import React from "react";
import "../css/basicStyle.css";
import "../css/theme.css";
import { Link } from "react-router-dom"; // for routing to other pages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faLinkedin,
  faGooglePlus,
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

// migration from index.html
const Footer = () => {
  const { t  } = useTranslation()
  return (
    <>
      <footer className="w3-container w3-padding-32 w3-theme-d1 w3-center">
        <h4>{t("footer.follow_us")}</h4>
        <Link
          className="w3-button w3-large w3-theme-d5"
          to="https://www.facebook.com/"
          title="Facebook"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </Link>
        <Link
          className="w3-button w3-large w3-theme-d5"
          to="https://www.twitter.com/"
          title="Twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </Link>
        <Link
          className="w3-button w3-large w3-theme-d5"
          to="https://www.google.com/"
          title="Google +"
        >
          <FontAwesomeIcon icon={faGooglePlus} />
        </Link>
        <Link
          className="w3-button w3-large w3-theme-d5"
          to="https://www.instagram.com/"
          title="Instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
        <Link
          className="w3-button w3-large w3-theme-d5 w3-hide-small"
          to="https://www.linkedin.com/"
          title="Linkedin"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </Link>

      </footer>
    </>
  );
};

export default Footer;
