import React, { useEffect } from "react";
import "../css/basicStyle.css";
import "../css/theme.css";
import "../css/components/ProducerProductsBar.css"

function ProducerProductBar({ producerData, productsData, onProductSelected, onProducerSelected, selectedProducer, isMobile }) {
  useEffect(() => {
    if (!productsData) {
      onProductSelected(null);
    } else {
      if (producerData.length === 0) {
        onProductSelected(null);
      }
      else {
        onProductSelected(productsData[0]);
      }
    }
  }, [productsData])
  return (
    <div className="producer-products-bar">
      <div className="treeview-container">
        {producerData.map((producer) => (
          <div key={producer.spoq_producer_id} className="producer-button">
            <button className="w3-button w3-theme-d5 producer-button"
              onClick={() => {
                onProducerSelected(producer);
              }}
              style={isMobile ? {fontSize:"11px"}: {}}>
              
              {producer.company_name}
            </button>
            {selectedProducer && selectedProducer.spoq_producer_id === producer.spoq_producer_id && (
              <div>
                {productsData.map((product) => (
                  <button className="w3-button w3-theme-d3 producer-button"
                    key={product.product_id}
                    onClick={() => {
                      onProductSelected(product);
                    }}
                    style={isMobile ? {fontSize:"11px"}: {}}
                  >{product.product_name}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProducerProductBar;
