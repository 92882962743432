// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import { postRegisterCharge } from "../../api_functions/ChargeInfo";
import { fetchSaleRegions } from "../../api_functions/ProductInfo";
import { DateRange } from "react-date-range";
import LoadingIndicator from "../../Components/LoadingIndicator";


// page containing tde base information of a company including tde option to
// edit tde information
function AddCharge({
    show,
    product_api_url,
    onClose,
    onAdded,
    productInfo,
    assignedManufacturers,
    isMobile
}) {
    const { t } = useTranslation();
    const [selectedManufacturer, setSelectedManufacturer] = useState(null)
    const [chargeNumber, setChargeNumber] = useState("");
    const [chargeSize, setChargeSize] = useState(0);
    const [saleRegions, setSaleRegions] = useState([]);
    const [selectedSaleRegions, setSelectedSaleRegions] = useState("");
    const [productionStartDate, setProductionStartDate] = useState(new Date());
    const [productionEndDate, setProductionEndDate] = useState(new Date());
    const [addCallActive, setAddCallActive] = useState(false);
    useEffect(() => {
        if(productInfo) {
            fetchSaleRegions(product_api_url, productInfo.product_id, setSaleRegions)
        }
    }, [productInfo]);

    function onAddCallFinished() {
        console.log("onAddCallFinished")
        setAddCallActive(false);
        onAdded();
        setChargeSize(0);
        setSelectedManufacturer(assignedManufacturers[0]);
        setSelectedSaleRegions([]);
        setChargeNumber("");
    }

    if (!show) return null;
    return (
        <div className="edit-product-dialog" style={isMobile? {fontSize:"11px"} : {}}>
            <div className="edit-product-dialog-content" style={addCallActive ? {opacity:"0.3"}: {}}>
                {isMobile ? <h4>{t("account.add_charge.edit_charge_info")}</h4> : <h2>{t("account.add_charge.edit_charge_info")}</h2>}
                <table className="w3-table w3-striped w3-border w3-bordered">
                    <tbody>
                        <tr>
                            <td>{t("account.charges.product_id")}</td>
                            <td id="info_product_id">
                                <center>{productInfo.product_id}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.products.product_name")}</td>
                            <td id="info_product_name">
                                <center>{productInfo.product_name}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.add_charge.charge_number")}</td>
                            <td>
                                <center>
                                    <input
                                        type="text"
                                        className="w3-input w3-border w3-center"
                                        onChange={(e) => { setChargeNumber(e.target.value) }}
                                        value={chargeNumber}>
                                    </input>
                                </center>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t("account.add_charge.contract_manufacturer")}
                            </td>
                            <td>
                                <Select
                                    options={assignedManufacturers}
                                    labelField="spoq_producer_name"
                                    valueField="spoq_producer_id"
                                    onChange={(value) => setSelectedManufacturer(value[0])} />
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.add_charge.charge_size")}</td>
                            <td id="Charge Size">
                                <center>
                                    <input
                                        type="number"
                                        className="w3-input w3-border w3-center"
                                        value={chargeSize}
                                        onChange={(e) => setChargeSize(e.target.value)}>
                                    </input>
                                </center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.add_charge.sale_region")}</td>
                            <td id="Sale Region Selection">
                                <center>
                                    <Select
                                        id="saleRegionSelect"
                                        options={saleRegions.map((value) => { return { "region": value[2] } })}
                                        onChange={(value) => {
                                            // set the selected regions
                                            //console.log("onChangeSelectSaleRegions:")
                                            //console.log(value)
                                            var regions = [];
                                            for (var element in value) {
                                                regions.push(value[element].region);
                                            }
                                            setSelectedSaleRegions(regions);
                                        }}
                                        className="w3-input w3-border w3-center w3-margin-top"
                                        labelField="region"
                                        valueField="region"
                                        style={{ maxWidth: "400px", display: " inline-block" }}
                                        create={true}
                                        multi={true} />
                                </center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.add_charge.production_period")}</td>
                            <td>
                                <center style={{maxWidth:"500px"}}>
                                    <DateRange
                                    minDate={new Date()}
                                        ranges={[{ startDate: productionStartDate, endDate: productionEndDate, key: 'selection' }]}
                                        onChange={(ranges) => {
                                            //console.log(ranges)
                                            if (ranges.selection.startDate !== undefined) {
                                                setProductionStartDate(ranges.selection.startDate);
                                            }
                                            if (ranges.selection.endDate !== undefined) {
                                                setProductionEndDate(ranges.selection.endDate);
                                            }
                                        }}
                                    />
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="fixed-buttons-dialog">
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        disabled = {addCallActive}
                        onClick={() => {
                            setChargeSize(0);
                            setSelectedManufacturer(assignedManufacturers[0]);
                            setSelectedSaleRegions([]);
                            setChargeNumber("");
                            onClose();
                        }}
                    >
                        {t("button.cancel")}
                    </button>                    
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            setAddCallActive(true);
                            var chargeData = {
                                "charge_number": chargeNumber,
                                "product_id": productInfo.product_id,
                                "charge_size": chargeSize,
                                "contract_manufacturer_id": selectedManufacturer.spoq_producer_id,
                                "sale_region": selectedSaleRegions.toString(),
                                "production_region": "",
                                "production_start": productionStartDate.getTime(),
                                "production_end": productionEndDate.getTime(),
                                "begin_date": Date.now(),
                                "end_date": 0
                            }
                            postRegisterCharge(product_api_url, chargeData,()=> {
                                onAddCallFinished();
                            });
                        }}
                        disabled={chargeNumber === "" || selectedManufacturer === null || selectedSaleRegions.length === 0 || chargeSize === 0 || addCallActive}
                    >
                        {t("button.confirm")}
                    </button>
                </div>
            </div>
            {addCallActive && <div style={{position:"fixed"}}>
                <LoadingIndicator></LoadingIndicator>
                </div>
                }
        </div>
    );
}

export default AddCharge;
