// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../css/basicStyle.css";
import "../css/theme.css";
import "../css/components/CompanyTile.css"
import { useTranslation } from "react-i18next";
import { CustomImage } from "./CustomImage";
import { fetchCompanyInfo } from "../api_functions/AccountInfo";
import LoadingIndicator from "./LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
function CompanyTile({ company_id, isMobile }) {
    const { t  } = useTranslation();
    const [publicCompanyData, setPublicCompanyData] = useState(null);
    const [expanded, setExpanded] = useState(false)
    useEffect(() => {
        if (company_id) {
            fetchCompanyInfo(company_id, setPublicCompanyData)
        }
    }, [])
    useEffect(() => {
        if (company_id) {
            fetchCompanyInfo(company_id, setPublicCompanyData)
        }
    }, [company_id])
    return (
        <div >
            {publicCompanyData ?
                <div>
                    {!isMobile &&
                        <div className="company-tile">
                            <table className="company-table w3-table w3-striped w3-border w3-bordered"
                            style={{flex:"3"}}>
                                <tbody>
                                    <tr>
                                        <td>{t("account.add_charge.contract_manufacturer")}</td>
                                        <td>{publicCompanyData.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td>ID</td>
                                        <td>{publicCompanyData.spoq_producer_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("register.contact_person_name")}</td>
                                        <td>{publicCompanyData.contact_person_name}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("account.home.email")}</td>
                                        <td>{publicCompanyData.contact_email}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <center className="company-tile-image"
                            style={{flex:"1"}}>
                                <CustomImage
                                    data={publicCompanyData.company_logo}
                                    maxWidth={"15vw"}
                                    maxHeight={"10vh"}
                                /></center>
                        </div>}
                    {isMobile &&
                        <div style={{fontSize:"11px"}}>
                            <div className="mobile-company-tile"
                                onClick={() => {
                                    setExpanded(!expanded)
                                }}>
                                <center style={{ flex: "6", marginLeft: "5px" }}>{publicCompanyData.company_name}</center>
                                <center style={{ padding: "10px", flex: "2" }} className="company-tile-image">
                                    <CustomImage
                                        data={publicCompanyData.company_logo}
                                        maxWidth={"15vw"}
                                        maxHeight={"10vh"}
                                    />
                                </center>
                                <center style={{ flex: "1" }}>
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </center>
                            </div>
                            {expanded &&
                                <div className="mobile-company-tile">
                                    <table className="w3-table expanded-table">
                                        <tbody className="w3-border w3-bordered w3-table w3-striped" style={{fontSize:"11px"}}>
                                            <tr>
                                                <td>ID</td>
                                                <td>{publicCompanyData.spoq_producer_id}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("register.contact_person_name")}</td>
                                                <td>{publicCompanyData.contact_person_name}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("account.home.email")}</td>
                                                <td>{publicCompanyData.contact_email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>}
                </div> :
                <LoadingIndicator></LoadingIndicator>
            }
        </div>
    );
}

export default CompanyTile;
