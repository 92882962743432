// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";
import { postRegisterProduct } from "../../api_functions/ProductInfo";
import { fetchAssociatedContractManufacturers } from "../../api_functions/ManufacturerInfo";
import { CustomImage, imageToBase64 } from "../../Components/CustomImage";
import Header from "../../Components/Header";

// page containing tde base information of a company including tde option to
// edit tde information
const RegisterProducts = ({spoqProducerData, spoqId, spoqUserData, isMobile}) => {
  const { t } = useTranslation();

  const [selectedProductImage, setSelectedProductImage] = useState(null);
  const [hasApi, setHasApi] = useState(false);

  // warning fields
  const [warningFields, setWarningFields] = useState([]);
  const addFields = () => {
    setWarningFields([...warningFields, ""]);
  };
  const [saleRegions, setSaleRegions] = useState([]);
  const [selectedSaleRegions, setSelectedSaleRegions] = useState([]);
  const [associatedContractManufacturers, setAssociatedContractManufacturers] = useState([]);
  const [assignedManufacturers, setAssignedManufacturers] = useState([]);

  useEffect(() => {
    var infoText = document.getElementById("info_text");
    // get all products from the database
    if (spoqProducerData.product_api_url === "") {
      //console.log("No products can be added or retrieved without a product api url");
      // alert("No products can be added or retrieved without a product api url");
      infoText.innerHTML =
        "No products can be added or retrieved without a product api url!";
      infoText.style.color = "red";
      setHasApi(false);
      return;
    } else {
      setHasApi(true);
      // fetch regions
      fetchAssociatedContractManufacturers(spoqProducerData.product_api_url,setAssociatedContractManufacturers);
    }
  }, [spoqProducerData]);
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  // to register product
  async function clear_fields() {
    // Clear Product Information fields
    document.getElementById("info_product_name_input").value = "";
    document.getElementById("info_product_type_input").value = "";
    document.getElementById("info_product_number_input").value = "";
    document.getElementById("info_description_input").value = "";
    document.getElementById("info_link_input").value = "";
    document.getElementById("info_link_recycle_input").value = "";
    document.getElementById("product_image_file").value = null;
    setWarningFields([]); // Clear warnings

    await delay(200);
    var saleRegionSelect = document.getElementsByName("saleRegionSelect")
    //console.log(saleRegionSelect)
    saleRegionSelect.value = [];
    setSelectedProductImage(null);
    setSelectedSaleRegions([]);
    setAssignedManufacturers([]);
    //console.log("Cleared Fields");
    //console.log(selectedSaleRegions)
  }
  async function registerProduct() {
    var info_product_name = document.getElementById(
      "info_product_name_input"
    ).value;
    var info_product_type = document.getElementById(
      "info_product_type_input"
    ).value;
    var info_description = document.getElementById(
      "info_description_input"
    ).value;
    var info_product_number = document.getElementById(
      "info_product_number_input"
    ).value;
    var info_link = document.getElementById("info_link_input").value;
    var info_link_recycle = document.getElementById("info_link_recycle_input").value;
    var productInfoData = {
      product_name: info_product_name,
      product_type: info_product_type,
      product_number: info_product_number,
      description: info_description,
      link_info: info_link,
      link_info_recycle: info_link_recycle,
      product_image: selectedProductImage?selectedProductImage:"",
    };
    var warningData = {
      warnings: warningFields,
    };

    var contractManfuacturersData = {
      contract_manufacturer_list: assignedManufacturers.map(value => value.spoq_producer_id),
      product_id: NaN
    }
    //console.log(contractManfuacturersData)

    var salesRegionData = {
      sale_region_list: selectedSaleRegions,
    }


    postRegisterProduct(spoqProducerData.product_api_url, productInfoData, warningData, contractManfuacturersData, salesRegionData);
    clear_fields();
    await delay(200);
    window.location.href = '/account/products'
  }

  const handleWarningChange = (index, value) => {
    let data = [...warningFields];
    data[index] = value;
    setWarningFields(data);
  };

  const removeFields = (index) => {
    let data = [...warningFields];
    data.splice(index, 1); // remove the warning field
    setWarningFields(data);
  };

  return (
    <div style={isMobile? {fontSize:"11px"}: {}}>
      <Header 
      spoqProducerData={spoqProducerData}
       isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData}/>

      <div className="w3-center">
        {isMobile ? <h3>{t("account.register_product")}</h3>:<h1>{t("account.register_product")}</h1>}
      </div>

      {hasApi ? (
        <div>
          <div className={isMobile ? "w3-container w3-padding-16 w3-center": "w3-container w3-padding-64 w3-center"}
          style={isMobile ?  {fontSize:"11px"}: {}}
          >
            {isMobile ?<h4>{t("account.products.product_information")}</h4>:<h2>{t("account.products.product_information")}</h2>}
            <div>
              <div>
                <label>
                  <b>{t("account.products.product_name")}</b>
                </label>
              </div>
              <input
                type="text"
                id="info_product_name_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{ maxWidth: "400px", display: " inline-block" }}
              />
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.product_type")}</b>
                </label>
              </div>
              <input
                type="text"
                id="info_product_type_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{ maxWidth: "400px", display: " inline-block" }}
              />
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.product_number")}</b>
                </label>
              </div>
              <input
                type="text"
                id="info_product_number_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{ maxWidth: "400px", display: " inline-block" }}
              />
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.product_description")}</b>
                </label>
              </div>
              <textarea
                id="info_description_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{
                  maxWidth: "400px",
                  height: "250px",
                  display: " inline-block",
                }}
              />
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.info_link")}</b>
                </label>
              </div>
              <input
                type="text"
                id="info_link_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{ maxWidth: "400px", display: " inline-block" }}
              />
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.info_link_recycle")}</b>
                </label>
              </div>
              <input
                type="text"
                id="info_link_recycle_input"
                required
                className="w3-input w3-border w3-center w3-margin-top"
                style={{ maxWidth: "400px", display: " inline-block" }}
              />
            </div>            
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.product_image")}</b>
                </label>
              </div>
              {/* image select */}
              <div>
                {selectedProductImage && (
                  <div className="w3-padding-16">
                    <CustomImage
                    data={selectedProductImage}
                    maxHeight={300}
                    maxWidth={300}
                    />
                    <br />
                    <button
                      onClick={() => {
                        setSelectedProductImage(null);
                        var input =
                          document.getElementById("product_image_file");
                        input.value = "";
                      }}
                      className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                    >
                      {t("button.remove")}
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  id="product_image_file"
                  name="product_image_file"
                  className="w3-padding-16 w3-center"
                  accept=".jpg, .jpeg, .png, .svg"
                  onChange={async (event) => {
                    //console.log(event.target.files[0]);
                    const image = await imageToBase64(event.target.files[0])
                    setSelectedProductImage(image);
                  }}
                />
              </div>
            </div>
            <hr className="dotted"></hr>
            <div>
              <div>
                <label>
                  <b>{t("account.products.warnings")}</b>
                </label>
              </div>
              <div>
                {warningFields.map((input, index) => {
                  return (
                    <div key={index}>
                      <input
                        placeholder={`warning ${index + 1}`}
                        onChange={(event) =>
                          handleWarningChange(index, event.target.value)
                        }
                        className="w3-input w3-border w3-center w3-margin-top"
                        style={{ maxWidth: "300px", display: " inline-block" }}
                      />
                      <button
                        type="button"
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => removeFields(index)}
                        style={{ maxWidth: "100px", display: " inline-block" }}
                      >
                        {t("button.remove")}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div>
                <button
                  type="button"
                  className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                  onClick={addFields}
                >
                  {t("button.add_warning")}
                </button>
              </div>
              <hr className="dotted"></hr>
              <div className="w3-center w3-margin">
                <label>
                  <b>{t("account.products.possible_sale_regions")}</b>
                </label>
              </div>
              <div className="w3-center w3-margin">
                {/* select from all regions via drowndown-select  */}
                <Select
                  id= "saleRegionSelect"
                  name="saleRegionSelect"
                  options={saleRegions}
                  values={selectedSaleRegions}
                  onChange={(value) => {
                    // set the selected regions
                    //console.log("onChangeSelectSaleRegions:")
                    //console.log(value)
                    var regions = [];
                    for (var element in value) {
                      regions.push(value[element].value);
                    }
                    setSelectedSaleRegions(regions);
                  }}
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  create={true}
                  multi={true}
                />
              </div>
              <hr className="dotted"></hr>
              <div className="w3-center w3-margin">
                <label>
                  <b>{t("account.products.associated_manufacturers")}</b>
                </label>
              </div>
              <div className="w3-center w3-margin">
                <Select
                  options={associatedContractManufacturers}
                  labelField="spoq_producer_name" 
                  valueField="spoq_producer_id" 
                  onChange={(values) => {
                   //console.log(values)
                    setAssignedManufacturers(values);
                    //console.log(associatedContractManufacturers);
                  }}
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  multi={true}
                />
              </div>
            </div>
          </div>

          <div className="w3-center">
            <button
              type="button"
              className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
              onClick={registerProduct}
              disabled={!hasApi}
              style={isMobile? {fontSize:"11px"}: {}}
            >
              {t("account.register.submit")}
            </button>
          </div>
        </div>
      ) : (
        <div className="w3-center w3-margin w3-padding-64">
          <p id="info_text"></p>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default RegisterProducts;
