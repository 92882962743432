import { accountApi, CACHE_KEY_ALL_OWNERS, CACHE_KEY_ACCOUNT_INFO, CACHE_KEY_STATISTICS_USERS } from "../config";
import { fetchAllProductsForManufacturer } from "./ProductInfo";
import { fetchChargesForManufacturer, fetchSpoqProductIdsForCharge } from "./ChargeInfo";

function getCachedAccountInfo() {
  // Retrieve the cached data from localStorage or any other caching mechanism you're using
  const cachedData = sessionStorage.getItem(CACHE_KEY_ACCOUNT_INFO);
  return JSON.parse(cachedData);
}


const isValidHexadecimal = (s) => {
  // Check if the string matches the hexadecimal pattern
  return /^[0-9a-fA-F]+$/.test(s);
};

async function checkAPIAlive(api_url) {
  try {
    const response = await fetch(api_url + "/test_alive",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }
  catch (error) {
    console.log("Failure to connect to API:", api_url)
    console.log("Error:", error)
    return false
  }
}

async function fetchAccountInfo(setSpoqId, setSpoqProducerData, setForeignProductApiUrls, setSpoqUserData) {
  const currentTime = Date.now();
  if( !localStorage.getItem("token")) {
    return
  }
  try {
    const response = await fetch(
      accountApi + "/logged_in?token=" + localStorage.getItem("token"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.headers.get("content-type").includes("application/json")) {
      console.log("Browser Cache is Saying no login")
      const cacheData = sessionStorage.getItem("logged_in_spoq_producer_id");
      setSpoqId(cacheData.spoq_producer_id);
    } else if (response.status !== 200) {
      setSpoqId(false);
      setSpoqProducerData(false);
      setForeignProductApiUrls(false);
    }
    else {
      const data = await response.json();
      setSpoqId(data.spoq_producer_id);
      try{
        sessionStorage.setItem("logged_in_spoq_producer_id", data)
      }
      catch(error){
        console.log("Browser Cache out of Space")
      }
      try {
        const spoqProducerResponse = await fetch(
          accountApi +
          "/get_spoq_producer",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (spoqProducerResponse.status === 200) {
          const spoqProducerData = await spoqProducerResponse.json();
          console.log("Setting spoq_producer_data with :", spoqProducerData.spoq_producer)
          setSpoqProducerData(spoqProducerData.spoq_producer);
          // Cache the fetched data
          const cachedInfo = {
            timestamp: currentTime,
            spoq_producer_id: data.spoq_producer_id,
            spoq_producer: spoqProducerData.spoq_producer,
          };
          try {
            sessionStorage.setItem(CACHE_KEY_ACCOUNT_INFO, JSON.stringify(cachedInfo));
          }
          catch(error){
            console.log("Browser Cache out of Space")
          }
          if (spoqProducerData.spoq_producer.contract_manufacturer_boolean) {
            fetchForeignApiUrls(setForeignProductApiUrls);
          }
          if (spoqProducerData.spoq_producer.account_api_url && spoqProducerData.spoq_producer.account_api_url !== "") {
            fetchUserData(spoqProducerData.spoq_producer.account_api_url, setSpoqUserData)
          } else {
            setSpoqUserData({
              role: 1,
            })
          }
        } else if (!response.headers.get("content-type").includes("application/json")) {
          const cachedData = getCachedAccountInfo();
          if (cachedData) {
            setSpoqProducerData(cachedData.spoq_producer);
            if (cachedData.spoq_producer.contract_manufacturer_boolean) {
              fetchForeignApiUrls(setForeignProductApiUrls);
            }
            if (cachedData.spoq_producer.account_api_url && cachedData.spoq_producer.account_api_url !== "") {
              fetchUserData(cachedData.spoq_producer.account_api_url, setSpoqUserData)
            }
            else {
              setSpoqUserData({
                role: 1,
              })
            }
          }
        }
      } catch (error) {
        //window.location.href = "/login";
        console.log(error);
        alert("Error: " + error);
      }
    }
  } catch (error) {
    //window.location.href = "/login";
    console.log(error);
    alert("Error: " + error);
  }
}

async function fetchUserData(local_api_url, setUserData) {
  try {
    const userInfoResponse = await fetch(
      local_api_url +
      "/get_user_by_token",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
      }
    );

    if (userInfoResponse.status === 200) {
      const userInfoData = await userInfoResponse.json();
      // Cache the fetched data
      const cachedInfo = {
        userInfoData: userInfoData
      };
      try {
      sessionStorage.setItem("cached_user_info", JSON.stringify(cachedInfo));
      }
      catch(error){
        console.log("Browser Cache out of Space")
      }
      setUserData(userInfoData);
    } else if (!userInfoResponse.headers.get("content-type").includes("application/json")) {
      const cachedData = JSON.parse(sessionStorage.getItem("cached_user_info"));
      if (cachedData) {
        setUserData(cachedData);
      }
    }
  } catch (error) {
    console.log(error);
    alert("Error: " + error);
  }
}

async function fetchForeignApiUrls(setForeignProductApiUrls) {
  try {
    const foreignAPIURLSResponse = await fetch(
      accountApi +
      "/get_foreign_product_api_urls_manufacturer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (foreignAPIURLSResponse.status === 200) {
      const data = await foreignAPIURLSResponse.json();
      var filteredForeignAPIUrls = []
      for (var product_id_pair of data.product_api_urls) {
        var isAlive = await checkAPIAlive(product_id_pair.product_api_url)
        if (isAlive) {
          filteredForeignAPIUrls.push(product_id_pair);
        }
      }
      try {
      sessionStorage.setItem("cached_foreign_product_apis", JSON.stringify(filteredForeignAPIUrls));
      }
      catch(error){
        console.log("Browser Cache out of Space")
      }
      setForeignProductApiUrls(filteredForeignAPIUrls);
    } else if (!foreignAPIURLSResponse.headers.get("content-type").includes("application/json")) {
      const cachedData = JSON.parse(sessionStorage.getItem("cached_foreign_product_apis"));
      if (cachedData) {
        // Process and use cached data
        setForeignProductApiUrls(cachedData);
      }
    }
  } catch (error) {
    window.location.href = "/login";
    console.log(error);
    alert("Error: " + error);
  }
}

async function fetchCompanyInfo(spoqId, handleCompanyInfo) {
  if (!spoqId) {
    console.log("Trying to fetch Company Info with undefiened spoq_producer_id")
    return;
  }
  if (sessionStorage.getItem("cached_company_info" + spoqId) !== null) {
    const cachedData = JSON.parse(sessionStorage.getItem("cached_company_info" + spoqId));
    handleCompanyInfo(cachedData.companyInfoData);
    return;
  }
  try {
    const companyInfoResponse = await fetch(
      accountApi +
      "/get_company_information?spoq_producer_id=" +
      spoqId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (companyInfoResponse.status === 200) {
      const companyInfoData = await companyInfoResponse.json();
      // Cache the fetched data
      const cachedInfo = {
        companyInfoData: companyInfoData
      };
      try {
      sessionStorage.setItem("cached_company_info" + spoqId, JSON.stringify(cachedInfo));
      }
      catch(error){
        console.log("Browser Cache out of Space")
      }
      handleCompanyInfo(companyInfoData);
    } else if (!companyInfoResponse.headers.get("content-type").includes("application/json")) {
      //console.log("Browser Cache is saying no");
      //console.log("Using cached data");
      const cachedData = JSON.parse(sessionStorage.getItem("cached_company_info" + spoqId));
      if (cachedData) {
        // Process and use cached data
        //console.log(cachedData);
        handleCompanyInfo(cachedData);
      }
    } else {
      //console.log("Response status: " + spoqProducerResponse.status);
    }
  } catch (error) {
    console.log(error);
    alert("Error: " + error);
  }
}


async function fetchAllOwners(local_account_api, spoq_producer_id, setAllOwners) {
  try {
    if (spoq_producer_id === null || typeof spoq_producer_id === 'undefined') {
      console.log("Trying to fetch Owners with invalid spoq_producer_id");
      return;
    }
    const currentTime = Date.now();

    const response = await fetch(local_account_api + "/get_all_owners?spoq_producer_id=" + spoq_producer_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // process response
    if (response.status !== 200) {
      // error
      const data = await response.json()
      //console.log(data);
      alert("Error: " + data.message);
    } else {
      const data = await response.json()
      setAllOwners(data.owners);
      //console.log("all owners: " + JSON.stringify(data.owners));

      // update cache
      const cachedInfo = {
        timestamp: currentTime,
        all_owners: data.owners,
      };
    }
  } catch (error) {
    console.log(error);
  }
}

async function fetchAllCompanyUsers(local_account_api, setAllUsers) {
  try {
    const response = await fetch(local_account_api + "/get_all_users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    // process response
    if (response.status !== 200) {
      // error
      const data = await response.json()
      alert("Error: " + data.message);
    } else {
      const data = await response.json()
      console.log("Fetched User Data:",data);
      setAllUsers(data);
    }
  } catch (error) {
    console.log(error);
  }
}

async function postAPIURLS(spoq_producer_id, product_api_url, account_api_url, verify_api_url, onAccountInfoChanged) {
  // Fetching alive Signals to verify entered API URLS
  var product_api_alive = await checkAPIAlive(product_api_url);
  var account_api_alive = await checkAPIAlive(account_api_url);
  var verify_api_alive = await checkAPIAlive(verify_api_url);
  if (product_api_alive && verify_api_alive && account_api_alive) {
    try {
      const response = await fetch(
        accountApi +
        "/set_api_urls?spoq_producer_id=" +
        spoq_producer_id +
        "&product_api_url=" +
        product_api_url +
        "&account_api_url=" +
        account_api_url +
        "&verification_api_url=" +
        verify_api_url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        const data = await response.json();
        //console.log(data);
        alert("Error: " + data.message);
      } else {
        onAccountInfoChanged();
        alert("API URLs successfully updated.");
      }
    } catch (error) {
      console.log(error);
      alert("Error: " + error);
    }
  } else {
    console.log("Checking alive Signal failed")
    alert("Alive Signal from API failed. Did NOT update API URLS")
    try {
      await fetch(
        accountApi +
        "/set_api_urls?spoq_producer_id=" +
        spoq_producer_id +
        "&product_api_url=" +
        "" +
        "&account_api_url=" +
        "" +
        "&verification_api_url=" +
        "",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
      alert("Error: " + error);
    }
  }
}

async function postRegisterUser(spoq_producer_id, account_api_url, name, email, password, role, setImage, setTwoFABoolean, setMailAvailable) {
  try {
    const response = await fetch(account_api_url + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        role: role,
        spoq_producer_id: spoq_producer_id,
      }),
    });
    // process response
    if (response.status !== 200) {
      if (response.status === 409) {
        setMailAvailable(false);
      } else {
      alert("Error while registering!");
      }
    } else {
      const data = await response.json()
      setImage(data.qr_code);
      setTwoFABoolean(true);
    }
  }
  catch (error) {
    console.log(error);
    setTwoFABoolean(false);
    alert("Error while registering!");
  }
}

async function postUpdateUserRole(account_api_url, email, newUserRole) {
  try {
    const response = await fetch(account_api_url + "/update_user_role?email="+ email +"&newUserRole="+ newUserRole, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // process response
    if (response.status !== 200) {
      alert("Error while user role Update");
    } else {
      console.log("Succes user role Update Request");
    }
  }
  catch (error) {
    console.log(error);
    alert("Error while user role Update");
  }
}

async function postDeleteUser(account_api_url, email) {
  try {
    const response = await fetch(account_api_url + "/delete_user?email="+ email, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // process response
    if (response.status !== 200) {
      alert("Error while user role Update");
    } else {
      console.log("Succes user role Update Request");
    }
  }
  catch (error) {
    console.log(error);
    alert("Error while user role Update");
  }
}

async function fetchRepresentativeData(account_api_url, setRepresentativeOptions, representativeOptions) {
  try {
    const r = await fetch(account_api_url + "/get_all_users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    const json = await r.json();
    //console.log(json);
    var owner = 0;
    var product_owner = 0;
    var manufacturer_owner = 0;
    var product_member = 0;
    for (var i = 0; i < json.length; i++) {
      if (json[i].role === 1) {
        owner++;
      } else if (json[i].role === 2) {
        product_owner++;
      } else if (json[i].role === 3) {
        manufacturer_owner++;
      } else if (json[i].role === 4) {
        product_member++;
      }
    }
    const labels = ['Owner', 'Product Owner', 'Manufacturer Owner', 'Product Member'];

    setRepresentativeOptions(
      {
      labels,
      datasets: [
        {
          label: "Number of Company Members",
          data: [owner, product_owner, manufacturer_owner, product_member],
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(53, 162, 235, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(255, 205, 86, 0.5)',
          ],
        },
      ],
    });
  }
  catch (e) {
    console.log(e);
    alert("Error: " + e);
  }
};

async function fetchManufacturerStatisticData(
  product_api_url,
  associatedContractManufacturers,
  setAssignedProductsOptions,
  setCompletionRateOptions,
  setNumberChargesOptions,) {
  var manufacturerData = [];
  for (var manufacturer of associatedContractManufacturers) {
    var productsForManufacturer = await fetchAllProductsForManufacturer(product_api_url, manufacturer.spoq_producer_id, () => { }, () => { }, () => { });
    var completion_rates = []
    var number_completed_charges = 0
    var number_charges = 0
    for (var product of productsForManufacturer) {
      var charges_for_product = await fetchChargesForManufacturer(product_api_url, product.product_id, manufacturer.spoq_producer_id, () => { });
      for (var charge of charges_for_product) {
        number_charges = number_charges + 1;
        var chargeData = await fetchSpoqProductIdsForCharge(product_api_url, product.product_id, charge.charge_id, () => { }, () => { })
        completion_rates.push((chargeData.spoqProducIds.length - chargeData.unfinishedSpoqProductIds.length) / chargeData.spoqProducIds.length);
        if (chargeData.unfinishedSpoqProductIds.length === 0) {
          number_completed_charges = number_completed_charges + 1;
        }
      }
    }
    manufacturerData.push({
      manufacturer_name: manufacturer.spoq_producer_name,
      assignedProducts: productsForManufacturer.length,
      completionRate: completion_rates.length === 0? 0: completion_rates.reduce((acc, val) => acc + val, 0) / completion_rates.length,
      numCharges: number_charges,
      completedCharges: number_completed_charges
    })
  }
  console.log("Completion Rates: ", manufacturerData.map((manufacturer)=> manufacturer.manufacturer_name), manufacturerData.map((manufacturer)=> manufacturer.completionRate))
  setCompletionRateOptions(
    {
      labels: manufacturerData.map((manufacturer)=> manufacturer.manufacturer_name),
      datasets:[{
        data:manufacturerData.map((manufacturer)=> manufacturer.completionRate),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      }]
    });
  setAssignedProductsOptions(    {
    labels: manufacturerData.map((manufacturer)=> manufacturer.manufacturer_name),
    datasets:[{
      data:manufacturerData.map((manufacturer)=> manufacturer.assignedProducts),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    }]
  });
  setNumberChargesOptions(    {
    labels: manufacturerData.map((manufacturer)=> manufacturer.manufacturer_name),
    datasets:[{
      data:manufacturerData.map((manufacturer)=> manufacturer.numCharges),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    }]
  });
}

async function fetchUsersAssginedToProduct(account_api_url, product_id, setAssignedUsers) {
  try {
    const response = await fetch(account_api_url + "/get_users_permitted_to_product?product_id=" + product_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // process response
    if (response.status !== 200) {
      // error
      const data = await response.json()
      //console.log(data);
      alert("Error: " + data.message);
    } else {
      const data = await response.json()
      setAssignedUsers(product_id,data);
    }
  } catch (error) {
    console.log(error);
  }
}

async function fetchCooperationRequests(spoq_producer_id, setPendingCooperationRequests) {
  if (spoq_producer_id) {
    try {
      const response = await fetch(accountApi + "/get_cooperation_requests?producer_id=" + spoq_producer_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // process response
      if (response.status !== 200) {
        // error
        const data = await response.json()
        //console.log(data);
        alert("Error: " + data.message);
      } else {
        const data = await response.json()
        setPendingCooperationRequests(data.pending_requests);
      }
    } catch (error) {
      console.log(error);
    }
  }
}


async function postAssignUsersToProduct(account_api_url, users, product_id) {
  try {
    const response = await fetch(account_api_url + "/add_permitted_product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        "product_id": product_id,
        "emails": users.map(user => user.email)
      })
    });
    if (response.status !== 200) {
      alert("Assigning User to Product failed. Please try again.");
      return;
    }
  }
  catch (error) {
    console.log("Error Assigning User to Product:", error);
    alert("Error:" + error);
  }
}

async function postForgotPasswordReset(userData, setErrorText) {
  try {
    const response = await fetch(accountApi + "/forgot_password_reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    if (response.status === 200) {
      // API call succeeded (status code 200)
      window.location.href = "/login"; // Call the success callback
    } else {
      // API call failed
      const detail = data.detail;
      console.log("No Succes from API Response");
      if (Array.isArray(detail)) {
        const errorMessages = detail.map((info) => info.message);
        setErrorText(errorMessages.join(" "));
      } else {
        setErrorText(data.detail);
      } // Display the error message from the server
    }
  } catch (error) {
    // Handle any unexpected errors (e.g., network error)
    setErrorText(
      "An error occurred while processing your request. Please try again later."
    );
  }
}

async function postForgotPassword(userData, setErrorText) {
  try {
    const response = await fetch(accountApi + "/forgot_password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    if (response.status === 200) {
      // API call succeeded (status code 200)
      return true;
    } else {
      // API call failed
      const detail = data.detail;
      if (Array.isArray(detail)) {
        const errorMessages = detail.map((info) => info.message);
        setErrorText(errorMessages.join(" "));
        return false;
      } else {
        setErrorText("No such email known");
        return false;
      } // Display the error message from the server
    }
  } catch (error) {
    // Handle any unexpected errors (e.g., network error)
    setErrorText(
      "An error occurred while processing your request. Please try again later."
    );
    return false;
  }
}
async function postForgotPasswordVerify(userData, setEmailVerified, setErrorText) {
  try {
    const response = await fetch(accountApi + "/forgot_password_verify", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    localStorage.setItem("token", data.token);
    if (response.status === 200) {
      // API call succeeded (status code 200)
      setEmailVerified(true);
    } else {
      // API call failed
      const detail = data.detail;
      if (Array.isArray(detail)) {
        const errorMessages = detail.map((info) => data.message);
        setErrorText(errorMessages.join(" "));
      } else {
        setErrorText("Invalid one time password");
      } // Display the error message from the server
    }
  } catch (error) {
    // Handle any unexpected errors (e.g., network error)
    setErrorText("Internal Server Error");
  }
}
async function postLogin(spoq_producer_id, email, password, setSpoqProducerId, setTwoFABoolean, setHinText) {
  if (!isValidHexadecimal(spoq_producer_id)) {
    setHinText("Invalid SpoqProducerID");
    return;
  }
  var apiLink = accountApi + "/login";
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        spoq_producer_id: spoq_producer_id,
        email: email,
        password: password,
      }),
    });
    // process response
    // console.log(response);
    if (response.status !== 200) {
      // error
      // print detail error message
      const data = await response.json()
      //console.log(data);
      setHinText(data.detail);
    } else {
      // success
      // save token to local storage??

      const data = await response.json()
      localStorage.setItem("token", data.token);
      //console.log(data);
      // redirect to home page
      // window.location.href = "/login/2fa?spoq_producer_id=" + producerId;
      setSpoqProducerId(spoq_producer_id);
      setHinText("");
      setTwoFABoolean(true);
    }
  }
  catch (error) {
    console.log(error);
    alert("Error: " + error);
  }
}

async function postVerifyOTPToken(spoq_producer_id, setTwoFABoolean, onAccountInfoChanged, setHintText) {
  var apiLink = accountApi + "/verify_otp_token";
  //console.log("token: " + document.getElementById("token").value + ", producerId: " + spoq_producer_id)
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        token_2fa: document.getElementById("token").value,
        spoq_producer_id: spoq_producer_id,
      }),
    })
    // process response
    // console.log(response);
    if (response.status !== 200) {
      //console.log(data);
      const hintData = await response.json();
      setHintText(hintData.detail);
    } else {
      // success
      const data = await response.json()
      //console.log(data);
      // update token
      localStorage.setItem("token", data.updated_token);
      setTwoFABoolean(false);
      // redirect to home page
      onAccountInfoChanged();
      window.location.href = "/account/home";
    }
  }
  catch (error) {
    console.log(error);
    setHintText("Could not reach API please contact customer support if this error persists");
  }
}
async function postRegisterCompany(company_data, setSpoqProducerId, setSelectedImage, setTwoFABoolean, setHintText) {
  var apiLink = accountApi + "/register_company";
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: company_data.company_name,
        contact_person_name: company_data.contact_person_name,
        contact_person_email: company_data.contact_person_email,
        logo_image: company_data.logo_image,
        street_and_number: company_data.street_and_number,
        zip_code: company_data.zip_code,
        city: company_data.city,
        country: company_data.country,
        password: company_data.password,
        producer_boolean: company_data.producer_boolean,
        contract_manufacturer_boolean:
          company_data.contract_manufacturer_boolean,
        dealer_boolean: company_data.dealer_boolean,
        customer_boolean: company_data.customer_boolean,
      }),
    });
    // check status code
    if (response.status !== 200) {
      const err_data = await response.json();
      setHintText("Registration failed:" + err_data.detail);
      return;
    }
    // process response
    // convert to json
    const data = await response.json()
    //console.log(data);
    console.log("registration successful");
    //console.log(data.qr_image_string);
    setSpoqProducerId(data.spoq_producer_id);
    setSelectedImage(data.qr_image_string);
    setTwoFABoolean(true);
  }
  catch (error) {
    console.log("Error registering company:", error);
    alert("Error:" + error);
  }
}

async function postDynamicAPISetupForCompany(spoq_producer_id, onAccountInfoChanged, setIsAPISetupRunning) {
  console.log("Dynamic setup called");
  var apiLink = accountApi + "/setup_api?spoq_producer_id=" + spoq_producer_id;
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // check status code
    if (response.status !== 200) {
      console.log("Response status: " + response.status);
      alert("API SETUP failed");
      return;
    } else {
      setIsAPISetupRunning(false)
      onAccountInfoChanged();
    }
  }
  catch (error) {
    console.log("Error Setting up API:", error);
    alert("Error:" + error);
  }
}

async function postUpdateCompanyInfo(updated_company_data, onAccountInfoChanged) {
  var apiLink = accountApi + "/update_company_info";
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(updated_company_data),
    });
    // check status code
    if (response.status !== 200) {
      console.log("Response status: " + response.status);
      alert("Update company info failed. Please try again.");
      return;
    }
    onAccountInfoChanged();
  }
  catch (error) {
    console.log("Error updating company info:", error);
    alert("Error:" + error);
  }
}

async function postCooperationRequest(producer_id, manufacturer_id) {
  var apiLink = accountApi + "/add_cooperation_request?producer_id=" + producer_id + "&manufacturer_id=" + manufacturer_id;
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // check status code
    if (response.status !== 200) {
      console.log("Response status: " + response.status);
      alert("Update company info failed. Please try again.");
      return;
    }
    console.log("Post Coopereation Request Succesfull");
  }
  catch (error) {
    console.log("Error posting cooperation Request", error);
    alert("Error:" + error);
  }
}

async function postAnswerCooperationRequest(producer_id, manufacturer_id, acknowledged) {
  var apiLink = accountApi + "/answer_cooperation_request?producer_id=" + producer_id + "&manufacturer_id=" + manufacturer_id + "&acknowledeged=" + acknowledged;
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // check status code
    if (response.status !== 200) {
      console.log("Response status: " + response.status);
      alert("Answering Cooperation Request failed.");
      return;
    }
    console.log("Answering Cooperation Request succesfull");
  }
  catch (error) {
    console.log("Error Answering Cooperation Request:", error);
    alert("Error:" + error);
  }
}

async function postEndCooperation(producer_id, manufacturer_id) {
  var apiLink = accountApi + "/end_cooperation?producer_id=" + producer_id + "&manufacturer_id=" + manufacturer_id;
  try {
    const response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    // check status code
    if (response.status !== 200) {
      console.log("Response status: " + response.status);
      alert("Ending Cooperation Request failed.");
      return;
    }
    console.log("Ending Cooperation Request succesfull");
  }
  catch (error) {
    console.log("Error Ending Cooperation Request:", error);
    alert("Error:" + error);
  }
}

export {
  fetchAccountInfo,
  fetchCompanyInfo,
  fetchAllOwners,
  fetchRepresentativeData,
  fetchManufacturerStatisticData,
  fetchAllCompanyUsers,
  fetchUsersAssginedToProduct,
  fetchCooperationRequests,
  postAPIURLS,
  postRegisterUser,
  postUpdateUserRole,
  postDeleteUser,
  postForgotPasswordReset,
  postForgotPassword,
  postForgotPasswordVerify,
  postLogin,
  postVerifyOTPToken,
  postRegisterCompany,
  postDynamicAPISetupForCompany,
  postUpdateCompanyInfo,
  postAssignUsersToProduct,
  postCooperationRequest,
  postAnswerCooperationRequest,
  postEndCooperation
};
