// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-statistics-container-row{
    display:flex;
    flex-direction: row;
}
.buttons-container{
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
.statistics-constainer{
    flex: 4 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/css/pages/Statistics.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".buttons-statistics-container-row{\r\n    display:flex;\r\n    flex-direction: row;\r\n}\r\n.buttons-container{\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n.statistics-constainer{\r\n    flex: 4;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
