// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState, useRef } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/Products.css"
import "../../css/components/ProducerProductsBar.css"
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";
import EditProduct from "./EditProduct";
import ConfirmationDialogDelete from "./ConfirmationDialogDelete";
import { fetchAssignedManufacturers } from "../../api_functions/ManufacturerInfo";
import { fetchAllProducts, fetchAllProductsForManufacturer } from "../../api_functions/ProductInfo";
import { fetchCompanyInfo, fetchAllCompanyUsers, fetchUsersAssginedToProduct, postAssignUsersToProduct } from "../../api_functions/AccountInfo";
import CompanyTile from "../../Components/CompanyTile";
import ProductTable from "./ProductTable";
import Select from "react-dropdown-select";
import ProducerProductBar from "../../Components/ProducerProductsBar";
import { CustomImage } from "../../Components/CustomImage";
import Header from "../../Components/Header";
import LoadingIndicator from "../../Components/LoadingIndicator";

// page containing tde base information of a company including tde option to
// edit tde information
const Products = ({ spoqProducerData, spoqId, foreign_product_api_urls, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [assignedManufacturers, setAssignedManufacturers] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [showEditProductDialog, setShowEditProductDialog] = useState(false);
  const [showDeleteProductDialog, setSchowDeleteProductDialog] = useState(false);
  var [productInfo, setProductInfo] = useState([]);
  var [productWarnings, setProductWarnings] = useState([]);
  var [productSaleRegions, setProductSaleRegions] = useState([]);
  var [selectedProduct, setSelectedProduct] = useState(null);
  const selectedProductIdRef = useRef(null);
  const [contractProducers, setContractProducers] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState(null);
  useEffect(() => {
    if (spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "") {
      fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions)
      fetchAllCompanyUsers(spoqProducerData.account_api_url, setCompanyUsers)
    }
    if (spoqProducerData.contract_manufacturer_boolean) {
      for (const foreign_data of foreign_product_api_urls) {
        //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
        fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
      }
    }

  }, []);

  useEffect(() => {
    console.log("Selected Product:", selectedProduct)
    if (selectedProduct) {
      selectedProductIdRef.current = selectedProduct.product_id;
      if(selectedProducer) {
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.producer_boolean) {
        fetchAssignedManufacturers(spoqProducerData.product_api_url, productInfo[0].product_id, handleUpdateAssignedManufacturer)
      }
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2) {
        fetchUsersAssginedToProduct(spoqProducerData.account_api_url, selectedProduct.product_id, handleUpdateAssignedUsers)
      }
    }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "") {
      fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions)
      fetchAllCompanyUsers(spoqProducerData.account_api_url, setCompanyUsers)
    }
    if (spoqProducerData.contract_manufacturer_boolean) {
      for (const foreign_data of foreign_product_api_urls) {
        //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
        fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
      }
    }

  }, [spoqProducerData, foreign_product_api_urls]);

  useEffect(() => {
    console.log("Produktinfo useEffekt ProductInfo:",productInfo)
    if (productInfo) {
      if (productInfo.length > 0) {
        setSelectedProduct(productInfo[0]);
        if (selectedProducer) {
          if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.producer_boolean) {
            fetchAssignedManufacturers(spoqProducerData.product_api_url, productInfo[0].product_id, handleUpdateAssignedManufacturer)
          }
          if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2) {
            fetchUsersAssginedToProduct(spoqProducerData.account_api_url, selectedProduct.product_id, handleUpdateAssignedUsers)
          }
        }
      } else {
        setSelectedProduct(null);
        setAssignedManufacturers(null);
        setAssignedUsers(null);
      }
    }
  }, [productInfo]);

  useEffect(() => {
    if (selectedProducer) {
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
        fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions);
      }
      else if (contractProducers.length > 0 && selectedProducer && foreign_product_api_urls.length > 0) {
        //console.log(contractProducers)
        var product_apiUrl_id_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id);
        if (selectedProducer.product_api_url !== "" && product_apiUrl_id_pair[0]) {
          fetchAllProductsForManufacturer(product_apiUrl_id_pair[0].product_api_url, spoqProducerData.spoq_producer_id, setProductInfo, setProductWarnings, setProductSaleRegions);
        }
      }
    }
  }, [selectedProducer]);
  useEffect(() => {
    if (spoqProducerData.producer_boolean) {
      setSelectedProducer(
        {
          "spoq_producer_id": spoqProducerData.spoq_producer_id,
          "company_name": spoqProducerData.spoq_producer_name,
          "contact_person_name": spoqProducerData.contact_person_name,
          "contact_email": spoqProducerData.contact_email,
          "company_logo": spoqProducerData.company_logo
        }
      )
    }
    else if (contractProducers.length > 0) {
      setSelectedProducer(contractProducers[0]);
    }
  }, [contractProducers])

  function addForeignProducerInfo(foreignProducerInfo) {
    setContractProducers((prevProducers) => [
      ...prevProducers.filter((producer) => producer.company_name !== foreignProducerInfo.company_name),
      foreignProducerInfo,
    ]);
  }
  const handleEditProduct = async () => {
    setShowEditProductDialog(true);
  };
  const handleDeleteProduct = () => {
    setSchowDeleteProductDialog(true);
  };
  const handleProductDeleted = async () => {
    if (selectedProducer && productInfo.length > 0) {
      setSelectedProduct(productInfo[0]);
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.producer_boolean) {
        fetchAssignedManufacturers(spoqProducerData.product_api_url, productInfo[0].product_id, handleUpdateAssignedManufacturer)
      }
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2) {
        fetchUsersAssginedToProduct(spoqProducerData.account_api_url, selectedProduct.product_id, handleUpdateAssignedUsers)
      }
    } else {
      setSelectedProduct(null);
      setAssignedManufacturers(null);
      setAssignedUsers(null);
    }
    //console.log("Product deleted");

    setSchowDeleteProductDialog(false);

    await delay(1000);

    fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions);
  }

  const handleProductEdited = async () => {
    //console.log("Product Edited");
    setShowEditProductDialog(false);
    await delay(1000);

    fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions);
    fetchAssignedManufacturers(spoqProducerData.product_api_url, selectedProduct.product_id, handleUpdateAssignedManufacturer);
    await delay(200);
    if (selectedProducer && productInfo.length > 0) {
      setSelectedProduct(productInfo[0]);
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.producer_boolean) {
        fetchAssignedManufacturers(spoqProducerData.product_api_url, productInfo[0].product_id, handleUpdateAssignedManufacturer)
      }
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2) {
        fetchUsersAssginedToProduct(spoqProducerData.account_api_url, selectedProduct.product_id, handleUpdateAssignedUsers)
      }
    } else {
      setSelectedProduct(null);
      setAssignedManufacturers(null);
      setAssignedUsers(null);
    }
  }
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  function intToHexString(num, length) {
    // Convert the integer to a hexadecimal string without leading "0x".
    let hexString = num.toString(16);

    // Pad the string with leading zeros to ensure a fixed length of 5.
    while (hexString.length < length) {
      hexString = '0' + hexString;
    }

    return hexString;
  }

  function handleUpdateAssignedManufacturer(product_id, updated_assigned_manufacturers) {
    if (selectedProduct) {
      console.log("UPDATE Assigned with:")
      console.log(product_id, updated_assigned_manufacturers)
      console.log("Selected product_id:", selectedProduct.product_id)
      const currentProductId = selectedProductIdRef.current;
      console.log("Selected Product ID by Ref:", currentProductId)
      if (product_id === currentProductId) {
        setAssignedManufacturers(updated_assigned_manufacturers);
      }
    } else {
      setAssignedManufacturers(null)
    }
  }
  function handleUpdateAssignedUsers(product_id, updated_assigned_users) {
    if (selectedProduct) {
      const currentProductId = selectedProductIdRef.current;
      if (product_id === currentProductId) {
        setAssignedUsers(updated_assigned_users);
      }
    } else {
      setAssignedUsers([]);
    }
  }

  return (
    <div className="page-container" style={isMobile ? { fontSize: "11px" } : {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />
      <div className="producer-product-content-row" >
        <ProducerProductBar
          id={"ProducerProductBar"}
          key={"ProducerProductBar"}
          productsData={productInfo}
          producerData={spoqProducerData.producer_boolean ? [{
            "spoq_producer_id": spoqProducerData.spoq_producer_id,
            "company_name": spoqProducerData.spoq_producer_name,
            "contact_person_name": spoqProducerData.contact_person_name,
            "contact_email": spoqProducerData.contact_email,
            "company_logo": spoqProducerData.company_logo
          }, ...contractProducers] : contractProducers}
          onProducerSelected={(newSelectedProducer) => {
            if (newSelectedProducer !== selectedProducer) {
              setProductInfo([]);
              setSelectedProducer(newSelectedProducer);
            }
          }
          }
          onProductSelected={(selectedProduct) => {
            if (selectedProduct) {
              console.log("ProductsProducerBar setSelectedProduct product_id:", selectedProduct.product_id)
              setSelectedProduct(selectedProduct);
              setAssignedManufacturers(null);
              setAssignedUsers([]);
              if (selectedProducer) {
                if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.producer_boolean) {
                  fetchAssignedManufacturers(spoqProducerData.product_api_url, selectedProduct.product_id, handleUpdateAssignedManufacturer)
                }
                if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2) {
                  fetchUsersAssginedToProduct(spoqProducerData.account_api_url, selectedProduct.product_id, handleUpdateAssignedUsers)
                }
              }
            }
          }}
          selectedProducer={selectedProducer}
          isMobile={isMobile}
        />
        <div className="products-page" style={isMobile ? {minHeight:'85vh'} : {minHeight:'70vh'}}>
          <div className={isMobile ? "products-page-content-mobile" : "products-page-content-desktop"}>
            <center>
              {isMobile ? <h3>{t("account.products.products")}</h3> : <h1>{t("account.products.products")}</h1>}
            </center>
            {selectedProducer &&
              <div className={isMobile ? "product-actions-mobile" : "product-actions-desktop"}>
                <div style={isMobile ? {} : { width: '50%' }}>
                  {selectedProduct ?
                    <ProductTable
                      productData={selectedProduct}
                      warnings={productWarnings}
                      sale_regions={productSaleRegions}
                      isMobile={isMobile} /> : <center>
                      No Product selected
                    </center>
                  }
                </div>
                <div style={isMobile ? {} : { width: '50%' }}>
                  {selectedProduct && selectedProduct.product_image && !isMobile &&
                    <center><CustomImage
                      data={selectedProduct.product_image}
                      maxWidth={"25vw"}
                      maxHeight={"30%"}
                    /></center>}
                  {spoqProducerData.producer_boolean && selectedProducer && selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqUserData.role <= 2 &&
                    <div className="w3-center w3-margin">
                      <div className="w3-center w3-margin-top">
                        <button
                          className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                          style={isMobile ?
                            { width: "15vw", fontSize: "11px" } :
                            { width: "15vw" }}
                          onClick={handleEditProduct}
                          disabled={!selectedProducer || !selectedProduct}
                        >
                          {t("button.edit")
                          }
                        </button>
                        <button
                          className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                          style={isMobile ?
                            { width: "15vw", fontSize: "11px" } :
                            { width: "15vw" }}
                          onClick={handleDeleteProduct}
                          disabled={!selectedProducer || !selectedProduct}
                        >
                          {t("button.delete")}
                        </button>
                      </div>
                    </div>}
                  {spoqProducerData.producer_boolean && selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqUserData.role === 1 &&
                    <div className="w3-container w3-center w3-border w3-margin">
                      <div className="w3-center">
                        {isMobile ? <h6>{t("account.products.assign_user")}</h6> : <h4>{t("account.products.assign_user")}</h4>}
                        <center>
                          <Select
                            id="assign_worker_select"
                            className="w3-input w3-border w3-center w3-margin-top"
                            style={isMobile ? { maxWidth: "400px", fontSize: "11px" } : { maxWidth: "400px" }}
                            values={companyUsers && assignedUsers ? companyUsers.filter(user => assignedUsers.some(assignedUser => user.email === assignedUser.email)) : []}
                            options={companyUsers}
                            labelField="name"
                            valueField="email"
                            disabled={!selectedProducer || !selectedProduct}
                            onChange={async (values) => {
                              setAssignedUsers(values);
                            }}
                            clearable={true} multi={true} />
                        </center>
                        <div className="w3-center w3-margin-top">
                          <button className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                            onClick={() => {
                              postAssignUsersToProduct(spoqProducerData.account_api_url, assignedUsers, selectedProduct.product_id)
                            }}
                            style={isMobile ?
                              { width: "fit-content", fontSize: "11px" } :
                              { width: "15vw" }}
                            disabled={!selectedProducer || !selectedProduct}
                          > Assign
                          </button>
                        </div>
                      </div>
                    </div>}
                </div>



                {spoqProducerData.producer_boolean && spoqProducerData.product_api_url === "" &&
                  <div className="w3-center w3-padding-64 w3-margin">
                    <p id="info_text">{t("account.products.missing_api")} </p>
                  </div>
                }
              </div>}
            {selectedProducer &&
              <div>
                {/* Plotting Assigned manufacturers in Producerview */}
                {spoqProducerData.producer_boolean && selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqUserData.role <= 3 && selectedProduct &&
                  <center>
                    <div>
                      {isMobile ? <h4>{t("account.product.assigned_manufacturer")}</h4> : <h2>{t("account.product.assigned_manufacturer")}</h2>}
                      <div>
                        <center className="manufacturer-list">
                          {assignedManufacturers ? assignedManufacturers.map(manufacturer => (
                            <div key={manufacturer.spoq_producer_id} className="manufacturer-item">
                              <CompanyTile company_id={manufacturer.spoq_producer_id} isMobile={isMobile} />
                            </div>)) :
                            <center><LoadingIndicator></LoadingIndicator> </center>
                          }
                        </center>
                      </div>
                    </div>
                  </center>
                }
                {/* Plotting Producer in Manufacturerview */}
                {spoqProducerData.contract_manufacturer_boolean && selectedProducer.spoq_producer_id !== spoqProducerData.spoq_producer_id &&
                  <div>
                    <center>
                      <div>
                        {isMobile ? <h4>
                          {t("account.product.assigned_producer")}
                        </h4> :
                          <h2>
                            {t("account.product.assigned_producer")}
                          </h2>}
                        <div>
                          <center className="manufacturer-list">
                            <div key={selectedProducer.spoq_producer_id} className="manufacturer-item">
                              <CompanyTile company_id={selectedProducer.spoq_producer_id} isMobile={isMobile} />
                            </div>
                          </center>
                        </div>
                      </div>
                    </center>
                  </div>
                }
              </div>
            }
          </div>
        </div>

      </div>
      <Footer />
      {selectedProduct && selectedProducer && spoqUserData.role <= 3 &&
        <EditProduct
          show={showEditProductDialog}
          onClose={() => setShowEditProductDialog(false)}
          onEdited={handleProductEdited}
          productInfo={selectedProduct}
          productWarnings={productWarnings.filter((warning) => warning.product_id === selectedProduct.product_id)}
          productSaleRegions={productSaleRegions.filter((sale_region) => sale_region.product_id === selectedProduct.product_id)}
          spoqProducerInfo={spoqProducerData}
          spoqId={spoqId}
          spoqProducerData={spoqProducerData}
          isMobile={isMobile}
        ></EditProduct>}
      {spoqUserData.role <= 3 && <ConfirmationDialogDelete
        show={showDeleteProductDialog}
        onClose={() => setSchowDeleteProductDialog(false)}
        onDeleted={handleProductDeleted}
        productInfo={selectedProduct}
        productWarnings={productWarnings}
        api_url={spoqProducerData.product_api_url}
        fetchProductData={() => { fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, setProductWarnings, setProductSaleRegions) }}
        isMobile={isMobile}
      >
      </ConfirmationDialogDelete>}
    </div>
  );
};

export default Products;
