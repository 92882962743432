// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next";
import { postRegisterProductionScanner } from "../../api_functions/ScannerInfo";


// page containing tde base information of a company including tde option to
// edit tde information
function AddScannerDialog({
    show,
    product_api_url,
    onClose,
    onAdded,
    productInfo,
    spoqProducerData,
    isMobile
}) {
    const { t } = useTranslation();
    const [scannername, setScannerName] = useState("");
    if (!show) return null;
    return (
        <div className="edit-product-dialog" style={isMobile? {fontSize:"11px"}: {}}>
            <div className="edit-product-dialog-content">
                {isMobile ? <h4>{t("account.production_scanner.add_scanner")}</h4> : <h2>{t("account.production_scanner.add_scanner")}</h2>}
                <table className="w3-table w3-striped w3-border w3-bordered">
                    <tbody>
                        <tr>
                            <td>{t("account.charges.product_id")}</td>
                            <td id="info_product_id">
                                <center>{productInfo.product_id}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.products.product_name")}</td>
                            <td id="info_product_id">
                                <center>{productInfo.product_name}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.production_scanner.scanner_name")}</td>
                            <td>
                                <center>
                                    <input
                                        type="text"
                                        className="w3-input w3-border w3-center"
                                        onChange={(e) => { setScannerName(e.target.value) }}
                                        value={scannername}>
                                    </input>
                                </center>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t("account.production_scanner.scanner_key")}
                            </td>
                            <td>
                                <center>{t("account.production_scanner.scanner_key_empty")}</center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="fixed-buttons-dialog">
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            setScannerName("");
                            onClose();
                        }}
                    >
                        {t("button.cancel")}
                    </button>                    
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={async () => {
                            var scannerData = {
                                "scanner_name": scannername,
                                "product_id": productInfo.product_id,
                                "contract_manufacturer_id": spoqProducerData.spoq_producer_id,
                                "scanner_key": "",
                                "expire_date": null
                            }
                            postRegisterProductionScanner(product_api_url, scannerData);
                            function delay(ms) {
                                return new Promise(resolve => setTimeout(resolve, ms));
                            }
                            await delay(600)
                            onAdded();
                            setScannerName("");
                        }}
                        disabled={scannername === "" }
                    >
                        {t("button.confirm")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddScannerDialog;
