import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/AccountLanding.css"
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";
import CompanyTile from "../../Components/CompanyTile";
import { postAPIURLS } from "../../api_functions/AccountInfo";
import SetUpAPIDialog from "./SetUpAPIDialog";
import EditCompanyInfo from "./EditCompanyInfo";
import LoadingIndicator from "../../Components/LoadingIndicator";
import Header from "../../Components/Header";

// page containing the base information of a company including the option to
// edit the information
const AccountLanding = ({ spoqProducerData, spoqId, onAccountInfoChanged, spoqUserData, isMobile }) => {
  const [showSetUpAPIDialog, setShowSetUpAPIDialog] = useState(false);
  const [showEditCompanyInfoDialog, setShowEditCompanyInfoDialog] = useState(false);
  const [isAPISetupRunning, setIsAPISetupRunning] = useState(false)
  function getRolesAsString() {
    var roleString = "";
    if (spoqProducerData.dealer_boolean) {
      roleString += "Dealer ";
    }
    if (spoqProducerData.producer_boolean) {
      roleString += " Producer ";
    }
    if (spoqProducerData.contract_manufacturer_boolean) {
      roleString += " Contract Manufacturer ";
    }
    if (spoqProducerData.customer_boolean) {
      roleString += " Customer ";
    }
    return roleString;
  }
  const { t } = useTranslation();
  useEffect(() => {
    console.log("SpoqproducerData AccountLanding", spoqProducerData);
  }, [spoqProducerData])
  async function changeApiUrls() {
    var product_api_url = document.getElementById("product_api_url").value;
    var account_api_url = document.getElementById("account_api_url").value;
    var verify_api_url = document.getElementById("verify_api_url").value;
    postAPIURLS(spoqProducerData.spoq_producer_id, product_api_url, account_api_url, verify_api_url, onAccountInfoChanged);
  }
  return (
    <div style={isMobile ? { fontSize: "11px" } : {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />
      <div className="w3-center w3-padding-32 w3-margin" style={isMobile ? {minHeight:'85vh'} : {minHeight:'70vh'}}>
        {isMobile ? <h3>{t("account.home.account_data")}</h3> : <h1>{t("account.home.account_data")}</h1>}
        {isMobile ? <h5>{t("account.home.company_data")}</h5> : <h3>{t("account.home.company_data")}</h3>}
        {spoqProducerData ?
          <div>
            {spoqProducerData && spoqUserData.role >= 3 && <CompanyTile company_id={spoqProducerData.spoq_producer_id} />}
            <div
              className="w3-center"
            >
              {((spoqProducerData && spoqUserData.role <= 2) || (spoqProducerData && !spoqUserData)) &&
                <center>
                  <table className="account-table w3-table w3-striped w3-border w3-bordered">
                    <tbody>
                      <tr>
                        <td>{t("account.home.companyname")}</td>
                        <td>{spoqProducerData.spoq_producer_name}</td>
                      </tr>
                      <tr>
                        <td>{t("account.home.contactperson")}</td>
                        <td>{spoqProducerData.contact_person_name}</td>
                      </tr>
                      <tr>
                        <td>{t("register.contact_person_email")}</td>
                        <td>{spoqProducerData.contact_email}</td>
                      </tr>
                      <tr>
                        <td>{t("account.home.address")}</td>
                        <td>{spoqProducerData.main_address}</td>
                      </tr>
                      <tr>
                        <td>{t("account.home.country")}</td>
                        <td>{spoqProducerData.country}</td>
                      </tr>
                      <tr>
                        <td>{t("account.home.company_role")}</td>
                        <td>
                          {<div>{getRolesAsString()}</div>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </center>

              }
            </div>
          </div> :
          <center>
            <div>
              <LoadingIndicator></LoadingIndicator>
            </div>
          </center>}

        {spoqUserData.role === 1 && <div>
          <button
            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
            onClick={() => {
              setShowEditCompanyInfoDialog(true)
            }}
          >
            {t("account.home.edit")}
          </button>
        </div>}
        {isMobile ? <h5>{t("account.home.user_data")}</h5> : <h3>{t("account.home.user_data")}</h3>}
        {spoqUserData ?
          <div
            className="w3-center"
          >
            <center>
              <table className="account-table w3-table w3-striped w3-border w3-bordered">
                <tbody>
                  <tr>
                    <td>{t("account.home.user.name")}</td>
                    <td>{spoqUserData.name}</td>
                  </tr>
                  <tr>
                    <td>{t("account.home.user.email")}</td>
                    <td>{spoqUserData.email}</td>
                  </tr>
                  <tr>
                    <td>{t("account.home.user.role")}</td>
                    <td>{spoqUserData.role === 1 ? t("account.register_representative.owner") :
                      spoqUserData.role === 2 ? t("account.register_representative.product_owner") :
                        spoqUserData.role === 3 ? t("account.register_representative.manufacturer_owner") :
                          t("account.register_representative.product_member")} { }</td>
                  </tr>
                </tbody>
              </table>
            </center>

          </div>
          : <div><LoadingIndicator></LoadingIndicator> </div>
        }
      {/* API URLS  */}
      {(spoqUserData.role === 1 || (!spoqUserData && spoqProducerData)) &&
        <div className="w3-center w3-padding-32 w3-margin">
          {!isAPISetupRunning ?
            <div>
              {isMobile ? <h4>API URLs</h4> : <h2>API URLs</h2>}
              <div>
              </div>
              <div>
                {/* Label + textField for each api */}
                <div>
                  <label>{t("account.home.product_api")}</label>
                  <input
                    className="w3-input w3-border w3-round-large w3-margin"
                    type="text"
                    id="product_api_url"
                    placeholder={spoqProducerData.product_api_url}
                    style={{ maxWidth: "800px", display: "inline-block" }}
                  />
                </div>
                <div>
                  <label>{t("account.home.account_api")}</label>
                  <input
                    className="w3-input w3-border w3-round-large w3-margin"
                    type="text"
                    id="account_api_url"
                    placeholder={spoqProducerData.account_api_url}
                    style={{ maxWidth: "800px", display: "inline-block" }}
                  />
                </div>
                <div>
                  <label>{t("account.home.verify_api")}</label>
                  <input
                    className="w3-input w3-border w3-round-large w3-margin"
                    type="text"
                    id="verify_api_url"
                    placeholder={spoqProducerData.verify_api_url}
                    style={{ maxWidth: "800px", display: "inline-block" }}
                  />
                </div>
              </div>
              <button
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={() => {
                  changeApiUrls();
                }}
              >
                {t("account.home.edit.save")}
              </button>
              <center>
                <button
                  className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                  onClick={() => {
                    setShowSetUpAPIDialog(true);
                  }}
                >
                  {t("account.home.setup_api")}
                </button>
              </center>
            </div> : <center>
              <LoadingIndicator></LoadingIndicator>
            </center>}
        </div>
      }        
      </div>


      <SetUpAPIDialog
        show={showSetUpAPIDialog}
        onClose={() => {
          setShowSetUpAPIDialog(false);
        }}
        spoq_producer_id={spoqProducerData.spoq_producer_id}
        onConfirmed={() => { setIsAPISetupRunning(true); }}
        onAccountInfoChanged={() => {
          setIsAPISetupRunning(false)
          onAccountInfoChanged();
        }}
        setIsAPISetupRunning={setIsAPISetupRunning}
        isMobile={isMobile}
      />
      <EditCompanyInfo
        spoqProducerData={spoqProducerData}
        show={showEditCompanyInfoDialog}
        onClose={() => { setShowEditCompanyInfoDialog(false) }}
        onEdited={() => { setShowEditCompanyInfoDialog(false) }}
        onAccountInfoChanged={onAccountInfoChanged}
        isMobile={isMobile}
      />
      <Footer />
    </div>
  );
};

export default AccountLanding;
