import React from "react";
import "../css/basicStyle.css";
import "../css/theme.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomImage } from "./CustomImage";
const AccountHeader = ({spoqProducerData, spoqUserData }) => {
  const { t  } = useTranslation();
  if ( !spoqProducerData) {
    return  <></>
  }
  return (
    <div>
      <div
        className="w3-display-container w3-animate-opacity"
        style={{
          background:
            "linear-gradient(90deg, rgba(38,49,109,1) 0%, rgba(63,81,181,1) 64%, rgb(133, 147, 216) 100%)",
          }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection:"row"}}>
          <div >
            <CustomImage
              id="companyLogo"
              data={spoqProducerData.company_logo} 
              margin={true}/>
          </div>
          <div
           style={{ color: 'white', fontSize: '120px', marginLeft: '24px' }}
            className="w3-margin"
          >
            <h2 style={{ color: "white" }}>{spoqProducerData.spoq_producer_name}</h2>
          </div>
        </div>
      </div>
      {/*navigation*/}
      <div className="w3-bar w3-theme-d2 ">
        {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) &&(<Link
          to="/account/products"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.products")}
        </Link>)}
        {spoqProducerData.product_api_url !== "" && spoqProducerData.producer_boolean && spoqUserData.role <= 2 &&(<Link
          to="/account/register_product"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.register_product")}
        </Link>)}
        {spoqProducerData.product_api_url !== "" && spoqUserData.role <= 2 &&(<Link
          to="/account/stats"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.statistics")}
        </Link>)}
        {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) && spoqUserData.role <=3 &&<Link
          to="/account/charges"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.charges")}
        </Link>}
        {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) && spoqUserData.role <= 3 &&<Link
          to="/account/register_production_scanner"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.production_scanner")}
        </Link>}
        {spoqUserData.role <= 2 &&(<Link
          to="/account/add_associated_manufacturer"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.company_cooperation")}
        </Link>)}
        {spoqProducerData.account_api_url !== "" && spoqUserData.role <= 2 &&(<Link
          to="/account/register"
          className="w3-bar-item w3-button w3-hover-white"
        >
          {t("account.company_accounts")}
        </Link>)}
        <Link to="/account/home" className="w3-bar-item w3-button w3-hover-white">
          {t("account.home.account_data")}
        </Link>
      </div>
    </div>
  )
};

export default AccountHeader;
