import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/RegisterCompany.css"
import ReCAPTCHA from 'react-google-recaptcha';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faVcard,
  faImage,
  faGlobe,
  faLocationPin,
  faCity,
  faMapPin,
  faStreetView,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountrySelect from "../../Components/CountrySelect";
import { postRegisterCompany } from "../../api_functions/AccountInfo";
import { CustomImage, imageToBase64 } from "../../Components/CustomImage";
import { useEffect } from "react";
import { useRef } from "react";
// migration from index.html
const RegisterCompany = ({ spoqProducerData, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [twoFABoolean, setTwoFABoolean] = useState(false);
  const [spoqProducerId, setSpoqProducerId] = useState("");
  const [selectedCompanyRoles, setSelectedCompanyRoles] = useState([]);
  const recaptcha = useRef();
  useEffect(() => {
    console.log(selectedCompanyRoles);
  }, [selectedCompanyRoles])
  useEffect(() => {
    console.log("site key in env:", process.env.REACT_APP_SITE_KEY);
  }, []);
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]|;:'",.<>/?]).{8,}$/;
  const padding = "10px";
  // Define an array of hint texts
  const hintTexts = [
    t("account.register.role_producer_hint"),
    t("account.register.role_manufacturer_hint"),
    t("account.register.role_dealer_hint"),
    t("account.register.role_customer_hint"),
  ];
  const [errorHint, setErrorHint] = useState("");
  function check() {
    var input = document.getElementById("password_confirm");
    var errorLabel = document.getElementById("error_label");
    var password = document.getElementById("password").value;

    var registerButton = document.getElementById("register_button");

    var password_valid = passwordRegex.test(password);
    var passwords_matching = input.value === password;
    if (password_valid) {
      if (passwords_matching) {
        // input is valid -- reset the error message
        input.setCustomValidity("");
        errorLabel.innerHTML = "";
        registerButton.disabled = false;
        registerButton.className =
          "w3-button w3-theme-d5 w3-margin-bottom w3-hover-white";
      } else {
        input.setCustomValidity("Password Must be Matching.");
        errorLabel.innerHTML = t("register.password_not_match");
        errorLabel.style.color = "red";

        registerButton.disabled = true;
        registerButton.className =
          "w3-button w3-theme-d5 w3-margin-bottom w3-disabled";
      }
    } else {
      input.setCustomValidity(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character."
      );
      registerButton.className =
        "w3-button w3-theme-d5 w3-margin-bottom w3-disabled";
      registerButton.disabled = true;
      errorLabel.innerHTML = t("register.password_unsafe");
      errorLabel.style.color = "red";
    }
  }
  function checkMailValid() {
    const contactEmail = document.getElementById("input_contact_email").value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(contactEmail);
    var errorLabel = document.getElementById("error_label_mail");
    var registerButton = document.getElementById("register_button");
    if (isValidEmail) {
      errorLabel.innerHTML = "";
      registerButton.disabled = false;
    } else {
      errorLabel.innerHTML = "Email is not valid";
      errorLabel.style.color = "red";
      registerButton.disabled = true;
    }
  }

  // submit button
  async function handleButtonClick() {
    if(!recaptcha.current.getValue()) {
      setErrorHint("Please verify the reCAPTCHA!");
      return
    }
    const companyName = document.getElementById("input_company_name").value;
    const contactPersonName = document.getElementById(
      "input_contact_person_name"
    ).value;
    const contactEmail = document.getElementById("input_contact_email").value;

    // role can be multiple values
    const selectedRoles = {
      producer_boolean: selectedCompanyRoles.some((role) => role === t("account.roles.producer")),
      contract_manufacturer_boolean: selectedCompanyRoles.some((role) => role === t("account.roles.contract_manufacturer")),
      dealer_boolean: selectedCompanyRoles.some((role) => role === t("account.roles.dealer")),
      customer_boolean: selectedCompanyRoles.some((role) => role === t("account.roles.customer")),
    };

    const streetAddress = document.getElementById("input_street_address").value;
    const postalCode = document.getElementById("input_postal_code").value;
    const city = document.getElementById("input_city").value;
    const country = document.getElementById("input_country").value;
    const password = document.getElementById("password").value;

    // check if all fields are filled out
    if (
      companyName === "" ||
      contactPersonName === "" ||
      contactEmail === "" ||
      streetAddress === "" ||
      postalCode === "" ||
      city === "" ||
      country === "" ||
      password === ""
    ) {
      setErrorHint("Please fill out all fields.");
    } else {
      var company_data = {
        company_name: companyName,
        contact_person_name: contactPersonName,
        contact_person_email: contactEmail,
        logo_image: selectedImage ? selectedImage : "",
        street_and_number: streetAddress,
        zip_code: postalCode,
        city: city,
        country: country,
        password: password,
        producer_boolean: selectedRoles.producer_boolean,
        contract_manufacturer_boolean:
          selectedRoles.contract_manufacturer_boolean,
        dealer_boolean: selectedRoles.dealer_boolean,
        customer_boolean: selectedRoles.customer_boolean,
      }
      //console.log(company_data)
      postRegisterCompany(company_data, setSpoqProducerId, setSelectedImage, setTwoFABoolean, setErrorHint);
    }
  }

  return (
    <>
      <Header
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile}
        showBanner={true} />

      <div className="w3-container w3-center" id="info">
        <div className="w3-content ">
          <p>{t("register.text")}</p>
        </div>
      </div>

      {twoFABoolean ? (
        <div
          style={{
            height: "500px",
            paddingTop: padding,
            paddingBottom: padding,
          }}
          className="w3-center w3-margin"
        >
          <div className="w3-center w3-padding">
            <div className="w3-margin-bottom">
              <span>
                {t("register.producer_id.one")} {spoqProducerId}.{" "}
                {t("register.producer_id.two")}
              </span>
            </div>
            <div className="w3-margin-bottom">
              <label htmlFor="token">{t("register.twofactor.one")}</label>
            </div>
          </div>
          <div>
            <CustomImage data={selectedImage}
              maxHeight={300}
              maxWidth={300} />
          </div>
          <div className="w3-center">
            <button
              type="button"
              className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
            >
              <Link to="/login">{t("register.twofactor.two")}</Link>
            </button>
          </div>
        </div>
      ) : (
        <div className="w3-container w3-padding-64 w3-center" id="features">
          <div
            className="w3-container"
            style={{ backgroundColor: "rgb(219, 228, 255)" }}
          >
            <h2>{t("register.title")}</h2>
            <div
              className="w3-center w3-padding-16"
              style={{ maxWidth: "400px", display: " inline-block" }}
            >
              <p>{t("register.info")}</p>
            </div>
            <form id="iq241d">
              <div id="izkqt5" className="w3-center w3-padding-16">
                <label id="reg_name">
                  <b>
                    <FontAwesomeIcon
                      icon={faVcard}
                      className="w3-margin-right"
                    />
                    {t("register.name")}
                  </b>
                </label>
              </div>
              <div>
                <input
                  type="text"
                  id="input_company_name"
                  required
                  name="reg_name"
                  placeholder="Hier Name eingeben ..."
                  className="w3-input w3-border w3-center"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                />
              </div>

              <div id="iubhfz" className="w3-center w3-padding-16">
                <label id="reg_username">
                  <b>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="w3-margin-right"
                    />
                    {t("register.contact_person_name")}
                  </b>
                </label>
              </div>
              <div>
                <input
                  type="text"
                  id="input_contact_person_name"
                  required
                  className="w3-input w3-border w3-center"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                />
              </div>
              <div id="i8stg2" className="w3-center w3-padding-16">
                <label id="reg_mail">
                  <b>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="w3-margin-right"
                    />
                    {t("register.contact_person_email")}
                  </b>
                </label>
              </div>
              <div>
                <input
                  type="email"
                  id="input_contact_email"
                  required
                  name="reg_email"
                  placeholder="Hier E-Mail eingeben"
                  className="w3-input w3-border w3-center"
                  onChange={checkMailValid}
                  style={{ maxWidth: "400px", display: " inline-block" }}
                />
              </div>
              <div className="w3-center">
                <span
                  id="error_label_mail"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                ></span>
              </div>
              <div id="iubhfz" className="w3-center w3-padding-16">
                <label id="reg_username">
                  <b>
                    <FontAwesomeIcon
                      icon={faImage}
                      className="w3-margin-right"
                    />
                    {t("register.logo")}
                  </b>
                </label>
              </div>
              {selectedImage && (
                <div className="w3-padding-16">
                  <CustomImage
                    data={selectedImage}
                    maxHeight={250}
                    maxWidth={250}
                  />
                  <br />
                  <button
                    onClick={() => {
                      setSelectedImage(null);
                      var input = document.getElementById("input_logo_image");
                      input.value = "";
                    }}
                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                  >
                    {t("button.remove")}
                  </button>
                </div>
              )}
              <input
                type="file"
                name="myImage"
                id="input_logo_image"
                className="w3-padding-16 w3-center"
                onChange={async (event) => {
                  //console.log(event.target.files[0]);
                  var base64Image = await imageToBase64(event.target.files[0])
                  setSelectedImage(base64Image);
                }}
              />
              <center>
                <div id="roleselect" className="role-select-container">
                  <label id="reg_role">
                    <b>
                      {t("register.role")}
                    </b>
                  </label>
                  <center>
                    <table className="role-select-table">
                      <tbody>
                        {[
                          { label: t("account.roles.producer") },
                          { label: t("account.roles.contract_manufacturer") },
                          { label: t("account.roles.dealer") },
                          { label: t("account.roles.customer") },
                        ].map((role, index) => (
                          <tr key={role.label}>
                            <td className="translucent-td">
                              <label>
                                <input
                                  className="role-checkbox"
                                  type="checkbox"
                                  value={role.label}
                                  checked={selectedCompanyRoles.includes(role.label)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedCompanyRoles([...selectedCompanyRoles, role.label]);
                                    } else {
                                      setSelectedCompanyRoles(selectedCompanyRoles.filter((item) => item !== role.label));
                                    }
                                  }}
                                />
                                {role.label}
                              </label>
                            </td>
                            <td>
                              <div className="hint-text">{hintTexts[index]}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </center>
                </div>
              </center>


              <div id="i8stg2" className="w3-center w3-padding-16">
                <b>
                  <FontAwesomeIcon
                    icon={faLocationPin}
                    className="w3-margin-right"
                  />
                  {t("register.address")}
                </b>
              </div>
              <div>
                <div className="w3-center w3-padding-16">
                  <label htmlFor="street-address">
                    <FontAwesomeIcon
                      icon={faStreetView}
                      className="w3-margin-right"
                    />
                    {t("register.street_address")}
                  </label>
                </div>
                <input
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  className="w3-input w3-border w3-center"
                  type="text"
                  id="input_street_address"
                  name="street-address"
                  autoComplete="street-address"
                  required
                  enterKeyHint="next"
                ></input>
              </div>
              <div>
                <div className="w3-center w3-padding-16">
                  <label htmlFor="postal-code">
                    <FontAwesomeIcon
                      icon={faMapPin}
                      className="w3-margin-right"
                    />
                    {t("register.zip")}
                  </label>
                </div>
                <input
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  className="w3-input w3-border w3-center postal-code"
                  id="input_postal_code"
                  name="postal-code"
                  autoComplete="postal-code"
                  enterKeyHint="next"
                />
              </div>
              <div>
                <div className="w3-center w3-padding-16">
                  <label htmlFor="city">
                    <FontAwesomeIcon
                      icon={faCity}
                      className="w3-margin-right"
                    />
                    {t("register.city")}
                  </label>
                </div>
                <input
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  className="w3-input w3-border w3-center"
                  required
                  type="text"
                  id="input_city"
                  name="city"
                  autoComplete="address-level2"
                  enterKeyHint="next"
                />
              </div>
              <div>
                <div className="w3-center w3-padding-16">
                  <label htmlFor="country">
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className="w3-margin-right"
                    />
                    {t("register.country")}
                  </label>
                </div>
                <select
                  id="input_country"
                  name="country"
                  autoComplete="country"
                  enterKeyHint="done"
                  className="w3-input w3-border w3-center"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  required
                >
                  <CountrySelect />
                </select>
              </div>

              {/* enter password twice */}
              <div className="w3-center w3-padding-16">
                <span style={{ maxWidth: "400px", display: " inline-block" }}>
                  {t("register.password_info")}
                </span>
              </div>
              <div className="w3-center w3-padding-16">
                <div className="w3-center w3-padding-16">
                  <label htmlFor="password">
                    <b>
                      <FontAwesomeIcon
                        icon={faLock}
                        className="w3-margin-right"
                      />
                      {t("register.password")}
                    </b>
                  </label>
                </div>
                <input
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  className="w3-input w3-border w3-center"
                  name="password"
                  required="required"
                  type="password"
                  id="password"
                  onChange={() => check()}
                />
              </div>

              <div>
                <div className="w3-center w3-padding-16">
                  <label htmlFor="password_confirm">
                    {t("register.confirm_password")}
                  </label>
                </div>
                <input
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  className="w3-input w3-border w3-center"
                  name="password_confirm"
                  required="required"
                  type="password"
                  id="password_confirm"
                  onChange={() => check()}
                />
              </div>

              <div className="w3-center">
                <span
                  id="error_label"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                ></span>
              </div>

              <div className="w3-center w3-padding-16">
                <button
                  type="button"
                  className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding w3-disabled"
                  id="register_button"
                  onClick={() => handleButtonClick()}
                >
                  {t("register.submit")}
                </button>
              </div>
              {errorHint !== "" && <center style={{margin:'20px'}}>
              <label style={{color:'red'}}>{errorHint}</label>
                </center>}
              <div className="w3-center w3-padding-16">
                <center>
                  <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                </center>
              </div>
            </form>
            <div className="w3-center w3-padding-16">
              <span className="noacc">
                <Link to="/login">{t("register.hasaccount")}</Link>
              </span>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default RegisterCompany;
