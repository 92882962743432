import React, { useState, useRef } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postUpdateUserRole, postDeleteUser } from "../../api_functions/AccountInfo";
import {
    faUser,
    faIndustry,
    faEnvelope,
    faLock,
}
    from "@fortawesome/free-solid-svg-icons";
import 'react-circular-progressbar/dist/styles.css';
// page containing tde base information of a company including tde option to
// edit tde information
function CompanyAccountTile({
    spoqProducerData,
    userData,
    onAccountChanged }) {
    const [changeUserRoleMode, setChangeUserModeRole] = useState(false);
    const [selectedUserRole, setSelectedUserRole] = useState(userData.role);
    const isContactPerson = spoqProducerData.contact_email === userData.email;
    const { t } = useTranslation();
    return (
        <div style={{ display: "flex", flexDirection: "row", width: "70%", minWidth: "100px", border: "2px solid #ccc", borderRadius: "10px", padding: "10px", margin: "10px" }}>
            <div style={{ flex: "1", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <center>
                            <FontAwesomeIcon
                                icon={faUser}
                                className="w3-margin-right"
                            />
                        </center>
                        <label>{userData.name}</label>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <center>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="w3-margin-right"
                            />
                        </center>
                        <label>{userData.email}</label>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <center>
                            <FontAwesomeIcon
                                icon={faIndustry}
                                className="w3-margin-right"
                            />
                        </center>
                        {changeUserRoleMode ?
                            <Select
                                className="w3-input w3-border w3-margin"
                                id="input_role"
                                options={[
                                    { value: 1, label: t("account.register_representative.owner"), },
                                    { value: 2, label: t("account.register_representative.product_owner"), },
                                    { value: 3, label: t("account.register_representative.manufacturer_owner"), },
                                    { value: 4, label: t("account.register_representative.product_member"), }
                                ]}
                                placeholder={userData.role === 1 ? t("account.register_representative.owner") :
                                    userData.role === 2 ? t("account.register_representative.product_owner") :
                                        userData.role === 3 ? t("account.register_representative.manufacturer_owner") :
                                            t("account.register_representative.product_member")}
                                labelField="label"
                                valueField="value"
                                style={{ minWidth: "150px", maxWidth: "400px", display: " inline-block", backgroundColor: "#ffffff" }}
                                onChange={(value) => {
                                    console.log("Select Value:", value)
                                    setSelectedUserRole(value[0].value);
                                }}>
                            </Select>
                            :
                            <label>{userData.role === 1 ? t("account.register_representative.owner") :
                                userData.role === 2 ? t("account.register_representative.product_owner") :
                                    userData.role === 3 ? t("account.register_representative.manufacturer_owner") :
                                        t("account.register_representative.product_member")}
                            </label>}
                    </div>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                {!changeUserRoleMode && <button
                    className="delete-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                    onClick={() => {
                        postDeleteUser(spoqProducerData.account_api_url, userData.email);
                        onAccountChanged();
                    }}
                    disabled={isContactPerson}
                >
                    {t("account.administer.delete_user")}
                </button>}
                <button
                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                    onClick={changeUserRoleMode ? () => {
                        postUpdateUserRole(spoqProducerData.account_api_url, userData.email, selectedUserRole);
                        setChangeUserModeRole(false);
                        onAccountChanged();
                    } : () => {
                        setChangeUserModeRole(true);
                    }}
                    disabled={isContactPerson}
                >
                    {changeUserRoleMode ? t("account.administer.confirm_change") : t("account.administer.change_user_role")}
                </button>                    
                </div>
                {isContactPerson && <label style={{color:"red"}}>
                    {t("account.administer.contact_person_hint")}
                </label>}
            </div>

        </div>

    );
}

export default CompanyAccountTile;
