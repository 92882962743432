import React, { StrictMode, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import Home from './pages/Home/Home';
import Impressum from './pages/Impressum/Impressum';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Download from './pages/Download/Download';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './services/i18n';
import AccountLanding from './pages/Account/AccountLanding';
import AccountStatistics from './pages/Statistics/AccountStatistics';
import Products from './pages/Products/Products';
import RegisterCompany from './pages/RegisterCompany/RegisterCompany';
import Login from './pages/Login/Login';
import CompanyAccounts from './pages/CompanyAccounts/CompanyAccounts';
import RegisterProducts from './pages/RegisterProduct/RegisterProducts';
import ForgotPassword from './pages/Login/ForgotPassword';
import Charges from './pages/Charges/Charges';
import AddAssociatedManufacturer from './pages/CompanyCooperations/AddAssociatedManufacturer';
import ProductionScanner from './pages/Scanners/ProductionScanner';
import { fetchAccountInfo } from './api_functions/AccountInfo';

function App() {
  const [spoqProducerData, setSpoqProducerData] = useState(false);
  const [spoqUserData, setSpoqUserData] = useState(false);
  const [spoqId, setSpoqId] = useState(false);
  const [foreignProductApiUrls, setForeignProductApiUrls] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerHeight > window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      const check_mobile = (window.innerHeight > window.innerWidth) || (window.innerWidth<750);
      if (check_mobile !== isMobile) {
        setIsMobile(check_mobile);
      }
    }
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile])


  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  useEffect(() => {
    fetchAccountInfo(setSpoqId, setSpoqProducerData, setForeignProductApiUrls, setSpoqUserData);
  }, []);
  useEffect(() => {
    console.log("index.js spoqProducerData Changed to:", spoqProducerData)
  }, [spoqProducerData]);

  async function onAccountInfoChanged() {
    await delay(500);
    fetchAccountInfo(setSpoqId, setSpoqProducerData, setForeignProductApiUrls, setSpoqUserData);
  }
  const router = createBrowserRouter([
    {
      path: '/home',
      element: <Home
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/impressum',
      element: <Impressum
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/register',
      element: <RegisterCompany
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/login',
      element: <Login
        onAccountInfoChanged={onAccountInfoChanged}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile}
      />,
    },
    {
      path: '/',
      element: <Home
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/download',
      element: <Download
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/home',
      element:
        <AccountLanding
          spoqProducerData={spoqProducerData}        
          spoqId={spoqId}
          spoqUserData={spoqUserData}
          onAccountInfoChanged={onAccountInfoChanged}
          isMobile={isMobile}
        />
      ,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/stats',
      element: <AccountStatistics
        foreign_product_api_urls={foreignProductApiUrls}
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/products',
      element: <Products
        foreign_product_api_urls={foreignProductApiUrls}
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/register_product',
      element: <RegisterProducts
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/register',
      element: <CompanyAccounts
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData} 
        isMobile={isMobile}/>,
    },
    {
      path: '/forgot_password',
      element: <ForgotPassword
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/charges',
      element: <Charges
        foreign_product_api_urls={foreignProductApiUrls}
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/add_associated_manufacturer',
      element: <AddAssociatedManufacturer
        spoqId={spoqId}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        onCooperationsChanged={() => {
          onAccountInfoChanged();
        }}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
    {
      path: '/account/register_production_scanner',
      element: <ProductionScanner
        foreign_product_api_urls={foreignProductApiUrls}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
      errorElement: <ErrorPage
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />,
    },
  ]);

  return (
    <StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider
          router={router}
        />
      </Suspense>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();