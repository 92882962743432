// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useState, useRef } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next";
import 'react-circular-progressbar/dist/styles.css';
import { postUpdateSpoqIdentity, fetchImageForSpoqId } from "../../api_functions/ChargeInfo";
import { CustomImage, imageToBase64 } from "../../Components/CustomImage";
// page containing tde base information of a company including tde option to
// edit tde information
function SpoqIdentityTile({
    product_api_url,
    chargeInfo,
    productInfo,
    onSpoqIdentityUpdated,
    spoqProductId,
    isManufacturerView,
    isFinished }) {
    const { t } = useTranslation();
    const fileInputRef_single_png = useRef(null);
    const [uploadError, setUploadError] = useState("");
    const [existingImageData, setExistingImageData] = useState(null);
    async function handleSingleFileSelected(event) {
        const file = event.target.files[0];
        if (!file) {
            setUploadError("Selected file for upload is not a valid PNG file");
            return;
        }
        const filename = file.name;
        const spoqProductIdInFilename = filename.includes(spoqProductId);
        if (!spoqProductIdInFilename) {
            setUploadError("Selected file does not contain the spoqProductId in its name");
            return;
        }
        const imageData = await imageToBase64(file);
        if (!imageData) {
            setUploadError("Failed to read the PNG file");
            return;
        }
        // Making API Call to add SPOQ Identity for the specified spoqProductId
        const spoqIdentityData = {
            "product_id": chargeInfo.product_id,
            "charge_id": chargeInfo.charge_id,
            "date_of_manufacture": Date.now(),
            "contract_manufacturer": chargeInfo.contract_manufacturer_id,
            "qr_image": imageData,
            "verification_method": 1,
            "spoq_product_id": parseInt(spoqProductId, 16) // Convert hex to integer
        };
        setUploadError("")
        postUpdateSpoqIdentity(product_api_url, spoqIdentityData, spoqProductId);
        onSpoqIdentityUpdated();
    }
    return (
        <div>
            <div key={spoqProductId} className="spoq-product-id-tile standard-row">
                <label style={{wordBreak:"break-all"}}>{spoqProductId}</label>
                { isManufacturerView && <div className="standard-row">
                    <input
                    id={"file_input_ " + spoqProductId}
                    type="file"
                    ref={fileInputRef_single_png}
                    accept=".png"
                    style={{ display: "none" }}
                    onChange={(event) => { handleSingleFileSelected(event); }}
                />
                <label
                    htmlFor={"file_input_ " + spoqProductId}
                    className="w3-button w3-theme-d5">
                    <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
                    <i className={"fa-solid fa-upload"} style={{ marginRight: "10px" }}></i>
                    {t("account.charges.upload_qr_png")}
                </label> </div>}
                {uploadError !== "" && <div>{uploadError}</div>}
                {isFinished && <label
                    className="w3-button w3-theme-d5"
                    onClick={() => {
                        fetchImageForSpoqId(product_api_url, spoqProductId, productInfo.product_id, setExistingImageData)
                    }}
                >
                    <i className={"fa-solid fa-download"} style={{ marginRight: "10px" }}></i>
                    {t("account.charges.view_existing_image")}
                </label>}
            </div>
            <center>
                <CustomImage
                data={existingImageData}
                maxHeight={100}
                maxWidth={100}
            /></center>
        </div>
    );
}

export default SpoqIdentityTile;
