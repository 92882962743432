// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/ProductionScanners.css"
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { fetchCompanyInfo } from "../../api_functions/AccountInfo";
import ScannerDetail from "./ScannerDetail";
function ScannerTile({ selectedProducer, selectedProduct, spoqProducerData, scannerData, foreign_product_api_urls, onScannerUpdated, isMobile }) {
  const { t } = useTranslation();
  const [publicCompanyData, setPublicCompanyData] = useState(null);
  const [showScannerDetail, setShowScannerDetail] = useState(false);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (scannerData.contract_manufacturer_id) {
        fetchCompanyInfo(scannerData.contract_manufacturer_id.toString(16), setPublicCompanyData)
    }
}, [scannerData])
  function intToHexString(num, length) {
    // Convert the integer to a hexadecimal string without leading "0x".
    let hexString = num.toString(16);

    // Pad the string with leading zeros to ensure a fixed length of 5.
    while (hexString.length < length) {
      hexString = '0' + hexString;
    }

    return hexString;
  }
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  }
  return (
    <div>
      {!isMobile && <div className="standard-row" key={scannerData.id}>
        <table className="w3-table w3-striped w3-border w3-bordered scanner-table">
          <tbody>
            <tr>
              <td>{t("account.production_scanner.scanner_name")}</td>
              <td>
                {scannerData.scanner_name}
              </td>
            </tr>
            <tr>
              <td>{t("account.charges.product_id")}</td>
              <td>
                {scannerData.product_id.toString()}
              </td>
            </tr>
            <tr>
              <td>{t("account.statistics.charges")}</td>
              <td>
                {scannerData.charges.map((charge, index) => (<div key={index}>{charge}</div>))}
              </td>
            </tr>
            <tr>
              <td>{t("account.production_scanner.owner")}</td>
              <td>
                {publicCompanyData ? publicCompanyData.company_name : ""}
              </td>
            </tr>
            <tr>
              <td>{t("account.production_scanner.expire_date")}</td>
              <td>
                {scannerData.expire_date ? formatDate(new Date(scannerData.expire_date)) : "No Scanner Key has been set yet"}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button
            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
            onClick={() => {
              console.log("Show Scanner Detail")
              setShowScannerDetail(true);
            }}>
            <i className={"fa fa-key"} style={{ marginRight: "10px" }}></i>
            {t("account.production_scanner.detail")}
          </button>
        </div>
      </div>}

      {isMobile && <div 
      onClick={() => {
        setExpanded(!expanded)
      }}>
        <div className="mobile-scanner">
          <center style={{flex: "6"}}> {scannerData.scanner_name} </center>
          <center style={{flex: "2"}}>
            <button
              className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
              onClick={() => {
                console.log("Show Scanner Detail")
                setShowScannerDetail(true);
              }}
              style={{fontSize:"11px"}}>
              <i className={"fa fa-key"} style={{ marginRight: "10px" }}></i>
              {t("account.production_scanner.detail")}
            </button>
          </center>
          <center style={{ flex: "1" }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </center>
        </div>
        {expanded && <div className="mobile-scanner">
          <table className="w3-table w3-border w3-bordered scanner-table">
          <tbody>
            <tr>
              <td>{t("account.charges.product_id")}</td>
              <td>
                {scannerData.product_id.toString()}
              </td>
            </tr>
            <tr>
              <td>{t("account.statistics.charges")}</td>
              <td>
                {scannerData.charges.map((charge) => (<div>{charge}</div>))}
              </td>
            </tr>
            <tr>
              <td>{t("account.production_scanner.owner")}</td>
              <td>
                {scannerData.contract_manufacturer_id}
              </td>
            </tr>
            <tr>
              <td>{t("account.production_scanner.expire_date")}</td>
              <td>
                {scannerData.expire_date ? formatDate(new Date(scannerData.expire_date)) : "No Scanner Key has been set yet"}
              </td>
            </tr>
          </tbody>
        </table>

        </div>}
      
      <div style={{height:"5px"}}/>
      </div>}
      {selectedProducer && spoqProducerData &&
          <ScannerDetail
            show={showScannerDetail}
            productInfo={selectedProduct}
            selected_producer={selectedProducer}
            spoqProducerData={spoqProducerData}
            product_api_url={selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id ?
              spoqProducerData.product_api_url :
              foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id)[0].product_api_url

            }
            onClose={() => {
              setShowScannerDetail(false);
            }}
            scannerInfo={scannerData}
            onScannerUpdated={() => { onScannerUpdated(); }}
            isMobile={isMobile}
          />}
    </div>


  );
}

export default ScannerTile;
