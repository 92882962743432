import { useRouteError } from "react-router-dom";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";

export default function ErrorPage({spoqProducerData, spoqUserData, isMobile}) {
  const error = useRouteError();
  console.error(error);
  const { t } = useTranslation()
  return (
    <>
    <Header
    spoqProducerData= {spoqProducerData}
    isMobile={isMobile}
    showBanner={false}
    spoqUserData={spoqUserData}/>
    <div id="error-page" className="w3-center w3-padding-64 w3-margin">
      <h1>{t("error.oops")}</h1>
      <p>{t("error.message")}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
    <Footer />
    </>
  );
}