import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
// placeholder images
import holdingPhone1 from "../../images/placeholder/holdingPhone1.jpg";
import holdingPhone2 from "../../images/placeholder/holdingPhone2.jpg";
import holdingPhone3 from "../../images/placeholder/holdingPhone3.jpg";
import spoqApp from "../../images/placeholder/SPOQ_App.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Footer from "../../Components/Footer";
import {
  faApple,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faShield,
  faCamera,
  faBolt,
  faAngleRight,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import ApkFile from "../../assets/app-release.apk"
import Header from "../../Components/Header";

// migration from index.html
const Download = ({spoqProducerData, spoqUserData, isMobile}) => {
  const mySlidesRef = useRef([]);
  var [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    // Access the elements using the ref's current property
    // console.log("useEffect");
    const elements = mySlidesRef.current;

    if (elements.length === 0) {
      //console.log("no slides found");
      return;
    }
    // console.log(mySlidesRef.current);
    if (slideIndex > elements.length) {
      setSlideIndex(1);
    }
    if (slideIndex < 1) {
      setSlideIndex(elements.length);
    }
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]) {
        elements[i].style.display = "none";
      }
    }
    if (elements[slideIndex - 1]) {
      elements[slideIndex - 1].style.display = "block";
    }
  }, [slideIndex]);

  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  const producerParameter = getQueryVariable("producer_name");
  const companyName = producerParameter
    ? `Sie haben einen QR Code von ${producerParameter} gescannt!`
    : "";

  const { t } = useTranslation();

  return (
    <>
      <Header
      spoqProducerData={spoqProducerData}
      isMobile={isMobile}
      showBanner={true}
      spoqUserData={spoqUserData}/>
      <div className="w3-content w3-black" style={{ maxWidth: "1500px" }}>
        <header className="w3-display-container w3-center">
          <h1 className="w3-xlarge w3-text-white" id="company_name">
            {companyName}
          </h1>
          <button
            className="w3-button w3-block w3-indigo w3-hide-large w3-hide-medium"
            onClick={() => {
              var download = document.getElementById("download");
              if (download) {
                document.getElementById("download").style.display = "block";
              }
            }}
          >
            {t("download.download")}<FontAwesomeIcon icon={faAndroid} />{" "}
            <FontAwesomeIcon icon={faApple} />
          </button>
          <div
            ref={(element) => {
              if (element && !mySlidesRef.current.includes(element)) {
                mySlidesRef.current.push(element);
              }
            }}
            className="mySlides w3-animate-opacity"
            style={{ display: "block" }}
          >
            <img
              className="w3-image"
              src={holdingPhone1}
              alt=""
              style={{ minWidth: "500px" }}
              width="1500"
              height="1000"
            />
            <div
              className="w3-display-left w3-padding w3-hide-small"
              style={{ width: "35%" }}
            >
              <div className="w3-theme-l3 w3-opacity w3-hover-opacity-off w3-padding-large w3-round-large">
                <h1 className="w3-xlarge">{t("download.info1")}</h1>
                <hr className="w3-opacity" />
                <p>{t("download.info1.sub")}</p>
                <p>
                  <button
                    className="w3-button w3-block w3-indigo w3-round"
                    onClick={() => {
                      document.getElementById("download").style.display =
                        "block";
                    }}
                  >
                    {t("download.download")} <FontAwesomeIcon icon={faAndroid} />{" "}
                    <FontAwesomeIcon icon={faApple} />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div
            ref={(element) => {
              if (element && !mySlidesRef.current.includes(element)) {
                mySlidesRef.current.push(element);
              }
            }}
            className="mySlides w3-animate-opacity"
            style={{ display: "none" }}
          >
            <img
              className="w3-image"
              src={holdingPhone2}
              alt=""
              style={{ minWidth: "500px" }}
              width="1500"
              height="1000"
            />
            <div
              className="w3-display-left w3-padding w3-hide-small"
              style={{ width: "35%" }}
            >
              <div className="w3-theme-l3 w3-opacity w3-hover-opacity-off w3-padding-large w3-round-large">
                <h1 className="w3-xlarge">
                {t("download.info2")}
                </h1>
                <hr className="w3-opacity" />
                <p>
                {t("download.info2.sub")}{" "}
                </p>
                <p>
                  <button
                    className="w3-button w3-block w3-indigo w3-round"
                    onClick={() => {
                      var download = document.getElementById("download");
                      if (download) {
                        document.getElementById("download").style.display =
                          "block";
                      }
                    }}
                  >
                    {t("download.download")} <FontAwesomeIcon icon={faAndroid} />{" "}
                    <FontAwesomeIcon icon={faApple} />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div
            ref={(element) => {
              if (element && !mySlidesRef.current.includes(element)) {
                mySlidesRef.current.push(element);
              }
            }}
            className="mySlides w3-animate-opacity"
            style={{ display: "none" }}
          >
            <img
              className="w3-image"
              src={holdingPhone3}
              alt=""
              style={{ minWidth: "500px" }}
              width="1500"
              height="1000"
            />
            <div
              className="w3-display-left w3-padding w3-hide-small"
              style={{ width: "35%" }}
            >
              <div className="w3-theme-l3 w3-opacity w3-hover-opacity-off w3-padding-large w3-round-large">
                <h1 className="w3-xlarge">{t("download.info3")}</h1>
                <hr className="w3-opacity" />
                <p>
                {t("download.info3.sub")}{" "}
                </p>
                <p>
                  <button
                    className="w3-button w3-block w3-indigo w3-round"
                    onClick={() => {
                      var download = document.getElementById("download");
                      if (download) {
                        document.getElementById("download").style.display =
                          "block";
                      }
                    }}
                  >
                    {t("download.download")} <FontAwesomeIcon icon={faAndroid} />{" "}
                    <FontAwesomeIcon icon={faApple} />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <Link
            className="w3-button w3-indigo w3-display-right w3-margin-right w3-round w3-hide-small w3-hover-light-grey"
            onClick={() => {
              setSlideIndex((slideIndex) => slideIndex + 1);
              // console.log("Pressed button: " + slideIndex);
            }}
          >
            {t("download.tour")}  <FontAwesomeIcon icon={faAngleRight} />
          </Link>
          <Link
            className="w3-button w3-block w3-indigo w3-hide-large w3-hide-medium"
            onClick={() => {
              setSlideIndex((slideIndex) => slideIndex + 1);
              //console.log("Pressed button: " + slideIndex);
            }}
          >
           {t("download.tour")}<FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </header>

        {/* The App Section */}
        <div className="w3-padding-64 w3-white">
          <div className="w3-row-padding">{/* Content */}</div>
        </div>

        {/* Modal */}
        <div id="download" className="w3-modal w3-animate-opacity">
          <div className="w3-modal-content" style={{ padding: "32px" }}>
            <div className="w3-container w3-white">
              <i
                onClick={() => {
                  var download = document.getElementById("download");
                  if (download) {
                    document.getElementById("download").style.display = "none";
                  }
                }}
                
                className="w3-xlarge w3-button w3-transparent w3-right w3-xlarge"
              >
                 <FontAwesomeIcon icon={faRemove} className="w3-xlarge w3-button w3-transparent w3-right w3-xlarge" />
              </i>
              <h2 className="w3-wide">{t("download.download")}</h2>
              <p>
                {t("download.download_to_device")}
              </p>
              <p>
              {t("download.download_dialog_text")}
              </p>
              
              <div className = "w3-margin w3-center">
              <a
                href={ApkFile}
                download={"app-release.apk"}
                target="_blank"
                rel="noreferrer"
                className="w3-button w3-padding-large w3-theme-d5 w3-margin"
              >
                <FontAwesomeIcon icon={faAndroid} className="w3-margin-right"/> Download Demo .apk
              </a>
              <a
                href={ApkFile}
                download={"app-release.apk"}
                target="_blank"
                rel="noreferrer"
                className="w3-button w3-padding-large w3-theme-d5 w3-margin"
              >
                <FontAwesomeIcon icon={faApple} className="w3-margin-right"/> Download Demo .ipa (not available yet)
              </a>
              </div>
            </div>
          </div>
        </div>

        <div className="w3-padding-64 w3-light-grey">
          <div className="w3-row-padding w3-margin-right">
            <div className="w3-col l4 m6">
              <img
                className="w3-image w3-round-large w3-hide-small w3-grayscale"
                src={spoqApp}
                alt="App"
                width="335"
                height="471"
              />
            </div>
            <div className="w3-col l8 m6 ">
              <h1 className="w3-jumbo">
                <b>{t("download.ad.1")}</b>
              </h1>
              <h1 className="w3-xxxlarge w3-text-indigo">
                <b>{t("download.ad.2")}</b>
              </h1>
              <p>
                <span className="w3-xlarge ">
                {t("download.ad.3")}
                </span>{" "}
                {t("download.ad.text1")}
              </p>

              <p>
                <span className="w3-xlarge">
                {t("download.ad.4")}
                </span>{" "}
                {t("download.ad.text2")}
              </p>
            </div>
          </div>
        </div>

        <div className="w3-container w3-padding-64 w3-theme-d5 w3-center">
          <h1 className="w3-jumbo">
            <b>{t("download.features")}</b>
          </h1>
          <p>
            {t("download.features.text")}
          </p>

          <div className="w3-row" style={{ marginTop: "64px" }}>
            <div className="w3-col s3">
              <FontAwesomeIcon
                icon={faBolt}
                className="w3-text-white w3-jumbo"
              />
              <p>{t("download.features.fast")}</p>
            </div>
            <div className="w3-col s3">
              <FontAwesomeIcon
                icon={faCamera}
                className="w3-text-white w3-jumbo"
              />
              <p>{t("download.features.camera")}</p>
            </div>
            <div className="w3-col s3">
              <FontAwesomeIcon
                icon={faGlobe}
                className="w3-text-white w3-jumbo"
              />
              <p>{t("download.features.global")}</p>
            </div>
            <div className="w3-col s3">
              <FontAwesomeIcon
                icon={faShield}
                className="w3-text-white w3-jumbo"
              />
              <p>{t("download.features.authentic")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Download;
