// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { postGenerateScannerKey } from "../../api_functions/ScannerInfo";
import { TextField } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";


// page containing tde base information of a company including tde option to
// edit tde information
function ScannerDetail({
    show,
    product_api_url,
    onClose,
    productInfo,
    scannerInfo,
    spoqProducerData,
    selected_producer,
    onScannerUpdated,
    isMobile 
}) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    console.log(selectedDate)
    const { t } = useTranslation();
    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    if (!show) return null;
    return (
        <div className="edit-product-dialog" style={isMobile ? {fontSize:"11px"} : {}}>
            <div className="edit-product-dialog-content">
                {isMobile ? <h4>{t("account.production_scanner.detail")}</h4> : <h2>{t("account.production_scanner.detail")}</h2>}
                <table className="w3-table w3-striped w3-border w3-bordered">
                    <tbody>
                        <tr>
                            <td>{t("account.charges.product_id")}</td>
                            <td id="info_product_id">
                                <center>{productInfo.product_id}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.products.product_name")}</td>
                            <td id="info_product_id">
                                <center>{productInfo.product_name}</center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.production_scanner.scanner_id")}</td>
                            <td>
                                <center>
                                    {scannerInfo.scanner_id}
                                </center>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("account.production_scanner.scanner_name")}</td>
                            <td>
                                <center>
                                    {scannerInfo.scanner_name}
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {spoqProducerData.spoq_producer_id === selected_producer.spoq_producer_id && 
                    <div className="scanner-generate-key-tile">
                        {isMobile ? <h6>{t("account.production_scanner.generate_key_with_expire")}</h6> : <h4>{t("account.production_scanner.generate_key_with_expire")}</h4>}
                        <div className="standard-row">
                            <div>
                            {t("account.production_scanner.expire_date")}
                            <DatePicker
                                dateFormat={'dd-MM-yyyy'}
                                minDate={new Date()}
                                selected={selectedDate}
                                onSelect={(date) => { setSelectedDate(date) }} //when day is clicked
                                onChange={(date) => { setSelectedDate(date) }} //only when value has changed
                            />                                
                             </div>

                            <button
                                className="w3-button w3-theme-d5  w3-round-large w3-padding"
                                onClick={() => {
                                    console.log("Generate Key with Date:", selectedDate)
                                    if (selectedDate) {
                                        postGenerateScannerKey(product_api_url,
                                            {
                                                "id": scannerInfo.scanner_id,
                                                "scanner_name": scannerInfo.scanner_name,
                                                "contract_manufacturer_id": parseInt(scannerInfo.contract_manufacturer_id, 16),
                                                "product_id": scannerInfo.product_id,
                                                "scanner_key": scannerInfo.scanner_key,
                                                "expire_date": selectedDate.getTime()
                                            },
                                            selected_producer.spoq_producer_id)
                                        delay(600)
                                        onScannerUpdated();
                                    }
                                }}>
                                <i className={"fa fa-key"} style={{ marginRight: "10px" }}></i>
                                {t("account.production_scanner.generate_key")}
                            </button>
                        </div>
                    </div>
                }
                {spoqProducerData.contract_manufacturer_boolean &&
                    <div className="scanner-generate-key-tile">
                        {isMobile ? <h6>{t("account.production_scanner.scanner_key")}</h6> : <h4>{t("account.production_scanner.scanner_key")}</h4>}
                        {scannerInfo.scanner_key === "" || !scannerInfo.scanner_key ?
                            <div>{t("account.production_scanner.key_not_availabele")}</div> :
                            <CopyToClipboard text={scannerInfo.scanner_key}
                                onCopy={() => { }}>
                                <div style={{justifyContent:"flex"}}>
                                    <i className="far fa-copy" style={{ position: "absolute", }}></i>
                                    <TextField
                                        value={scannerInfo.scanner_key}>
                                    </TextField>

                                </div>

                            </CopyToClipboard>}
                    </div>
                }
                <div className="fixed-buttons-dialog">
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("button.close")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ScannerDetail;
