import React from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom"; // for routing to other pages
import { useTranslation } from "react-i18next";
import Logo from "../../images/Spoq_Globe.png";
// migration from index.html
const Impressum = ({ spoqProducerData, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  return (
    <>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={true}
        spoqUserData={spoqUserData} />
      <div
        className="w3-display-container w3-animate-opacity"
        style={{
          background:
            "linear-gradient(90deg, rgba(38,49,109,1) 0%, rgba(63,81,181,1) 64%, rgb(133, 147, 216) 100%)",
          position: "unset"
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ minHeight: "20vh", maxHeight: "20vh" }}
          />

          <div style={isMobile ? { color: 'white', fontSize: '60px', marginLeft: '24px' } : { color: 'white', fontSize: '120px', marginLeft: '24px' }}>
            SPOQ
          </div>
        </div>
      </div>
      <div className=" w3-container w3-center w3-padding-64 w3-theme-l3">
        <div className="w3-content">
          <h3>{t("impressum.1")}</h3>
          <p>
            {t("impressum.2")}
            <br />
            <br />
            {t("impressum.3")}
            <br />
            Paul-Wittsack-Straße 10
            <br />
            68163 Mannheim
            <br />
            {t("impressum.4")}: +49 621 292-6111
            <br />
            {t("impressum.5")}: +49 621 292-6420
            <br />
            {t("impressum.6")}: info@hs-mannheim.de
            <br />
            <br />
            {t("impressum.7")}
            <br />
            <br />
            {t("impressum.8")}
          </p>
        </div>
      </div>

      <div className=" w3-container w3-center w3-padding-64 w3-white">
        <div className="w3-content">
          <h3>{t("impressum.9")}</h3>
          {t("impressum.10")}
          <br id="iiy2ko" />
          <br id="i7mxfl" />
          <span id="i3rhc8">
            {t("impressum.11")}
          </span>
          <br id="ilrym9" />
          <br id="ixkfln" />
          <span id="isi4en">
            {t("impressum.12")}
            <br />
          </span>
          <br />
          <b>{t("impressum.13")}</b>
          <br />
          {t("impressum.14")}
          <br />
          {t("impressum.15")}
          <br />
          {t("impressum.16")}
          <br />
          {t("impressum.17")}
          <br />
          {t("impressum.18")}
          <br />
          <span id="il2nmt">
            {t("impressum.19")}
            <br />
            <br />
            <b>{t("impressum.20")}</b>
            <br />
            <Link to="" id="ikjmb7"></Link>
            <Link to="https://www.cit.hs-mannheim.de/" id="ib3nhj">
              {t("impressum.21")}
            </Link>
            <br />
            <br />
          </span>
          <p id="i4buew"></p>
        </div>
      </div>

      <div className="w3-container w3-center w3-padding-64  w3-theme-l3">
        {/* Haftungsausschluss */}
        <div className="w3-content">
          <h3>{t("impressum.22")}</h3>
          <b>{t("impressum.23")}</b>
          <br />
          <br />
          {t("impressum.24")}
          <br />
          <br />
          <b>{t("impressum.25")}</b>
          <br />
          <br />
          {t("impressum.26")}
          <br />
          <br />
          {t("impressum.27")}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Impressum;
