export const accountApi = "https://debug.account.spoq.org"

// cache durations and keys 
export const CACHE_KEY_ACCOUNT_INFO = "cachedAccountInfo"
export const CACHE_KEY_ACCOUNT_INFO_EXPIRY_MS = 600000

export const CACHE_KEY_USER_ROLE = "cachedUserRole";
export const CACHE_KEY_ALL_OWNERS = "cachedAllOwners";
export const CACHE_KEY_ACCOUNT_LANDING_EXPIRY = 300000;

export const CACHE_KEY_STATISTICS_USERS = "userStatisticsCache";
export const CACHE_KEY_STATISTICS_PRODUCTS = "productStatisticsCache";
export const CACHE_DURATION_MS_STATISTICS = 1000 * 60 * 5; // 5 minutes

export const CACHE_KEY_PRODUCTS = "cachedProductInfo";
export const CACHE_PRODUCT_DURATION_MS = 180000; // Cache duration in milliseconds (3 minutes)