import React, { useState, useEffect } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Footer from "../../Components/Footer";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faIndustry,
  faEnvelope,
  faLock,
}
  from "@fortawesome/free-solid-svg-icons";
import { postRegisterUser, fetchAllCompanyUsers } from "../../api_functions/AccountInfo";
import Select from "react-dropdown-select";
import Header from "../../Components/Header";
import CompanyAccountTile from "./CompanyAccountTile";

const QRImage = ({ data }) => (
  <img src={`data:image/png;base64,${data}`} style={{ width: "300px" }} alt="" />
);

const CompanyAccounts = ({ spoqProducerData, spoqId, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [twoFABoolean, setTwoFABoolean] = useState(false);
  const [image, setImage] = useState("");
  const [mailAvailable, setMailAvailable] = useState(true);
  const padding = "10px";
  const [hasApi, setHasApi] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  useEffect(() => {
    var infoText = document.getElementById("info_text");
    if (spoqProducerData.account_api_url === "") {
      //console.log("account_api_url is empty");
      infoText.innerHTML =
        "account_api_url is empty, no registration of representatives possible";
      infoText.style.color = "red";
      setHasApi(false);
    } else {
      setHasApi(true);
    }
  }, [spoqProducerData]);

  useEffect(() => {
    if (spoqUserData.role === 1) {
      fetchAllCompanyUsers(spoqProducerData.account_api_url, setCompanyUsers);
    }
  }, [spoqUserData]);

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]|;:'",.<>/?]).{8,}$/;

  function check() {
    var input = document.getElementById("password_confirm");
    var errorLabel = document.getElementById("error_label");
    var password = document.getElementById("password").value;

    var registerButton = document.getElementById("register_button");

    var password_valid = passwordRegex.test(password);
    var passwords_matching = input.value === password;
    if (password_valid) {
      if (passwords_matching) {
        // input is valid -- reset the error message
        input.setCustomValidity("");
        errorLabel.innerHTML = "";
        registerButton.disabled = false;
        registerButton.className =
          "w3-button w3-theme-d5 w3-margin-bottom w3-hover-white";
      } else {
        input.setCustomValidity("Password Must be Matching.");
        errorLabel.innerHTML = t("register.password_not_match");
        errorLabel.style.color = "red";

        registerButton.disabled = true;
        registerButton.className =
          "w3-button w3-theme-d5 w3-margin-bottom w3-disabled";
      }
    } else {
      input.setCustomValidity(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character."
      );
      registerButton.className =
        "w3-button w3-theme-d5 w3-margin-bottom w3-disabled";
      registerButton.disabled = true;
      errorLabel.innerHTML = t("register.password_unsafe");
      errorLabel.style.color = "red";
    }
  }

  function checkMailValid() {
    const contactEmail = document.getElementById("input_email").value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(contactEmail);
    var errorLabel = document.getElementById("error_label_mail");
    var registerButton = document.getElementById("register_button");
    if (isValidEmail) {
      errorLabel.innerHTML = "";
      registerButton.disabled = false;
    } else {
      errorLabel.innerHTML = "Email is not valid";
      errorLabel.style.color = "red";
      registerButton.disabled = true;
    }
  }

  // submit button
  async function handleRegisterButtonClick() {
    // get all the input values
    var name = document.getElementById("input_name").value;
    var email = document.getElementById("input_email").value;
    var password = document.getElementById("password").value;

    // get int from selected role
    var role = selectedRole[0].value;

    if (spoqProducerData.account_api_url === "") {
      alert("account_api_url is empty, no registration possible");
    }
    // send the data to the api
    postRegisterUser(spoqProducerData.spoq_producer_id, spoqProducerData.account_api_url, name, email, password, role, setImage, setTwoFABoolean, setMailAvailable);
    await new Promise(resolve => setTimeout(resolve, 300));
    fetchAllCompanyUsers(spoqProducerData.account_api_url, setCompanyUsers);
  }

  return (
    <div style={isMobile ? { fontSize: "11px" } : {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />

      {hasApi && spoqUserData ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {spoqUserData.role === 1 && companyUsers && <div style={{ flex: '1', display: "flex", flexDirection: "column", alignContent: "flex-start", alignItems: "center", justifyContent: "flex-start" }}>
          <div className="w3-container w3-center w3-margin">
              {isMobile ? <h3>{t("account.administer.header")}</h3> : <h1>{t("account.administer.header")}</h1>}
              <div
                className="w3-container w3-center w3-margin"
                style={{ maxWidth: "400px", display: "inline-block" }}
              >
                <span style={{ display: "inline-block" }}>
                  {t("account.administer.description")}
                </span>
              </div>
            </div>
            {companyUsers.map((userData) =>
              <CompanyAccountTile
                key={userData.email}
                spoqProducerData={spoqProducerData}
                userData={userData}
                onAccountChanged={async()=> {
                  await new Promise(resolve => setTimeout(resolve, 300));
                  fetchAllCompanyUsers(spoqProducerData.account_api_url, setCompanyUsers);
                }}
              />)}
          </div>}
          <div style={{ flex: '1' }}>
            <div className="w3-container w3-center w3-margin">
              {isMobile ? <h3>{t("account.register_account")}</h3> : <h1>{t("account.register_account")}</h1>}
              <div
                className="w3-container w3-center w3-margin"
                style={{ maxWidth: "400px", display: "inline-block" }}
              >
                <span style={{ display: "inline-block" }}>
                  {t("account.register.description")}
                </span>
              </div>
            </div>
            {twoFABoolean ? (
              <div className="w3-container w3-center w3-margin">
                <div
                  style={{
                    height: "500px",
                    paddingTop: padding,
                    paddingBottom: padding,
                  }}
                  className="w3-center w3-margin"
                >
                  <div className="w3-center w3-padding">
                    <div className="w3-margin-bottom">
                      <label htmlFor="token">{t("register.twofactor.one")}</label>
                    </div>
                  </div>
                  <div>
                    <QRImage data={image} />
                  </div>
                  <div className="w3-center">
                    <button
                      type="button"
                      onClick={() => setTwoFABoolean(false)}
                      className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                    >
                      {t("login.twofa.submit")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w3-container w3-center w3-margin">
                <form>
                  <div id="iubhfz" className="w3-center w3-padding-16">
                    <label id="reg_username">
                      <b>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="w3-margin-right"
                        />
                        {t("account.register.name")}
                      </b>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      id="input_name"
                      required
                      className="w3-input w3-border w3-center"
                      style={{ maxWidth: "400px", display: " inline-block" }}
                    />
                  </div>
                  <div id="i8stg2" className="w3-center w3-padding-16">
                    <label id="reg_mail">
                      <b>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="w3-margin-right"
                        />
                        {t("account.register.email")}
                      </b>
                    </label>
                  </div>
                  <div>
                    <input
                      type="email"
                      id="input_email"
                      required
                      name="reg_email"
                      placeholder="E-Mail"
                      onChange={(e) => {
                        checkMailValid();
                        setMailAvailable(true)
                      }}
                      className="w3-input w3-border w3-center"
                      style={{ maxWidth: "400px", display: " inline-block" }}
                    />
                    {!mailAvailable && <div className="w3-center">
                      <span
                        id="error_label_mail"
                        style={{ maxWidth: "400px", display: " inline-block", color: "red" }}
                      >{t("account.register.email_hint")}</span>
                    </div>}
                  </div>

                  <div className="w3-center">
                    <span
                      id="error_label_mail"
                      style={{ maxWidth: "400px", display: " inline-block" }}
                    ></span>
                  </div>
                  <div id="roleselect">
                    <div id="izkukv" className="w3-center w3-padding-16">
                      <label id="reg_role">
                        <b>
                          <FontAwesomeIcon
                            icon={faIndustry}
                            className="w3-margin-right"
                          />
                          {t("account.register.role")}
                        </b>
                      </label>
                    </div>
                    <Select
                      className="w3-input w3-border w3-margin"
                      id="input_role"
                      options={[
                        { value: 1, label: t("account.register_representative.owner"), },
                        { value: 2, label: t("account.register_representative.product_owner"), },
                        { value: 3, label: t("account.register_representative.manufacturer_owner"), },
                        { value: 4, label: t("account.register_representative.product_member"), }
                      ]}
                      values={selectedRole}
                      labelField="label"
                      valueField="value"
                      style={{ maxWidth: "400px", display: " inline-block", backgroundColor: "#ffffff" }}
                      onChange={(value) => {
                        //console.log(value)
                        setSelectedRole(value)
                      }}>
                    </Select>

                  </div>
                  {/* enter password twice */}
                  <div className="w3-center w3-padding-16">
                    <span style={{ maxWidth: "400px", display: " inline-block" }}>
                      {t("register.password_info")}
                    </span>
                  </div>
                  <div className="w3-center w3-padding-16">
                    <div className="w3-center w3-padding-16">
                      <label htmlFor="password">
                        <b>
                          <FontAwesomeIcon
                            icon={faLock}
                            className="w3-margin-right"
                          />
                          {t("register.password")}
                        </b>
                      </label>
                    </div>
                    <input
                      style={{ maxWidth: "400px", display: " inline-block" }}
                      className="w3-input w3-border w3-center"
                      name="password"
                      required="required"
                      type="password"
                      id="password"
                      onChange={() => check()}
                    />
                  </div>

                  <div>
                    <div className="w3-center w3-padding-16">
                      <label htmlFor="password_confirm">
                        {t("register.confirm_password")}
                      </label>
                    </div>
                    <input
                      style={{ maxWidth: "400px", display: " inline-block" }}
                      className="w3-input w3-border w3-center"
                      name="password_confirm"
                      required="required"
                      type="password"
                      id="password_confirm"
                      onChange={() => check()}
                    />
                  </div>

                  <div className="w3-center">
                    <span
                      id="error_label"
                      style={{ maxWidth: "400px", display: " inline-block" }}
                    ></span>
                  </div>
                </form>

                <div className="w3-center w3-padding-16">
                  <button
                    type="button"
                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding w3-disabled"
                    id="register_button"
                    onClick={() => handleRegisterButtonClick()}
                    disabled={!hasApi}
                  >
                    {t("register.submit")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w3-center w3-margin w3-padding-64">
          <p id="info_text"></p>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default CompanyAccounts;
