// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/Products.css"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { CustomImage } from "../../Components/CustomImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProductTable({ productData, warnings, sale_regions, isMobile }) {
  const [expanded, setExpanded] = useState(false);
  function formatWarnings() {
    var warningString = "";
    for (var i = 0; i < warnings.length; i++) {
      // console.log("product id: " + productWarnings[i].product_id);
      if (warnings[i].product_id === productData.product_id) {
        warningString += warnings[i].warning + "\n";
      }
    }
    return warningString;
  }
  function formatSaleRegions() {
    if (typeof sale_regions === 'undefined') {
      //console.log(sale_regions)
      //console.log("Found invalid Data for Sale Regions to Display")
      return ""
    }
    var saleRegionString = "";
    for (var i = 0; i < sale_regions.length; i++) {
      // console.log("product id: " + productWarnings[i].product_id);
      if (sale_regions[i].product_id === productData.product_id) {
        saleRegionString += sale_regions[i].sale_region + "\n";
      }
    }
    return saleRegionString;
  }

  const { t } = useTranslation();
  return (
    <div className={isMobile ?
      "w3-container w3-padding-16 w3-center w3-margin-left w3-margin-right" :
      "w3-container w3-padding-64 w3-center w3-margin-left w3-margin-right"}>
      {!isMobile && <div>
        <h2>{t("account.products.product_information")}</h2>
        <table className="product-table w3-table w3-striped w3-border w3-bordered ">
          <tbody>
            <tr>
              <td>{t("account.add_charge.product_id")}</td>
              <td id="info_product_id">
                {productData.product_id}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_name")}</td>
              <td id="info_product_name">
                {productData.product_name}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_type")}</td>
              <td id="info_product_type">
                {productData.product_type}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_number")}</td>
              <td id="info_product_number">
                {productData.product_number}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_description")}</td>
              <td id="info_description">
                {productData.description}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.info_link")}</td>
              <td id="info_link">
                {productData.link_info}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.info_link_recycle")}</td>
              <td id="info_link_recycle">
                {productData.link_info_recycle}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.warnings")}</td>
              <td id="info_warnings">
                {formatWarnings()}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.sale_region")}</td>
              <td id="info_sale_region">
                {formatSaleRegions()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>}
      {isMobile &&
        <div onClick={() => {
          setExpanded(!expanded)
        }}
          style={{ display: "flex", flexDirection: "column", fontSize: "11px" }}>
          <div className="mobile-product-tile">
            <center style={{ flex: "6", marginLeft: "5px" }}>{productData.product_name}</center>
            <center style={{ padding: "10px", flex: "2" }}><CustomImage
              data={productData.product_image}
              maxWidth={"15vw"}
              maxHeight={"10vh"}
            /></center>
            <center style={{ flex: "1" }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </center>
          </div>
          {expanded && <div className="mobile-product-tile-expanded">
            <table className="w3-table product-table-expanded">
              <tbody className="w3-border w3-bordered w3-table w3-striped">
                <tr>
                  <td>Produkt ID</td>
                  <td id="info_product_id">
                    {productData.product_id}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.product_type")}</td>
                  <td id="info_product_type">
                    {productData.product_type}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.product_number")}</td>
                  <td id="info_product_number">
                    {productData.product_number}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.product_description")}</td>
                  <td id="info_description">
                    {productData.description}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.info_link")}</td>
                  <td id="info_link">
                    {productData.link_info}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.info_link_recycle")}</td>
                  <td id="info_link_recycle">
                    {productData.link_info_recycle}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.warnings")}</td>
                  <td id="info_warnings">
                    {formatWarnings()}
                  </td>
                </tr>
                <tr>
                  <td>{t("account.products.sale_region")}</td>
                  <td id="info_sale_region">
                    {formatSaleRegions()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>}
        </div>



      }
    </div>
  );
}

export default ProductTable;
