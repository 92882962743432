// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState, useRef } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next";
import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import JSZip from "jszip";
import QRCode from "qrcode";
import FileSaver from "file-saver";
import { postUpdateSpoqIdentity, postUpdateCharge, fetchSpoqProductIdsForCharge } from "../../api_functions/ChargeInfo";
import SpoqIdentityTile from "./SpoqIdentityTile";
function ChargeDetail({
    show,
    product_api_url,
    spoq_prooducer_id,
    onClose,
    chargeInfo,
    productInfo,
    onChargeInfoChanged,
    isManufacturerView,
    isProducerView,
    isMobile }) {
    var zip = new JSZip();
    const { t } = useTranslation();
    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const [progress, setProgress] = useState(0);
    const fileInputRef = useRef(null);
    const [uploadError, setUploadError] = useState("");
    const [spoqProductIds, setSpoqProductIds] = useState(null);
    const [unfinishedSpoqProductIds, setUnfinishedSpoqProductIds] = useState(null);
    const [showSingleProductIds, SetShowSingleProductIds] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(10);
    const [showFinishedProductIds, setShowFinishedProductIds] = useState(true);
    const [showUnfinishedProductIds, setShowUnfisnishedProductIds] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [filteredProductIds, setFiltererdProductIds] = useState(spoqProductIds);
    useEffect(() => {
        if (show) {
            loadIdentitys();
        }
    }, [show])

    useEffect(() => {
        if (spoqProductIds && unfinishedSpoqProductIds) {
            filterIds(searchText, showFinishedProductIds, showUnfinishedProductIds);
            setDownloadDisabled(false);
        }
    }, [spoqProductIds, unfinishedSpoqProductIds])

    function loadIdentitys() {
        fetchSpoqProductIdsForCharge(product_api_url, chargeInfo.product_id, chargeInfo.charge_id, setSpoqProductIds, setUnfinishedSpoqProductIds);
    }

    function intToHexString(spoq_product_id) {
        let hexString = spoq_product_id.toString(16);
        while (hexString.length < 15) {
            hexString = '0' + hexString;
        }
        return hexString
    }

    function paginate(array, page_number, items_per_page) {
        const start_index = (page_number - 1) * items_per_page;
        const end_index = page_number * items_per_page;
        return spoqProductIds && unfinishedSpoqProductIds && filteredProductIds ? array.slice(start_index, end_index) : [];
    }
    const totalPages = Math.ceil(filteredProductIds?.length / productsPerPage);
    const paginatedProductIds = paginate(filteredProductIds, currentPage, productsPerPage);

    function handlePagination(pageNumber) {
        setCurrentPage(pageNumber);
    }

    function filterIds(filterText, show_finished, show_unfinished) {
        console.log("Filtering Ids with:", filterText, show_finished, show_unfinished)
        var filteredProductIds = [];
        if (filterText === "") {
            filteredProductIds = spoqProductIds
        } else {
            filteredProductIds = spoqProductIds.filter((spoqProductId) => {
                return spoqProductId.includes(filterText)
            })
        }
        if (show_finished && show_unfinished) {
            setFiltererdProductIds(filteredProductIds);
        } else {
            if (show_unfinished) {
                filteredProductIds = spoqProductIds.filter((spoqProductId) => {
                    return unfinishedSpoqProductIds.includes(spoqProductId)
                })
                setFiltererdProductIds(filteredProductIds);
            }
            if (show_finished) {
                filteredProductIds = spoqProductIds.filter((spoqProductId) => {
                    return !unfinishedSpoqProductIds.includes(spoqProductId)
                })
                setFiltererdProductIds(filteredProductIds);
            }
            if (!show_finished && !show_unfinished) {
                setFiltererdProductIds([]);
            }
        }
    }

    async function handleDownloadQRClicked(spoq_producer_id) {
        const baseQRString = "https://spoq.org/" + spoq_producer_id;
        const zip = new JSZip();

        setDownloadDisabled(true); // Disable the Download button
        setProgress(0); // Reset progress to 0%
        var progress_counter = 0;
        //console.log(spoqProductIds)
        for (let spoqProductId of spoqProductIds) {
            progress_counter = progress_counter + 1;
            //console.log(spoqProductId)
            const randomHexNumber = intToHexString(spoqProductId);
            //console.log("Generating for:", spoqProductId);
            const qrString = baseQRString + randomHexNumber;
            const fileName = `${spoq_producer_id + randomHexNumber}.png`;
            // Generate QR code as a data URL
            const qrDataURL = await QRCode.toDataURL(qrString, {
                errorCorrectionLevel: "H",
                margin: 2,
                width: 400,
            });

            // Convert the data URL to a Blob
            const blob = await fetch(qrDataURL).then((response) => response.blob());

            // Add the Blob to the zip folder
            zip.file(fileName, blob, { binary: true });

            // Update progress
            const currentProgress = ((progress_counter + 1) / spoqProductIds.length) * 100;
            setProgress(currentProgress);
        }

        // Generate the zip file
        zip.generateAsync({ type: "blob" }).then((blob) => {
            // Save the zip file
            FileSaver.saveAs(blob, "qr_codes.zip");

            // Enable the Download button again and reset progress
            setDownloadDisabled(false);
            setProgress(0);
        });
    }
    async function handleDownloadCSVClicked(spoq_producer_id) {
        const baseQRString = "https://spoq.org/" + spoq_producer_id;
        setDownloadDisabled(true); // Disable the Download button
        setProgress(0); // Reset progress to 0%
        var progress_counter = 0;
        //console.log(spoqProductIds)
        var id_list = [];
        for (let spoqProductId of spoqProductIds) {
            progress_counter = progress_counter + 1;
            //console.log(spoqProductId)
            const randomHexNumber = intToHexString(spoqProductId);
            //console.log("Generating for:", spoqProductId);
            const qrString = baseQRString + randomHexNumber;
            id_list.push(qrString)
            // Update progress
            const currentProgress = ((progress_counter + 1) / spoqProductIds.length) * 100;
            setProgress(currentProgress);
        }
        // Convert id_list to CSV format
        const csvData = id_list.join('\n');
        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        // Save the Blob as a CSV file
        FileSaver.saveAs(blob, 'id_list.csv');
        setDownloadDisabled(false);
        setProgress(0);
    }
    function convertDateStringsToDateObject(dateString) {
        //console.log("Datestring:", dateString);
        if (typeof dateString === 'undefined') {
            return new Date(0);
        }
        const dateParts = dateString.split("-");
        const dateObject = new Date(
            parseInt(dateParts[0]),
            parseInt(dateParts[1]) - 1,
            parseInt(dateParts[2]));
        return dateObject;
    }
    async function handleFilesSelected(event) {
        //console.log("HandleFilesSelected:", event)
        const zipFile = event.target.files[0];
        //console.log(zipFile)
        if (!zipFile) {
            setUploadError("Selected File for Upload is not a valid zip File");
            return;
        }
        const zipData = await zip.loadAsync(zipFile);
        var counter = 0;
        var bad_data = false
        zipData.forEach(function (relativePath, zipEntry) {
            const filenameWithoutExtension = zipEntry.name.replace(".png", "");
            const spoqProductId = filenameWithoutExtension.slice(-15);
            const spoqProductIdInFilename = spoqProductIds.includes(spoqProductId);
            if (!spoqProductIdInFilename) {
                bad_data = true;
                setUploadError("Selected file does not contain the spoqProductId in its name");
                return; // Return and stop processing further if it's not valid
            }
            counter = counter + 1;
        })
        if (bad_data) {
            return;
        }
        // Making API Calls to add SPOQ Identity
        zipData.forEach(async function (relativePath, zipEntry) {
            //console.log(zipEntry)
            // Read data from the zip entry
            const imageData = await zipEntry.async("base64"); // Change "uint8array" to the appropriate data type you need
            // Now you can work with the data
            //console.log(imageData);
            const spoqProductId = zipEntry.name.replace(".png", "").slice(-15);
            //console.log("spoqProductId read from Image Name:", spoqProductId)
            //console.log("spoqProductId read from Image Name as int:", parseInt(spoqProductId, 16))
            var spoqIdentityData = {
                "product_id": chargeInfo.product_id,
                "charge_id": chargeInfo.charge_id,
                "date_of_manufacture": Date.now(),
                "contract_manufacturer": chargeInfo.contract_manufacturer_id,
                "qr_image": imageData,
                "verification_method": 1,
                "spoq_product_id": NaN
            }
            postUpdateSpoqIdentity(product_api_url, spoqIdentityData, spoqProductId);
            loadIdentitys();
            onChargeInfoChanged();
        })
        setUploadError("")
    }
    if (!show) return null;
    return (
        <div className="edit-product-dialog" style={isMobile ? { fontSize: "11px" } : {}}>
            <div className="edit-product-dialog-content">
                <div className="finish-charge-container">
                    {isMobile ? <h4>{t("account.add_charge.edit_charge_info")}</h4> : <h2>{t("account.add_charge.edit_charge_info")}</h2>}
                    <div className={isMobile ? "" : "standard-row"}>
                        <table className="manufacturer-table w3-table w3-striped w3-border w3-bordered">
                            <tbody>
                                <tr>
                                    <td>{t("account.charges.product_id")}</td>
                                    <td id="info_product_id">
                                        <center>{productInfo.product_id}</center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("account.products.product_name")}</td>
                                    <td id="info_product_name">
                                        <center>{productInfo.product_name}</center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("account.add_charge.charge_number")}</td>
                                    <td>
                                        <center>
                                            {chargeInfo.charge_number}
                                        </center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("account.add_charge.charge_size")}</td>
                                    <td id="Charge Size">
                                        <center>
                                            {chargeInfo.charge_size}
                                        </center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("account.add_charge.sale_region")}</td>
                                    <td id="Sale Region">
                                        <center>
                                            {chargeInfo.sale_region}
                                        </center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("account.add_charge.production_period")}</td>
                                    <td>
                                        <center>
                                            {chargeInfo.production_start + "     -     " + chargeInfo.production_end}
                                        </center>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={isMobile ? "standard-row" : ""}>
                            <center className={isMobile ? "charge-progress-indicator-mobile" : "charge-progress-indicator"}>
                                <CircularProgressbar
                                    value={
                                        (chargeInfo.completion) * 100
                                    }
                                    text={`${(chargeInfo.completion).toFixed(2) * 100}%`}
                                />
                            </center>
                            {isManufacturerView && <div>
                                {downloadDisabled && <progress value={progress} max="100"></progress>}
                                <button
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        handleDownloadQRClicked(spoq_prooducer_id);
                                    }
                                    }
                                    disabled={downloadDisabled}>
                                    <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
                                    <i className={"fa-solid fa-download"} style={{ marginRight: "10px" }}></i>
                                    {t("account.charges.download_qr")}
                                </button>
                                <button
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        handleDownloadCSVClicked(spoq_prooducer_id);
                                    }
                                    }
                                    disabled={downloadDisabled}>
                                    <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
                                    <i className={"fa-solid fa-download"} style={{ marginRight: "10px" }}></i>
                                    {t("account.charges.download_csv")}
                                </button>

                                <input
                                    id="fileInput"  // Unique id for the file input
                                    type="file"
                                    ref={fileInputRef}
                                    accept=".zip"
                                    onChange={(event) => {
                                        handleFilesSelected(event);
                                    }
                                    }
                                    style={{ display: 'none' }}  // Hide the default file input
                                />
                                <label
                                    htmlFor="fileInput"
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                >
                                    <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
                                    <i className={"fa-solid fa-upload"} style={{ marginRight: "10px" }}></i>
                                    {t("account.charges.upload_qr_zip")}
                                </label>
                                {uploadError !== "" && <div>{uploadError}</div>}
                            </div>}
                            {isProducerView && <div>
                                <button
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        const oldDate = convertDateStringsToDateObject(chargeInfo.production_end)
                                        var newEndDate = new Date();
                                        const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
                                        newEndDate.setTime(oldDate.getTime() + 1 * 24 * 60 * 60 * 1000 + weekInMilliseconds);
                                        newEndDate = newEndDate.toISOString().split('T')[0];
                                        const editedChargeInfo = {
                                            charge_number: chargeInfo.charge_number,
                                            product_id: chargeInfo.product_id,
                                            charge_size: chargeInfo.charge_size,
                                            contract_manufacturer_id: chargeInfo.contract_manufacturer_id,
                                            sale_region: chargeInfo.sale_region,
                                            production_region: chargeInfo.production_region,
                                            production_start: chargeInfo.production_start,
                                            production_end: newEndDate,
                                            begin_date: chargeInfo.begin_date,
                                            end_date: chargeInfo.end_date
                                        }
                                        postUpdateCharge(product_api_url, editedChargeInfo, chargeInfo.charge_id)
                                        onChargeInfoChanged();
                                    }
                                    }
                                    disabled={downloadDisabled}>
                                    <i className={"fa fa-calendar"} style={{ marginRight: "10px" }}></i>
                                    {t("account.charges.postpone_production_end_week")}
                                </button>
                                <button
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        const oldDate = convertDateStringsToDateObject(chargeInfo.production_end)
                                        var newEndDate = new Date();
                                        const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
                                        newEndDate.setTime(oldDate.getTime() + 1 * 24 * 60 * 60 * 1000 + monthInMilliseconds);
                                        newEndDate = newEndDate.toISOString().split('T')[0];
                                        const editedChargeInfo = {
                                            charge_number: chargeInfo.charge_number,
                                            product_id: chargeInfo.product_id,
                                            charge_size: chargeInfo.charge_size,
                                            contract_manufacturer_id: chargeInfo.contract_manufacturer_id,
                                            sale_region: chargeInfo.sale_region,
                                            production_region: chargeInfo.production_region,
                                            production_start: chargeInfo.production_start,
                                            production_end: newEndDate,
                                            begin_date: chargeInfo.begin_date,
                                            end_date: chargeInfo.end_date
                                        }
                                        postUpdateCharge(product_api_url, editedChargeInfo, chargeInfo.charge_id)
                                        onChargeInfoChanged();
                                    }
                                    }
                                    disabled={downloadDisabled}>
                                    <i className={"fa fa-calendar"} style={{ marginRight: "10px" }}></i>
                                    {t("account.charges.postpone_production_end_month")}
                                </button>
                            </div>}

                        </div>
                    </div>

                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            SetShowSingleProductIds(!showSingleProductIds)
                        }}
                        disabled={!spoqProductIds || !unfinishedSpoqProductIds}
                    >
                        {showSingleProductIds ? t("account.charges.hide_single_ids") : t("account.charges.show_single_ids")}
                    </button>

                    {unfinishedSpoqProductIds && spoqProductIds && showSingleProductIds && <div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <input
                                type="text"
                                value={searchText}
                                className="w3-input w3-border w3-center w3-margin-top"
                                placeholder="Search for ID"
                                style={{ maxWidth: "400px", display: "inline-block" }}
                                onChange={(e) => {

                                    setSearchText(e.target.value);
                                    filterIds(e.target.value, showFinishedProductIds, showUnfinishedProductIds);
                                }}
                            />
                            <i className={"fas fa-search"} style={{ marginLeft: '10px' }}></i>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: '20px' }}>
                                <label style={{}}>
                                    {t("account.charges.show_finished_ids")}
                                </label>
                                <input
                                    type="checkbox"
                                    checked={showFinishedProductIds}
                                    onChange={(e) => {
                                        filterIds(searchText, !showFinishedProductIds, showUnfinishedProductIds);
                                        setShowFinishedProductIds(!showFinishedProductIds);
                                    }}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: '20px' }}>
                                <label>
                                    {t("account.charges.show_unfinished_ids")}
                                </label>
                                <input
                                    type="checkbox"
                                    checked={showUnfinishedProductIds}
                                    onChange={(e) => {
                                        filterIds(searchText, showFinishedProductIds, !showUnfinishedProductIds);
                                        setShowUnfisnishedProductIds(!showUnfinishedProductIds);
                                    }}
                                />
                            </div>
                        </div>
                        {spoqProductIds && spoqProductIds.length > productsPerPage && (
                            <div>
                                <button onClick={() => handlePagination(1)} style={currentPage === 1 ? { color: "blue" } : {}}>1</button>
                                {/* Display two pages before and after the current page */}
                                {[currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].filter(page => page > 0 && page <= totalPages && page !== 1 && page != totalPages).map(page => (
                                    <button key={page} onClick={() => handlePagination(page)} style={currentPage === page ? { color: "blue" } : {}}>{page}</button>
                                ))}
                                <button onClick={() => handlePagination(totalPages)} style={currentPage === totalPages ? { color: "blue" } : {}}>{totalPages}</button>
                            </div>
                        )}

                        {paginatedProductIds && paginatedProductIds.map((spoqProductId) => (
                            <SpoqIdentityTile
                                key={spoqProductId}
                                product_api_url={product_api_url}
                                chargeInfo={chargeInfo}
                                productInfo={productInfo}
                                onSpoqIdentityUpdated={() => {
                                    loadIdentitys();
                                    onChargeInfoChanged();
                                }}
                                isManufacturerView={isManufacturerView}
                                spoqProductId={spoqProductId}
                                isFinished={!unfinishedSpoqProductIds.includes(spoqProductId)}
                            />
                        ))}
                    </div>}
                    <div className="fixed-buttons-dialog">
                        <button
                            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            {t("button.close")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChargeDetail;
