// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import { fetchAssociatedContractManufacturers, fetchAssignedManufacturers } from "../../api_functions/ManufacturerInfo";
import { postUpdateProduct } from "../../api_functions/ProductInfo";
import CompanyTile from "../../Components/CompanyTile";
import { CustomImage, imageToBase64 } from "../../Components/CustomImage";
function EditProduct({
  show,
  onClose,
  onEdited,
  productInfo,
  productWarnings,
  productSaleRegions,
  spoqProducerData,
  isMobile
}) {

  const { t } = useTranslation();
  const [editedProductInfo, setEditedProductInfo] = useState(productInfo);
  const [editedProductWarnings, setEditedProductWarnings] = useState(productWarnings);
  const [editedSaleRegions, setEditedSaleRegions] = useState(productSaleRegions);
  const [associated_contract_manufacturers, setAssociatedManufacturers] = useState([]);
  const [assignedManufacturers, setAssignedManufacturers] = useState([]);

  useEffect(() => {
    //console.log("EditedProductInfoActualised with :")
    //console.log(productInfo)
    setEditedProductInfo(productInfo);
    if (productInfo.product_image) {
      handleInputChangeProduct(productInfo.product_image, "product_image");
    }
  }, [productInfo]);

  useEffect(() => {
    setEditedProductWarnings(productWarnings);
  }, [productWarnings]);

  useEffect(() => {
    //console.log("Sale Region in Edit:", productSaleRegions)
    setEditedSaleRegions(productSaleRegions);
  }, [productSaleRegions]);

  useEffect(() => {
    fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
  }, []);

  useEffect(() => {
    fetchAssignedManufacturers(spoqProducerData.product_api_url, productInfo.product_id, (product_id, updatedAssignedManufacturers)=> {setAssignedManufacturers(updatedAssignedManufacturers);});
  }, [productInfo]);

  function handleInputChangeProduct(value, element) {
    setEditedProductInfo((prevSpoqProductInfo) => ({
      ...prevSpoqProductInfo,
      [element]: value,
    }));
  }

  function handleAddWarning() {
    const newEntry = {
      product_id: editedProductInfo.product_id,
      charge_id: "not set",
      warning: "",
    };
    setEditedProductWarnings(
      [...editedProductWarnings, newEntry]
    )

  }

  function handleRemoveWarning(index) {
    const updatedList = editedProductWarnings.filter((item, i) => i !== index);
    setEditedProductWarnings(updatedList);
  }

  function handleWarningInputChange(value, index) {
    const updatedWarnings = editedProductWarnings.map((element, i) => {
      if (i === index) {
        return { ...element, warning: value }; // Update only the warning property
      }
      return element;
    });

    setEditedProductWarnings(updatedWarnings);
  }

  async function handleRemoveAssignedManufacturer(manufacturer) {
    const updatedAssignedManufacturers = assignedManufacturers.filter(
      (assignedManufacturer) =>
        assignedManufacturer.spoq_producer_id !== manufacturer.spoq_producer_id
    );
    setAssignedManufacturers(updatedAssignedManufacturers);
  }

  async function handleEditConfirmed() {
    // get list of strings from editedProductWarnings
    var warnings = editedProductWarnings.map((item) => item.warning);

    warnings = warnings.filter(n => n);
    //console.log("Warnings in Update Product:",warnings)
    //console.log(editedSaleRegions);
    var sale_region_list = editedSaleRegions.map((sale_region) => sale_region.sale_region);
    postUpdateProduct(spoqProducerData.product_api_url, editedProductInfo, warnings, assignedManufacturers, sale_region_list)
    onEdited();
  }
  if (!show) return null;
  return (
    <div className="edit-product-dialog" style={isMobile ? { fontSize: "11px" } : {}}>
      <div className="edit-product-dialog-content">
        {isMobile ? <h4>{t("edit_product.header_product")}</h4> : <h2>{t("edit_product.header_product")}</h2>}
        <table className="w3-table w3-striped w3-border w3-bordered">
          <tbody>
            <tr>
              <td>{t("account.add_charge.product_id")}</td>
              <td id="info_product_id">
                <center>{editedProductInfo.product_id}</center>
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_name")}</td>
              <td id="info_product_name">
                <center><input
                  value={editedProductInfo.product_name}
                  disabled={!spoqProducerData.producer_boolean}
                  type="text"
                  id="product_name_input"
                  required
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  onChange={(e) => {
                    handleInputChangeProduct(e.target.value, "product_name");
                  }}
                /></center>
                {!spoqProducerData.producer_boolean && (
                  <div>{t("edit_product.info_producers")}</div>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_type")}</td>
              <td id="info_product_type">
                <center><input
                  value={editedProductInfo.product_type}
                  disabled={!spoqProducerData.producer_boolean}
                  type="text"
                  id="product_type_input"
                  required
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  onChange={(e) => {
                    handleInputChangeProduct(e.target.value, "product_type");
                  }}
                /></center>
                {!spoqProducerData.producer_boolean && (
                  <div>{t("edit_product.info_producers")}</div>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_description")}</td>
              <td id="info_description">
                <center><textarea
                  value={editedProductInfo.description}
                  disabled={!spoqProducerData.producer_boolean}
                  id="description_input"
                  required
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{
                    maxWidth: "80vw",
                    height: "30vh",
                    display: " inline-block",
                  }}
                  onChange={(e) => {
                    handleInputChangeProduct(e.target.value, "description");
                  }}
                /></center>
                {!spoqProducerData.producer_boolean && (
                  <div>{t("edit_product.info_producers")}</div>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.info_link")}</td>
              <td id="info_link">
                <center><input
                  value={editedProductInfo.link_info}
                  disabled={!spoqProducerData.producer_boolean}
                  type="text"
                  id="link_info_input"
                  required
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  onChange={(e) => {
                    handleInputChangeProduct(e.target.value, "link_info");
                  }}
                /></center>
                {!spoqProducerData.producer_boolean && (
                  <div>{t("edit_product.info_producers")}</div>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.info_link_recycle")}</td>
              <td id="info_link_recycle">
                <center><input
                  value={editedProductInfo.link_info_recycle}
                  disabled={!spoqProducerData.producer_boolean}
                  type="text"
                  id="link_info_recycle_input"
                  required
                  className="w3-input w3-border w3-center w3-margin-top"
                  style={{ maxWidth: "400px", display: " inline-block" }}
                  onChange={(e) => {
                    handleInputChangeProduct(e.target.value, "link_info_recycle");
                  }}
                /></center>
                {!spoqProducerData.producer_boolean && (
                  <div>{t("edit_product.info_producers")}</div>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("account.products.product_image")}</td>
              <td id="info_product_image">
                <center>
                  <div>
                    {editedProductInfo.product_image && (
                      <div className="w3-padding-16">
                        <CustomImage data={editedProductInfo.product_image}
                          maxHeight={"25vh"}
                          maxWidth={"25vw"}
                        />
                        <br />
                        {spoqProducerData.producer_boolean && (
                          <button
                            onClick={() => {
                              handleInputChangeProduct(null, "product_image");
                              var input =
                                document.getElementById("product_image_file");
                              input.value = null;
                            }}
                            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                          >
                            {t("button.remove")}
                          </button>
                        )}
                      </div>
                    )}
                    {spoqProducerData.producer_boolean && (
                      <input
                        type="file"
                        name="product_image_file"
                        id="product_image_file"
                        className="w3-padding-16 w3-center"
                        onChange={async (e) => {
                          var base64Image = await imageToBase64(e.target.files[0])
                          //console.log("Base64Image1:", base64Image)
                          handleInputChangeProduct(
                            base64Image,
                            "product_image"
                          );
                        }}
                      />
                    )}
                    {!spoqProducerData.producer_boolean && (
                      <div>{t("edit_product.info_producers")}</div>
                    )}
                  </div></center>
              </td>
            </tr>
            <tr>
              <td>
                {t("account.add_charge.contract_manufacturer")}
              </td>
              <td>
                <center>
                  <Select
                    options={associated_contract_manufacturers}
                    labelField="spoq_producer_name"
                    valueField="spoq_producer_id"
                    onChange={(values) => {
                      //console.log("HandleAssignManufacturers")
                      var newAssignedManufacurers = [];
                      for (let manufacturer of values) {
                        if (!assignedManufacturers.filter(
                          (assignedManufacturer) =>
                            assignedManufacturer.spoq_producer_id === manufacturer.spoq_producer_id
                        ).length > 0) {
                          newAssignedManufacurers.push(manufacturer);
                        }
                      }
                      setAssignedManufacturers([...assignedManufacturers, ...newAssignedManufacurers]);
                    }}
                    clearable={true}
                    multi={true}
                    style={{ maxWidth: "400px", display: " inline-block" }} />
                </center>

                {/* Display the List of already associated Manufacturers */}
                <div className="manufacturers-list">
                  {assignedManufacturers.map(manufacturer => (
                    <div key={manufacturer.spoq_producer_id} className="manufacturer-item">
                      <CompanyTile company_id={manufacturer.spoq_producer_id} isMobile={isMobile} />
                      <center>
                        <button
                          type="button"
                          className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                          onClick={() => handleRemoveAssignedManufacturer(manufacturer)}
                          style={{ maxWidth: "100px", display: " inline-block" }}>
                          {t("button.remove")}
                        </button>
                      </center>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>{t("account.products.warnings")}</td>
              <td id="info_warnings">
                {editedProductWarnings.map((element, index) => (
                  //console.log(element.warning),
                  (element.product_id === editedProductInfo.product_id) &&
                  (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <center>
                        <input
                          type="text"
                          value={element.warning}
                          className="w3-input w3-border w3-center"
                          style={{ maxWidth: "400px", display: " inline-block" }}
                          onChange={(e) =>
                            handleWarningInputChange(e.target.value, index)
                          }
                        />
                      </center>

                      <center>
                        <button
                          className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                          onClick={() => handleRemoveWarning(index)}
                        >
                          {t("button.remove")}
                        </button>
                      </center>

                    </div>
                  )
                ))}
                <center>
                  <button
                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                    onClick={handleAddWarning}
                  >
                    {t("button.add_warning")}
                  </button>
                </center>

              </td>
            </tr>
            <tr>
              <td>{t("account.products.sale_region")}</td>
              <td id="info_sale_region">
                {/* select from all regions via drowndown-select  */}
                <center>
                  <Select
                    id="saleRegionSelect"
                    name="saleRegionSelect"
                    options={productSaleRegions}
                    values={editedSaleRegions}
                    labelField="sale_region"
                    valueField="sale_region"
                    onChange={(value) => {
                      // set the selected regions
                      //console.log("onChangeSelectSaleRegions:")
                      console.log(value)
                      
                      setEditedSaleRegions(value);
                    }}
                    className="w3-input w3-border w3-center w3-margin-top"
                    style={{ maxWidth: "400px", display: " inline-block" }}
                    create={true}
                    multi={true}
                  />
                </center>

              </td>
            </tr>
          </tbody>
        </table>
        <div className="fixed-buttons-dialog">
          <button
            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
            onClick={() => {
              onClose();
            }}
          >
            {t("button.cancel")}
          </button>
          <button
            className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
            onClick={() => {
              handleEditConfirmed();
            }}
          >
            {t("button.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
