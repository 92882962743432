import { ColorRing} from "react-loader-spinner";
const LoadingIndicator = () => {
 
    return (
        <ColorRing
            height={70}
            width={70}
            colors={["#20295b", "#26316d", "#2d397f"]}
        />
 
    );
}
export default LoadingIndicator;