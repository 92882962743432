import { accountApi } from "../config";
import { fetchCompanyInfo } from "./AccountInfo";
async function fetchAssociatedContractManufacturers(product_api_url, setAssociatedContractManufacturers) {
    if (product_api_url) {
        try {
            var associated_ids;
            const id_list_response = await fetch(product_api_url + "/associated_contract_manufacturers/get_all", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
                },
            });
            if (id_list_response.status !== 200) {
                const response_data = await id_list_response.json();
                //console.log(response_data);
                throw new Error(response_data.detail ? response_data.detail : "Network response was not ok");
            } else {
                if (!id_list_response.headers.get("content-type").includes("application/json")) {
                    associated_ids = JSON.parse(sessionStorage.getItem("associated_maunfacturer_ids"));
                    console.log("Browser cache is saying no AssociatedManufacturers cached Data:", associated_ids)
                } else {
                    const data = await id_list_response.json();
                    associated_ids = data.contract_manufacturers.map((id_description_pair) => id_description_pair.contract_manufacturer_id);
                    //console.log("Associated IDs:", associated_ids)
                    try {
                    sessionStorage.setItem("associated_maunfacturer_ids", JSON.stringify(associated_ids));
                    }
                    catch(error){
                        console.log("Browser Cache out of Space")
                      }
                }
                var manufacturers = []
                //console.log("Fetching company Information for following ids:", associated_ids)
                for (var id of associated_ids) {
                    //console.log("Fetching with id:", id)
                    await fetchCompanyInfo(id, (manufacturer) => {
                        manufacturers = [...manufacturers, {
                            "spoq_producer_name": manufacturer.company_name,
                            "spoq_producer_id": manufacturer.spoq_producer_id,
                            "contact_person_name": manufacturer.contact_person_name,
                            "contact_email": manufacturer.contact_email,
                            "company_logo": manufacturer.company_logo
                        }]
                    })
                }
                //console.log(manufacturers)
                setAssociatedContractManufacturers(manufacturers);
            }
        }
        catch (error) {
            alert("Error: " + error);
            console.log(error);
        }
    }
}


async function postSelectedAssociatedManufacturers(product_api, selectedManufacturers) {
    //console.log(selectedManufacturers)
    for (let manufacturer of selectedManufacturers) {
        try {
            fetch(product_api + "/associated_contract_manufacturers/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
                },
                body: JSON.stringify(
                    {
                        "contract_manufacturer_id": manufacturer.spoq_producer_id,
                        "description": ""
                    }
                ),
            });
        }
        catch (error) {
            alert("Error: " + error);
            console.log(error);
        }
    }
}

async function fetchProductToManufacturer(product_api_url, product_id) {
    //console.log("fetchProductToManufacturer Entry")
    if (product_api_url === null || product_api_url === "" || product_id === null) {
        return
    }
    try {
        const response = await fetch(product_api_url + "/product/get_product_to_manufacturer?product_id=" + (typeof product_id === 'string' ? parseInt(product_id, 16) : product_id), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
            return null; // Handle the error case here
        } else if (!response.headers.get("content-type").includes("application/json")) {
            //console.log("Browser Cache is saying no");
            //console.log("Using cached data");
            const cachedData = getCachedProductToManufacturer();
            if (cachedData) {
                // Process and use cached data
                //console.log(cachedData);
                return cachedData;
            }
            return null; // Handle the cached data case here
        } else {
            const data = await response.json();
            //console.log(data);
            const product_to_manufacturer = data.product_to_manufacturer;
            try {
                sessionStorage.setItem("cachedProductToManufacturer", JSON.stringify(product_to_manufacturer));
            }
            catch(error){
                console.log("Browser Cache out of Space")
              }
            //console.log("fetchProductToManufacturer return")
            return product_to_manufacturer;
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
        return null; // Handle the error case here
    }
}

async function removeAssociatedManufacturer(product_api, manufacturer) {
    try {
        const response = await fetch(
            product_api +
            "/associated_contract_manufacturers/delete" +
            "?contract_manufacturer_id=" +
            manufacturer.spoq_producer_id,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        if (!response.status === 200) {
            const response_data = await response.json();
            //console.log(response_data);
            throw new Error(response_data.detail ? response_data.detail : "Network response was not ok");
        }

        // The fetch was successful, so you can proceed with your logic here
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function assignManufacturer(product_api_url, manufacturer, product_id) {
    try {
        fetch(product_api_url + "/product/add_product_to_manufacturer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
            body: JSON.stringify(
                {
                    "product_id": product_id,
                    "contract_manufacturer_id": manufacturer.spoq_producer_id,
                }
            ),
        });
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}
async function removeAssignedManufacturer(product_api_url, manufacturer, product_id) {
    try {
        fetch(product_api_url + "/product/delete_product_to_manufacturer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
            body: JSON.stringify(
                {
                    "product_id": typeof product_id === 'string' ? parseInt(product_id, 16) : product_id,
                    "contract_manufacturer_id": manufacturer.spoq_producer_id
                })
        });
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

function getCachedProductToManufacturer() {
    // Retrieve the cached data from localStorage or any other caching mechanism you're using
    const cachedData = sessionStorage.getItem("cachedProductToManufacturer");
    return JSON.parse(cachedData);
}
async function fetchAllManufacturers(setAllManufacturers) {
    try {
        const response = await fetch(accountApi + "/get_all_manufacturers", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (!response.status === 200) {
            const response_data = await response.json();
            //console.log(response_data);
            throw new Error(response_data.detail ? response_data.detail : "Network response was not ok");
        } else {
            const data = await response.json();
            //console.log(data);
            if (data.manufacturers.length === 0) {
                //console.log("No manufacturers found");
                return [];
            } else {
                setAllManufacturers(data.manufacturers);
                return data.manufacturers;
            }
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
        return [];
    }
}

async function fetchAssignedManufacturers(product_api_url, product_id, setAssignedManufacturers) {
    //console.log("FetchAssignedManufacturers entry")
    const product_to_manufacturer = await fetchProductToManufacturer(product_api_url, product_id);
    const allManufacturers = await fetchAllManufacturers(() => { });
    try {
        if (Array.isArray(allManufacturers) && Array.isArray(product_to_manufacturer)) {
            const assignedManufacturers = allManufacturers.filter(manufacturer => {
                return product_to_manufacturer.some(productManufacturer => {
                    return productManufacturer[2] === parseInt(manufacturer.spoq_producer_id, 16);
                });
            });
            setAssignedManufacturers(product_id, assignedManufacturers);
            return (assignedManufacturers);
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
        return [];
    }
}


export {
    fetchAssociatedContractManufacturers,
    fetchAllManufacturers,
    removeAssociatedManufacturer,
    postSelectedAssociatedManufacturers,
    fetchAssignedManufacturers,
    assignManufacturer,
    removeAssignedManufacturer
}