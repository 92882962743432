

async function fetchChargesForProduct(product_api_url, productID, setCharges) {
    try {
        const response = await fetch(product_api_url + "/charge/get_charges_for_product?productID=" + productID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
        });
        // process response
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
        } else if (!response.headers.get("content-type").includes("application/json")) {
            console.log("Browser Cache is saying no");
        } else {
            const data = await response.json()
            //console.log(data);
            // handle result
            if (data.charges.length === 0) {
                //console.log("No products found");
                //return;
            }
            const sortedCharges = data.charges.sort((a, b) => {
                const intA = a[0]; // Assuming the first element is an integer
                const intB = b[0];
                return intB - intA;
            });
            const sortedChargesAsObjects = sortedCharges.map(charge => ({
                charge_id: charge[0],
                charge_number: charge[1],
                product_id: charge[2],
                charge_size: charge[4],
                contract_manufacturer_id: charge[3],
                sale_region: charge[5],
                production_region: charge[6],
                production_start: charge[7],
                production_end: charge[8],
                begin_date: charge[9],
                end_date: charge[10],
                completion: charge[11]
            }));
            setCharges(sortedChargesAsObjects)
            return sortedChargesAsObjects
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchChargesForManufacturer(product_api_url, productID, manufacturerID, setCharges) {
    try {
        const response = await fetch(product_api_url + "/charge/get_charges_for_product_and_manufacturer?productID=" + productID + "&manufacturerID=" + (typeof manufacturerID === 'string' ? parseInt(manufacturerID, 16) : manufacturerID), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
        });
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
        } else if (!response.headers.get("content-type").includes("application/json")) {
            console.log("Browser Cache is saying no");
        } else {
            const data = await response.json()
            //console.log(data);
            // handle result
            const sortedCharges = data.charges.sort((a, b) => {
                const intA = a[0]; // Assuming the first element is an integer
                const intB = b[0];
                return intB - intA;
            });
            const sortedChargesAsObjects = await sortedCharges.map(charge => ({
                charge_id: charge[0],
                charge_number: charge[1],
                product_id: charge[2],
                charge_size: charge[4],
                contract_manufacturer_id: charge[3],
                sale_region: charge[5],
                production_region: charge[6],
                production_start: charge[7],
                production_end: charge[8],
                begin_date: charge[9],
                end_date: charge[10],
                completion: charge[11]
            }));
            //console.log("Setting charges with", sortedChargesAsObjects);
            setCharges(sortedChargesAsObjects)
            return sortedChargesAsObjects;
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchSpoqProductIdsForCharge(product_api_url, productID, chargeID, setSpoqProductIds, setUnfinishedSpoqProductIds) {
    //console.log("Fecthing Spoq Product Ids ", "With API URL:", product_api_url)
    console.log("ChargeId in fetch Function", chargeID)
    try {
        const response = await fetch(product_api_url + "/charge/get_identitys?product_id=" + productID + "&charge_id=" + chargeID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        // process response
        if (response.status !== 200) {
            return null
            //console.log("Response status: " + response.status);
        } else if (!response.headers.get("content-type").includes("application/json")) {
            console.log("Browser Cache is saying no on generate Spoq Product Ids");
        } else {
            const data = await response.json()
            //console.log(data);
            const spoqProducIds = data.spoq_product_ids;
            const unfinishedSpoqProductIds = data.unfinished_spoq_product_ids;
            //console.log("Setting spoqProducIds with", spoqProducIds);
            setSpoqProductIds(spoqProducIds)
            setUnfinishedSpoqProductIds(unfinishedSpoqProductIds);
            return { "spoqProducIds": spoqProducIds, "unfinishedSpoqProductIds": unfinishedSpoqProductIds }
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchImageForSpoqId(product_api_url, spoq_id, product_id, setImageData) {
    //console.log("Fecthing QR-Image ",)
    try {
        const response = await fetch(product_api_url + "/spoq_identity/get_image_with_id?spoq_id=" + spoq_id + "&product_id=" + product_id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        // process response
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
        } else if (!response.headers.get("content-type").includes("application/json")) {
        } else {
            const data = await response.json()
            //console.log(data);
            const imageData = data.image_data;
            setImageData(imageData);
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postRegisterCharge(product_api_url, chargeInfoData, onAddChargeFinished) {
    // send data to api
    try {
        const response =  await fetch(product_api_url + "/charge/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                "charge_number": chargeInfoData.charge_number,
                "product_id": chargeInfoData.product_id,
                "charge_size": chargeInfoData.charge_size,
                "contract_manufacturer_id": chargeInfoData.contract_manufacturer_id,
                "sale_region": chargeInfoData.sale_region,
                "production_region": chargeInfoData.production_region,
                "production_start": chargeInfoData.production_start,
                "production_end": chargeInfoData.production_end,
                "begin_date": chargeInfoData.begin_date,
                "end_date": chargeInfoData.end_date
            }),
        });
        if (response.status !== 200) {
            console.log(" Adding Charge Request failed Response status: " + response);
        } else {
            onAddChargeFinished();
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postUpdateSpoqIdentity(product_api_url, spoqIdentityInfoData, hex_spoq_product_id) {
    // send data to api
    try {
        fetch(product_api_url + "/spoq_identity/update?spoq_product_id_str=" + hex_spoq_product_id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                "product_id": spoqIdentityInfoData.product_id,
                "charge_id": spoqIdentityInfoData.charge_id,
                "date_of_manufacture": spoqIdentityInfoData.date_of_manufacture,
                "contract_manufacturer": spoqIdentityInfoData.contract_manufacturer,
                "qr_image": spoqIdentityInfoData.qr_image,
                "verification_method": spoqIdentityInfoData.verification_method,
                "spoq_product_id": spoqIdentityInfoData.spoq_product_id,
            }),
        });
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postUpdateCharge(product_api_url, chargeInfoData, chargeID) {
    try {
        fetch(product_api_url + "/charge/update?chargeId=" + chargeID, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                "charge_number": chargeInfoData.charge_number,
                "product_id": chargeInfoData.product_id,
                "charge_size": chargeInfoData.charge_size,
                "contract_manufacturer_id": chargeInfoData.contract_manufacturer_id,
                "sale_region": chargeInfoData.sale_region,
                "production_region": chargeInfoData.production_region,
                "production_start": chargeInfoData.production_start,
                "production_end": chargeInfoData.production_end,
                "begin_date": chargeInfoData.begin_date,
                "end_date": chargeInfoData.end_date
            }),
        });
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}



export {
    fetchChargesForProduct,
    fetchChargesForManufacturer,
    fetchSpoqProductIdsForCharge,
    fetchImageForSpoqId,
    postRegisterCharge,
    postUpdateSpoqIdentity,
    postUpdateCharge
}