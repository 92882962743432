// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/components/ChargeTile.css"
import { useTranslation } from "react-i18next";
import { fetchSpoqProductIdsForCharge } from "../../api_functions/ChargeInfo";
import ChargeDetail from "./ChargeDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function ChargeTile({
  spoq_producer_id,
  product_api_url,
  chargeData,
  contractManufacturerName,
  isManufacturerView,
  isProducerView,
  productData,
  onChargeInfoChanged,
  spoqUserData,
  isMobile }) {
  const { t } = useTranslation();
  const [showFinishChargeDialog, setShowFinishChargeDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const isInProductionPeriod = Date.now() >= convertDateStringsToDateObject(chargeData.production_start).getTime() && Date.now() <= convertDateStringsToDateObject(chargeData.production_end).getTime();

  useEffect(() => {
    console.log("Chargedata Useeffect:", chargeData)
  }, [chargeData]);
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function convertDateStringsToDateObject(dateString) {
    if (typeof dateString === 'undefined') {
      return new Date(0);
    }
    const dateParts = dateString.split("-");
    const dateObject = new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2]));
    return dateObject;
  }
  return (

    <div>
      {!isMobile && <div className={"standard-row"}>
        <div className={"charge-item"} key={'charge-Item' + chargeData.charge_number}>
          <table key={chargeData.charge_number} className="charge-table w3-table w3-striped w3-border w3-bordered w3-margin">
            <tbody>
              <tr>
                <td>{t("account.add_charge.charge_number")}</td>
                <td>{chargeData.charge_number}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.charge_size")}</td>
                <td>{chargeData.charge_size}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.contract_manufacturer")}</td>
                <td>{contractManufacturerName}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.sale_region")}</td>
                <td>{chargeData.sale_region}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.production_period")}</td>
                <td>{chargeData.production_start + "     -     " + chargeData.production_end}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.begin_date")}</td>
                <td>{chargeData.begin_date}</td>
              </tr>
              <tr>
                <td>{t("account.add_charge.end_date")}</td>
                <td>{"1970-01-01" === chargeData.end_date ? t("account.charges.end_date_not_set") : chargeData.end_date}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          {chargeData &&
            <center className={"charge-progress-indicator"}>
              <CircularProgressbar
                value={
                  (chargeData.completion) * 100
                }
                text={ `${(chargeData.completion).toFixed(2) * 100}%`
              }
              />
            </center>}
          {!isManufacturerView ||( isManufacturerView && isInProductionPeriod ) || chargeData.contract_manufacturer_id.toString(16) === spoq_producer_id ?
            <button
              className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
              onClick={() => {
                setShowFinishChargeDialog(true);
              }}
              disabled={spoqUserData.role >= 3}>
              <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
              <i className={"fa-solid fa-upload"} style={{ marginRight: "10px" }}></i>
              <i className={"fa-solid fa-download"} style={{ marginRight: "10px" }}></i>
              {t("account.charges.details")}
            </button> :
            <div>{t("account.charges.out_of_production_period")} </div>
          }
        </div>
      </div>}
      {isMobile &&
        <div
          onClick={() => {
            setExpanded(!expanded)
          }}>
          <div className="charge-tile-mobile">
            <center style={{ flex: "6" }}>{chargeData.charge_number}</center>
            <center style={{ flex: "3" }}>
              {!isManufacturerView || ( isManufacturerView && isInProductionPeriod ) ?
                <button
                  className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                  onClick={() => {
                    setShowFinishChargeDialog(true);
                  }}
                  disabled={spoqUserData.role >= 3}>
                  <i className={"fas fa-qrcode"} style={{ marginRight: "10px" }}></i>
                  <i className={"fa-solid fa-upload"} style={{ marginRight: "10px" }}></i>
                  <i className={"fa-solid fa-download"} style={{ marginRight: "10px" }}></i>
                  {t("account.charges.details")}
                </button> :
                <div>{t("account.charges.out_of_production_period")} </div>
              }
            </center>
            <center className="charge-progress-indicator-mobile" style={{ flex: "3", maxHeight: "70px", maxWidth: "70px" }}>
              <CircularProgressbar
                value={
                  (chargeData.completion) * 100
                }
                text={ `${(chargeData.completion).toFixed(2) * 100}%`
              }
              />
            </center>
            <center style={{ flex: "1" }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </center>
          </div>
          {expanded && <div className="charge-tile-mobile">
            <table key={chargeData.charge_number} className="charge-table w3-table w3-border w3-bordered">
              <tbody>
                <tr>
                  <td>{t("account.add_charge.charge_size")}</td>
                  <td>{chargeData.charge_size}</td>
                </tr>
                <tr>
                  <td>{t("account.add_charge.contract_manufacturer")}</td>
                  <td>{contractManufacturerName}</td>
                </tr>
                <tr>
                  <td>{t("account.add_charge.sale_region")}</td>
                  <td>{chargeData.sale_region}</td>
                </tr>
                <tr>
                  <td>{t("account.add_charge.production_period")}</td>
                  <td>{chargeData.production_start + "     -     " + chargeData.production_end}</td>
                </tr>
                <tr>
                  <td>{t("account.add_charge.begin_date")}</td>
                  <td>{chargeData.begin_date}</td>
                </tr>
                <tr>
                  <td>{t("account.add_charge.end_date")}</td>
                  <td>{"1970-01-01" === chargeData.end_date ? t("account.charges.end_date_not_set") : chargeData.end_date}</td>
                </tr>
              </tbody>
            </table>
          </div>
          }
        <div style={{height:"5px"}}/>
        </div>}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
      />
      <ChargeDetail
        show={showFinishChargeDialog}
        product_api_url={product_api_url}
        spoq_prooducer_id={spoq_producer_id}
        onClose={() => {
          setShowFinishChargeDialog(false);
        }
        }
        chargeInfo={chargeData}
        productInfo={productData}
        isManufacturerView={isManufacturerView}
        isProducerView={isProducerView}
        onChargeInfoChanged={() => {
          onChargeInfoChanged();
        }
        }
        isMobile={isMobile} />
    </div>
  );
}

export default ChargeTile;
