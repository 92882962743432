import "../../css/basicStyle.css";
import "../../css/theme.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CookieConsent from "react-cookie-consent";
import {
  faQrcode,
  faArchive,
  faExclamation,
  faMapMarker,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import AimLogo from "../../images/logos/aim_logo_2.jpg";
import HSLogo from "../../images/logos/hs_logo.png";
import KobilLogo from "../../images/logos/kobil_logo.png";
import PAVCardLogo from "../../images/logos/logo-pav-card.jpg";
import UniWueLogo from "../../images/logos/UniWue_Logo.svg.png";
import VDELogo from "../../images/logos/2560px-VDE_logo.svg.png";
import Logo from "../../images/Spoq_Globe.png";
import { Link } from "react-router-dom"; // for routing to other pages
import { HashLink } from "react-router-hash-link";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
// migration from index.html
const Home = ({spoqProducerData, spoqUserData, isMobile}) => {
  const [newsLetterChecked, setNewsLetterChecked] = useState(true);
  function toggleNewsLetterChecked() {
    setNewsLetterChecked(!newsLetterChecked);
  }
  // Close side navigation
  const w3_close = () => {
    document.getElementById("mySidebar").style.display = "none";
  };
  useEffect(() => {
    console.log("Home", isMobile)
  },[isMobile]);
  const { t } = useTranslation();

  return (
    <>
      <Header 
      spoqProducerData= {spoqProducerData}
      isMobile={isMobile}
      showBanner={true}
      spoqUserData={spoqUserData}/>
        <div
          className="w3-display-container w3-animate-opacity"
          style={{
            background:
              "linear-gradient(90deg, rgba(38,49,109,1) 0%, rgba(63,81,181,1) 64%, rgb(133, 147, 216) 100%)",
            position:"unset"
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ minHeight: "20vh", maxHeight: "20vh" }}
            />

            <div style={isMobile ? { color: 'white', fontSize: '60px', marginLeft: '24px' } : { color: 'white', fontSize: '120px', marginLeft: '24px' }}>
              SPOQ
            </div>
          </div>
        </div>
      <div>
        <div>
          <CookieConsent
            overlay={true}
            buttonText={t("cookie.accept")}
            style={{
              color: "#fff",
              backgroundColor: "#000",
            }}
            buttonStyle={{ color: "#fff", backgroundColor: "#3949a3" }}
            location="none"
          >
            {t("cookie.info")}
          </CookieConsent>
        </div>
        <nav
          className="w3-sidebar w3-bar-block w3-white w3-card w3-animate-left w3-medium"
          style={{ display: "none", zIndex: 2 }}
          id="mySidebar"
        >
          <button
            onClick={w3_close}
            className="w3-bar-item w3-button w3-display-topright w3-text-theme-d5"
          >
            Schließen <FontAwesomeIcon icon={faRemove} />
          </button>
          <HashLink to="#" className="w3-bar-item w3-button">
            Partner 1
          </HashLink>
          <HashLink to="#" className="w3-bar-item w3-button">
            Partner 2
          </HashLink>
          <HashLink to="#" className="w3-bar-item w3-button">
            Partner 3
          </HashLink>
        </nav>
        <div className="w3-container w3-padding-64 w3-indigo" id="info">
          <div className="w3-content">
            <p>{t("home.info")}</p>
          </div>
          <div className="w3-container w3-center w3-margin">
            <button
              onClick={() =>
                (document.getElementById("id01").style.display = "block")
              }
              className="w3-button w3-large w3-theme-d4 w3-hover-white w3-margin"
              title="Download .apk"
            >
              {t("home.download")}
            </button>

            <div>
              <Link
                to="/register"
                className="w3-button w3-large w3-theme-d4 w3-hover-white "
              >
                {t("home.register_company")}
              </Link>
            </div>
          </div>
        </div>

        {/* Features Container */}
        <div className="w3-container w3-padding-64 w3-margin w3-center" id="features">
          <h2>{t("home.check_authenticy")}</h2>
          <p>{t("home.check_authenticy_text")}</p>

          <div className="w3-row">
            <div className="w3-third">
              <FontAwesomeIcon
                icon={faArchive}
                size="5x"
                className="w3-text-indigo"
              />
              <h3>{t("home.company")}</h3>
              <p>{t("home.company_text")}</p>
            </div>

            <div className="w3-third">
              <FontAwesomeIcon
                icon={faQrcode}
                size="5x"
                className="w3-text-indigo"
              />
              <h3>{t("home.qr_code")}</h3>
              <p>{t("home.qr_code_text")}</p>
            </div>

            <div className="w3-third">
              <FontAwesomeIcon
                icon={faExclamation}
                size="5x"
                className="w3-text-indigo"
              />
              <h3>{t("home.warnings")}</h3>
              <p>{t("home.warnings_text")}</p>
            </div>
          </div>
        </div>

        <div className="w3-content w3-center w3-padding">
          <h1>{t("home.self_advertisment")}</h1>
          <p>{t("home.self_advertisment_text")}</p>
        </div>

        {/* Work Row  */}
        <div className="container w3-theme-l1 w3-padding" id="partner">
          <div>
            <h1>
              {t("home.relations")}
              
            </h1>
            <p>
            {t("home.relations_text1")}
            </p>
            <p>
            {t("home.relations_text2")}
            </p>
          </div>
          <ul className="cards ">
          <li className="card w3-white">
              <div>
                <h3 className="card-title">DKE</h3>
                <div
                style={{ maxWidth: "300px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {VDELogo}
                    alt="VDE Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
                  VDE Verband der Elektrotechnik Elektronik Informationstechnik e.V.
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
              <a href="https://www.dke.de" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
            <li className="card w3-white">
              <div>
                <h3 className="card-title">AIM-D e.V.</h3>
                <div
                style={{ maxWidth: "100px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {AimLogo}
                    alt="AIM Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
Industrieverband Automatische Identifikation, Datenerfassung und Mobile Kommunikation e.V
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
                <a href="https://www.aim-d.de" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
           
            <li className="card w3-white">
              <div>
                <h3 className="card-title">Hochschule Mannheim</h3>
                <div
                style={{ maxWidth: "300px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {HSLogo}
                    alt="Hochschule Mannheim Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
                  Fakultät für Informationstechnik, ESM-Institut
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
              <a href="https://www.esm.hs-mannheim.de" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
            <li className="card w3-white">
              <div>
                <h3 className="card-title">Julius-Maximilians-Universität Würzburg</h3>
                <div
                style={{ maxWidth: "300px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {UniWueLogo}
                    alt="Universität Würzburg Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
                  Lehrstuhl für Software Engineering
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
              <a href="https://se.informatik.uni-wuerzburg.de/secure-software-systems-group/secure-software-systems-group/" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
            <li className="card w3-white">
              <div>
                <h3 className="card-title">Kobil GmbH</h3>
                <div
                style={{ maxWidth: "300px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {KobilLogo}
                    alt="Kobil Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
                  Sicherheitstechnologien
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
              <a href="https://www.kobil.com" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
            <li className="card w3-white">
              <div>
                <h3 className="card-title">PAV Card GmbH</h3>
                <div
                style={{ maxWidth: "300px", display: "inline-block" }}
                className="w3-center">
                <img
                    src= {PAVCardLogo}
                    alt="PAV Card Logo"
                    className="w3-image"
                  />
                </div>
                <div className="card-content">
                  <p>
                  RFID-Lösungen und IT-Services
                  </p>
                </div>
              </div>
              <div className="card-link-wrapper">
              <a href="https://www.pav.de" target="_blank" className="card-link w3-theme-d5" rel="noreferrer">
                  Website
                </a>
              </div>
            </li>
            
          </ul>
        </div>

        {/* Contact Container */}
        <div
          className="w3-container w3-padding-64 w3-theme-l5 w3-center"
          id="contact"
        >
          <div className="w3-row">
            <div className="w3-col m5">
              <div className="w3-padding-16">
                <span className="w3-xlarge w3-border-indigo w3-bottombar">
                  {t("home.contact")}
                </span>
              </div>
              <h3>{t("home.adress")}</h3>
              <p>{t("home.invite")}</p>
              <p>
                <FontAwesomeIcon
                  icon={faMapMarker}
                  className="w3-text-indigo w3-xlarge"
                />
                Mannheim, DE
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="w3-text-indigo w3-xlarge"
                />
                +49 621 292 xxxx
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="w3-text-indigo w3-xlarge"
                />
                test@test.com
              </p>
              <div className="w3-bar w3-theme-d2 w3-left-align">
                <Link
                  to="/impressum"
                  className="w3-bar-item w3-button w3-hide-small w3-hover-white"
                >
                  {t("home.impressum")}
                </Link>
              </div>
            </div>
            <div className="w3-col m7">
              <form
                className="w3-container w3-card-4 w3-padding-16 w3-white"
                action="/action_page.php"
                target="_blank"
                rel="noreferrer"
              >
                <div className="w3-section">
                  <label>{t("home.name")}</label>
                  <input
                    className="w3-input"
                    type="text"
                    name="Name"
                    required
                  />
                </div>
                <div className="w3-section">
                  <label>{t("home.email")}</label>
                  <input
                    className="w3-input"
                    type="text"
                    name="Email"
                    required
                  />
                </div>
                <div className="w3-section">
                  <label>{t("home.message")}</label>
                  <input
                    className="w3-input"
                    type="text"
                    name="Message"
                    required
                  />
                </div>
                <input
                  className="w3-check"
                  type="checkbox"
                  checked={newsLetterChecked}
                  name="Like"
                  onChange={toggleNewsLetterChecked}
                />
                <label>{t("home.newsletter")}</label>
                <button type="submit" className="w3-button w3-right w3-theme">
                  {t("button.send")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
