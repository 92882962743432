import React, { useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faEnvelope,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postLogin, postVerifyOTPToken } from "../../api_functions/AccountInfo";

// migration from index.html
const Login = ({onAccountInfoChanged, spoqProducerData, isMobile }) => {
  const { t } = useTranslation();
  const [twoFABoolean, setTwoFABoolean] = useState(false);
  const [spoqProducerId, setSpoqProducerId] = useState("");
  const padding = "128px";
  const [hintText, setHintText] = useState("");

  function handleButtonClick(event) {
    event.preventDefault();
    const producerId = document.getElementById("input_producer_id").value;
    const email = document.getElementById("input_email").value;
    const password = document.getElementById("input_password").value;


    console.log(
      "producerId: " +
      producerId +
      ", email: " +
      email +
      ", password: " +
      password
    );
    if (producerId === "" || email === "" || password === "") {
      setHintText("Please fill out all fields.");
    } else {
      // make api call
      console.log(typeof producerId);
      console.log(
        JSON.stringify({
          spoq_producer_id: producerId,
          email: email,
          password: password,
        })
      );
      postLogin(producerId, email, password, setSpoqProducerId, setTwoFABoolean, setHintText);

    }
  }

  function handle2FAButtonClick(event) {
    event.preventDefault();
    postVerifyOTPToken(spoqProducerId, setTwoFABoolean, onAccountInfoChanged, setHintText);
  }

  return (
    <div style={isMobile? {fontSize:"11px"} : {}}>
      <Header 
      spoqProducerData={spoqProducerData}
      isMobile={isMobile}
      showBanner={false} />

      {/* Infos zur Anmeldung */}
      <div className="w3-container w3-center" id="info">
        <div className="w3-content ">
          <p>{t("login.text")}</p>
        </div>
      </div>

      <div className="w3-container w3-padding-64 w3-center" id="spacer" />

      {twoFABoolean ? (
        <div
          style={{
            minHeight:'70vh',
            paddingTop: padding,
            paddingBottom: padding,
          }}
          className="w3-center w3-margin-top "
        >
          <form className="w3-centered" onChange={(e) => {
            setHintText("");
          }}>
            <div className="w3-center w3-padding">
              <div className="w3-margin-bottom">
                <label htmlFor="token">{t("login.twofa")}</label>
              </div>
              <div>
                <input
                  type="text"
                  name="token"
                  id="token"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  className="w3-input w3-center w3-border w3-margin-bottom"
                  style={{ maxWidth: "400px", display: "inline-block" }}
                />
              </div>
            </div>
            {hintText !== "" && <center style={{margin:"20px"}}>
              <label style={{color:'red'}}>{hintText}</label>
            </center>}
            <div className="w3-center">
              <button
                type="submit"
                className="w3-center w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={(event) => handle2FAButtonClick(event)}
              >
                {t("login.twofa.submit")}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div
          className="w3-container"
          style={{ backgroundColor: "rgb(219, 228, 255)" , minHeight:'70vh'}}
        >
          {isMobile ? <h4 className="w3-center">{t("login.title")}</h4> : <h2 className="w3-center">{t("login.title")}</h2>}
          <form id="ijtfgh" className="w3-center" onChange={(e) => {
            setHintText("");
          }}>
            <div className="w3-center w3-padding-16">
              <label id="log_producer_id">
                <b>
                  <FontAwesomeIcon
                    icon={faIdCard}
                    className="w3-margin-right"
                  />
                  {t("login.producer_id")}
                </b>
              </label>
            </div>
            <div>
              <input
                type="text"
                id="input_producer_id"
                name="log_producer_id"
                placeholder="Producer ID"
                className="w3-input w3-border w3-center"
                style={{ maxWidth: "400px", display: "inline-block" }}
              />
            </div>
            <div className="w3-center w3-padding-16">
              <label id="log_email">
                <b>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="w3-margin-right"
                  />
                  {t("login.email")}
                </b>
              </label>
            </div>
            <div>
              <input
                type="email"
                id="input_email"
                name="log_email"
                placeholder="E-Mail"
                className="w3-input w3-border w3-center"
                style={{ maxWidth: "400px", display: "inline-block" }}
              />
            </div>
            <div className="w3-center w3-padding-16">
              <label id="log_password">
                <b>
                  <FontAwesomeIcon icon={faLock} className="w3-margin-right" />
                  {t("login.password")}
                </b>
              </label>
            </div>
            <div>
              <input
                type="password"
                id="input_password"
                name="log_password"
                placeholder="Password"
                className="w3-input w3-border w3-center"
                style={{ maxWidth: "400px", display: "inline-block" }}
              />
            </div>
            {hintText !== "" && <center style={{margin:"20px"}}>
              <label style={{color:'red'}}>{hintText}</label>
            </center>}
            <div className="w3-center w3-padding-16">
              <button
                type="submit"
                onClick={(event) => handleButtonClick(event)}
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
              >
                {t("login.submit")}
              </button>
            </div>
          </form>

          <div className="w3-center">
            <span className="error_label"></span>
          </div>

          <div className="w3-center">
            <span className="psw">
              <Link to="/forgot_password">{t("login.forgot")}</Link>
            </span>
          </div>

          <div className="w3-center w3-padding-16">
            <span className="noacc">
              <Link to="/register">{t("login.noaccount")}</Link>
            </span>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Login;
