import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import ProducerProductBar from "../../Components/ProducerProductsBar";
import { useTranslation } from "react-i18next";
import { fetchAllProducts, fetchAllProductsForManufacturer } from "../../api_functions/ProductInfo";
import { fetchCompanyInfo } from "../../api_functions/AccountInfo";
import { fetchProductionScannersForProduct } from "../../api_functions/ScannerInfo";
import ProductTile from "../../Components/ProductTile";
import "../../css/pages/ProductionScanners.css"
import AddScannerDialog from "./AddScannerDialog";
import ScannerTile from "./ScannerTile";
import Header from "../../Components/Header";

const ProductionScanner = ({ spoqProducerData, foreign_product_api_urls, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [contractProducers, setContractProducers] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState(null);
  const [productionScannerData, setProductionScannerData] = useState([]);
  const [showAddScannerDialog, setShowAddScannerDialog] = useState(false);
  useEffect(() => {
    //console.log("Initial UseEffect")
    if (spoqProducerData) {
      if (spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "") {
        fetchAllProducts(spoqProducerData.product_api_url, setProducts, () => { }, () => { })
      }
      if (spoqProducerData.contract_manufacturer_boolean) {
        for (const foreign_data of foreign_product_api_urls) {
          //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
          fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (spoqProducerData) {
      if (spoqProducerData.contract_manufacturer_boolean) {
        for (const foreign_data of foreign_product_api_urls) {
          //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
          fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
        }
      }
    }
  }, [spoqProducerData, foreign_product_api_urls])
  useEffect(() => {
    //console.log("UseEffect selectedProducer")
    setProducts([]);
    if (selectedProducer && spoqProducerData) {
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
        fetchAllProducts(spoqProducerData.product_api_url, setProducts, () => { }, () => { });
      }
      else if (selectedProducer && foreign_product_api_urls.length > 0 && typeof selectedProducer !== 'undefined') {
        //console.log(contractProducers)
        var product_apiUrl_id_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id);
        if (selectedProducer.product_api_url !== "" && product_apiUrl_id_pair[0]) {
          fetchAllProductsForManufacturer(product_apiUrl_id_pair[0].product_api_url, spoqProducerData.spoq_producer_id, setProducts, () => { }, () => { });
        }
      }
    }
  }, [selectedProducer]);

  useEffect(() => {
    if (spoqProducerData.producer_boolean) {
      setSelectedProducer(
        {
          "spoq_producer_id": spoqProducerData.spoq_producer_id,
          "company_name": spoqProducerData.spoq_producer_name,
          "contact_person_name": spoqProducerData.contact_person_name,
          "contact_email": spoqProducerData.contact_email,
          "company_logo": spoqProducerData.company_logo
        }
      )
    }
    else if (contractProducers.length > 0) {
      setSelectedProducer(contractProducers[0]);
    }
  }, [contractProducers])
  useEffect(() => {
    console.log("useEffect: selectedProduct", selectedProduct)
    if (selectedProduct && selectedProducer) {
      fetchScanners();
    }
  }, [selectedProduct]);
  useEffect(() => {
    //console.log("UseEffect Products")
    if (products) {
      //console.log("UseEffect: products")
      if (products.length > 0) {
        setSelectedProduct(products[0])
      }
    }
  }, [products])
  useEffect(() => {
    console.log("productionScannerData", productionScannerData)
  }, [productionScannerData])
  function addForeignProducerInfo(foreignProducerInfo) {
    //console.log("foreignProducerInfo", foreignProducerInfo);
    // Check if the foreignProducerInfo is not already in the array
    if (!contractProducers.some((producer) => producer.company_name === foreignProducerInfo.company_name)) {
      // Add the foreignProducerInfo to the state while removing duplicates
      setContractProducers((prevProducers) => [
        ...prevProducers.filter((producer) => producer.company_name !== foreignProducerInfo.company_name),
        foreignProducerInfo,
      ]);
    }
  }
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function intToHexString(num, length) {
    // Convert the integer to a hexadecimal string without leading "0x".
    let hexString = num.toString(16);

    // Pad the string with leading zeros to ensure a fixed length of 5.
    while (hexString.length < length) {
      hexString = '0' + hexString;
    }

    return hexString;
  }

  function fetchScanners() {
    //console.log(selectedProduct);
    if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
      fetchProductionScannersForProduct(spoqProducerData.product_api_url, selectedProduct.product_id, setProductionScannerData)
    }
    if (selectedProducer && spoqProducerData.contract_manufacturer_boolean) {
      //console.log("Contract Producers", contractProducers)
      console.log("Fetching Scanners for foreign Product")
      var contract_producer_id = selectedProducer.spoq_producer_id;
      var id_api_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === contract_producer_id);
      console.log("ID API Pair:", id_api_pair);
      if (id_api_pair[0]) {
        var product_api_url = id_api_pair[0].product_api_url;
        console.log(selectedProduct)
        console.log("With api Url:", product_api_url)
        fetchProductionScannersForProduct(product_api_url, selectedProduct.product_id, setProductionScannerData)
      }
    }
  }

  return (
    <div style={isMobile? {fontSize:"11px"}: {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />
      <div className="producer-product-content-row">
        <ProducerProductBar
          productsData={products}
          producerData={spoqProducerData.producer_boolean ? [{
            "spoq_producer_id": spoqProducerData.spoq_producer_id,
            "company_name": spoqProducerData.spoq_producer_name,
            "contact_person_name": spoqProducerData.contact_person_name,
            "contact_email": spoqProducerData.contact_email,
            "company_logo": spoqProducerData.company_logo
          }, ...contractProducers] : contractProducers}
          onProducerSelected={(selectedProducer) => {
            setSelectedProducer(selectedProducer);
          }
          }
          onProductSelected={(selectedProduct) => {
            setSelectedProduct(selectedProduct);
          }}
          selectedProducer={selectedProducer}
          isMobile={isMobile}
        />
        <div className="scanners-page" style={isMobile ? {minHeight:'85vh'} : {minHeight:'70vh'}}>
          <div className="scanners-page-content ">
            <center>
              {isMobile ? <h3>{t("account.production_scanner")}</h3> : <h1>{t("account.production_scanner")}</h1>}
            </center>

            <div className="w3-center">

              {selectedProducer && selectedProduct && spoqProducerData && products &&
                <div>
                  <div>
                    <ProductTile
                      productData={selectedProduct}
                      isMobile={isMobile}
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                      onClick={() => {
                        setShowAddScannerDialog(true)
                      }}
                      style={isMobile ? {fontSize:"11px"}: {}}
                    >
                      {t("account.production_scanner.add_scanner")}
                    </button>
                  </div>
                  {productionScannerData && <div>
                    {productionScannerData.map(scannerData => (
                      <div key={scannerData.scanner_id}>
                      <ScannerTile
                        scannerData={scannerData}
                        selectedProducer={selectedProducer}
                        selectedProduct={selectedProduct}
                        spoqProducerData={spoqProducerData}
                        foreign_product_api_urls={foreign_product_api_urls}
                        onScannerUpdated={() => {
                          fetchScanners();
                          }
                        }
                        isMobile={isMobile}
                        key={scannerData.scanner_id}
                      />
                      </div>
                    ))}
                  </div>}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
      />
      {selectedProducer && spoqProducerData &&
        <AddScannerDialog
          show={showAddScannerDialog}
          productInfo={selectedProduct}
          spoqProducerData={spoqProducerData}
          product_api_url={selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id ?
            spoqProducerData.product_api_url :
            foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id)[0].product_api_url
          }
          onAdded={async () => {
            await delay(600)
            fetchScanners();
            setShowAddScannerDialog(false);
          }
          }
          onClose={() => {
            setShowAddScannerDialog(false);
          }}
          isMobile={isMobile}
        />}
      <Footer />
    </div>
  );
};

export default ProductionScanner;
