import React, { useState, useEffect } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/Statistics.css"
import Footer from "../../Components/Footer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { fetchAllProducts, fetchProductStatisticData } from "../../api_functions/ProductInfo";
import { fetchManufacturerStatisticData, fetchRepresentativeData } from "../../api_functions/AccountInfo";
import { fetchAssociatedContractManufacturers } from "../../api_functions/ManufacturerInfo";
import Header from "../../Components/Header";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// page containing the base information of a company including the option to
// edit the information
const AccountStatistics = ({ spoqProducerData, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [representativeOptions, setRepresentativeOptions] = useState(null);
  const [productTypeOptions, setProductTypeOptions] = useState(null);
  const [associatedManufacturers, setAssociatedManufacturers] = useState([])
  const [manufacturerCompletionRateOptions, setManufacturerCompletionRateOptions] = useState(null);
  const [manufacturerNumProductsOptions, setManufacturerNumProductsOptions] = useState(null);
  const [manufacturerNumChargesOptions, setManufacturerNumChargesOptions] = useState(null);

  const [productToIdentitysData, setProductToIdentitysData] = useState(null);
  const [productToChargesData, setProductToChargesData] = useState(null);


  const [statisticsDisplayMode, setStatisticsDisplayMode] = useState(1);

  var [productInfo, setProductInfo] = useState([]);


  useEffect(() => {
    var infoText = document.getElementById("info_text");
    if (spoqProducerData && spoqProducerData.account_api_url) {
      fetchRepresentativeData(spoqProducerData.account_api_url, setRepresentativeOptions, representativeOptions)
      fetchAllProducts(spoqProducerData.product_api_url, setProductInfo, () => { }, () => { });
      fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
    } else {
      if (infoText) {
        infoText.innerHTML = "No API URLs found, so no statistics available."
        infoText.style.color = "red";
      }
    }
  }, [spoqProducerData]);
  useEffect(() => {
    if (associatedManufacturers.length > 0) {
      fetchManufacturerStatisticData(spoqProducerData.product_api_url, associatedManufacturers, setManufacturerNumProductsOptions, setManufacturerCompletionRateOptions, setManufacturerNumChargesOptions);
    }
  }, [associatedManufacturers]);
  useEffect(() => {
    // count all product types and add name + count to dictionary
    var product_types = {};
    for (var i = 0; i < productInfo.length; i++) {
      if (productInfo[i].product_type in product_types) {
        product_types[productInfo[i].product_type]++;
      } else {
        product_types[productInfo[i].product_type] = 1;
      }
    }

    // set the product type options
    var product_type_data = [];
    for (var key in product_types) {
      product_type_data.push({ y: product_types[key], label: key });
    }
    setProductTypeOptions({
      labels: Object.keys(product_types),
      datasets: [{
        data: Object.values(product_types),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },]
    });
    fetchProductStatisticData(spoqProducerData.product_api_url,productInfo, setProductToIdentitysData, setProductToChargesData)
  }, [productInfo])
  return (
    <div style={isMobile? {fontSize:"11px"}: {}}>
      <Header
        showBanner={false}
        spoqProducerData={spoqProducerData}
        spoqUserData={spoqUserData}
        isMobile={isMobile} />
      {spoqProducerData.product_api_url !== "" && spoqProducerData.account_api_url !== "" ? (
        <div className="w3-pading-32 w3-center" style={isMobile ? {minHeight:'85vh'} : {minHeight:'75vh'}}>
          {isMobile ?<h3>{t("account.statistics")}</h3>: <h1>{t("account.statistics")}</h1>}
          {(spoqProducerData.product_api_url === "" || spoqProducerData.account_api_url === "") &&
            <h4>{t("account.statistics.info")}</h4>}

          <div
            className="w3-padding w3-margin-top"
            style={{ width: "50%", margin: "auto" }}
          >
          </div>
          <div className="buttons-statistics-container-row">
            <div className="buttons-container">
              <span>
                {t("account.statistics.selection")}
              </span>
              <button
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={() => {
                  setStatisticsDisplayMode(1);
                }}
                disabled={statisticsDisplayMode === 1}
              >
                {t("account.statistics.company_members")}
              </button>
              <button
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={() => {
                  setStatisticsDisplayMode(2);
                }}
                disabled={statisticsDisplayMode === 2}
              >
                {t("account.statistics.products")}
              </button>
              <button
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={() => {
                  setStatisticsDisplayMode(3);
                }}
                disabled={statisticsDisplayMode === 3}
              >
                {t("account.statistics.charges")}
              </button>
              <button
                className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                onClick={() => {
                  setStatisticsDisplayMode(4);
                }}
                disabled={statisticsDisplayMode === 4}
              >
                {t("account.statistics.manufacturers")}
              </button>
            </div>
            <div className="statistics-constainer">
              {representativeOptions && statisticsDisplayMode === 1 && <div
                className="w3-padding-32 w3-center"
              >
                <Bar options={{
                  responsive: true,
                  plugins: {

                    title: {
                      display: true,
                      text: 'Number of Company Members',
                    },
                  },
                }}
                  data={representativeOptions} />
              </div>}

              {productTypeOptions && statisticsDisplayMode === 2 && <div
                className="w3-padding-32 w3-center"
                style={{ width: "50%", margin: "auto" }}
              >
                <Pie
                options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'Registered Product Types',
                    },
                  },
                }}
                  data={productTypeOptions}
                />
              </div>}


              {manufacturerCompletionRateOptions && (statisticsDisplayMode === 3 || statisticsDisplayMode === 4) &&
                <div
                  className="w3-padding-32 w3-center"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: true,
                          text: 'Completion Rate of Charges for Manufacturers',
                        },
                      },
                    }}
                    data={manufacturerCompletionRateOptions}
                  /* onRef = {ref => this.chart = ref} */
                  />
                </div>}
              {manufacturerNumChargesOptions && (statisticsDisplayMode === 3 || statisticsDisplayMode === 4) &&
                <div
                  className="w3-padding-32 w3-center"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: true,
                          text: 'Number of Charges assigned to Manufacturers',
                        },
                      },
                    }}
                    data = {manufacturerNumChargesOptions}
                  /* onRef = {ref => this.chart = ref} */
                  />
                </div>}
              {manufacturerNumProductsOptions && (statisticsDisplayMode === 2 || statisticsDisplayMode === 4) &&
                <div
                  className="w3-padding-32 w3-center"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: true,
                          text: 'Number of Products assigned to Manufacturers',
                        },
                      },
                    }}
                    data={manufacturerNumProductsOptions}
                  />
                </div>}

                {productToIdentitysData && (statisticsDisplayMode === 2) &&
                <div
                  className="w3-padding-32 w3-center"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Product Identitys registered per Product',
                        },
                      },
                    }}
                    data={productToIdentitysData}
                  />
                </div>}

                {productToChargesData && (statisticsDisplayMode === 2 || statisticsDisplayMode === 3) &&
                <div
                  className="w3-padding-32 w3-center"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Charges per Product',
                        },
                      },
                    }}
                    data={productToChargesData}
                  />
                </div>}
            </div>
          </div>
        </div>
      ) : (
        <div className="w3-center w3-padding-64 w3-margin"> style={isMobile ? {minHeight:'85vh'} : {minHeight:'70vh'}}
          <p id="info_text"></p>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default AccountStatistics;
