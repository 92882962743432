async function fetchProductionScannersForProduct(product_api_url, product_id, setProductionScannerInfo) {
    try {
        const scanners_response = await fetch(product_api_url + "/product_scanners/get_scanners_for_product?product_id=" + product_id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
        });
        if (scanners_response.status !== 200) {
            const response_data = await scanners_response.json();
            throw new Error(response_data.detail ? response_data.detail : "Network response was not ok");
        } else {
            if (!scanners_response.headers.get("content-type").includes("application/json")) {
                var scanners = JSON.parse(sessionStorage.getItem("scanners_for_product" + String(product_id)));
                console.log("Browser cache is saying no fetchProductionScannersForProduct cached Data:", scanners)
                setProductionScannerInfo(scanners)
            } else {
                const data = await scanners_response.json();
                const scanners = data.scanners.map(scannerData => {
                    // Convert each scanner array into an object
                    return {
                        scanner_id: scannerData.scanner_id,
                        scanner_name: scannerData.scanner_name,
                        contract_manufacturer_id: scannerData.contract_manufacturer_id,
                        product_id: scannerData.product_id,
                        scanner_key: scannerData.scanner_key,
                        expire_date: scannerData.expire_date,
                        charges: scannerData.charges
                    };
                });
                setProductionScannerInfo(scanners)
                try {
                sessionStorage.setItem("scanners_for_product" + String(product_id), JSON.stringify(scanners));
                }
                catch(error){
                    console.log("Browser Cache out of Space")
                  }
            }
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postRegisterProductionScanner(product_api, scannerData) {
    //console.log(selectedManufacturers)
    try {
        fetch(product_api + "/product_scanners/register_scanner", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
            body: JSON.stringify({
                "scanner_name": scannerData.scanner_name,
                "contract_manufacturer_id": parseInt(scannerData.contract_manufacturer_id, 16),
                "product_id": scannerData.product_id,
                "scanner_key": scannerData.scanner_key,
                "expire_date": 0
            }),
        });
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postGenerateScannerKey(product_api, scannerData, spoq_producer_id) {
    try {
        fetch(product_api + "/product_scanners/assign_scanner_key?spoq_producer_id=" + parseInt(spoq_producer_id, 16), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
            body: JSON.stringify({
                "id": scannerData.id,
                "scanner_name": scannerData.scanner_name,
                "contract_manufacturer_id": parseInt(scannerData.contract_manufacturer_id, 16),
                "product_id": scannerData.product_id,
                "scanner_key": scannerData.scanner_key,
                "expire_date": scannerData.expire_date
            }),
        });
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

export {
    fetchProductionScannersForProduct,
    postRegisterProductionScanner,
    postGenerateScannerKey
}