import { CACHE_KEY_PRODUCTS } from "../config";
import { fetchChargesForProduct, fetchSpoqProductIdsForCharge } from "./ChargeInfo";
function getCachedAllProducts() {
    const cachedData = sessionStorage.getItem(CACHE_KEY_PRODUCTS);
    return JSON.parse(cachedData);
}

async function fetchAllProducts(product_api_url, setProductInfo, setProductWarnings, setSaleRegions) {
    if (product_api_url === "") {
        return
    }
    const currentTime = Date.now();
    // get all products from the database
    try {
        const response = await fetch(product_api_url + "/product/get_all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
        });
        // process response
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
            return null;
        } else if (!response.headers.get("content-type").includes("application/json")) {
            //console.log("Browser Cache is saying no")
            var data = getCachedAllProducts();
            if (data) {
                setProductInfo(data.productInfo)
                setProductWarnings(data.warnings)
                setSaleRegions(data.sale_regions)
            }
        }
        else {
            const data = await response.json()
            //console.log(data);
            // handle result
            if (data.products.length === 0) {
                //console.log("No products found");
                //return;
            }
            var newProductInfo = [];
            var newWarnings = [];
            var newSaleRegions = [];
            for (var i = 0; i < data.products.length; i++) {
                newProductInfo.push(data.products[i]);
            }

            for (var j = 0; j < data.warnings.length; j++) {
                newWarnings.push(data.warnings[j]);
            }

            for (var k = 0; k < data.sale_regions.length; k++) {
                newSaleRegions.push(data.sale_regions[k]);
            }
            setProductInfo(newProductInfo);
            setProductWarnings(newWarnings);
            setSaleRegions(newSaleRegions);
            // Cache the fetched data
            const cachedInfo = {
                timestamp: currentTime,
                productInfo: newProductInfo,
                warnings: newWarnings,
                sale_regions: newSaleRegions
            };
            try {
            sessionStorage.setItem(CACHE_KEY_PRODUCTS, JSON.stringify(cachedInfo));
            }     
            catch(error){
                console.log("Browser Cache out of Space")
              }
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchAllProductsForManufacturer(product_api_url, manufacturerId, setProductInfo, setProductWarnings, setSaleRegions) {
    if (product_api_url === "") {
        return
    }
    try {
        const response = await fetch(product_api_url + "/product/get_products_for_manufacturer?manufacturerID=" + parseInt(manufacturerId, 16), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        // process response
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
            return null;
        }
        else {
            const data = await response.json();
            setProductInfo(data.products)
            setProductWarnings(data.warnings)
            setSaleRegions(data.sale_regions)
            return (data.products)
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postUpdateProduct(product_api_url, editedProductInfo, warnings, assignedManufacturers, sale_region_list) {
    const response = await fetch(product_api_url + "/product/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(
            {
                "product": {
                    product_id: editedProductInfo.product_id,
                    product_name: editedProductInfo.product_name,
                    product_type: editedProductInfo.product_type,
                    product_number: editedProductInfo.product_number,
                    description: editedProductInfo.description,
                    link_info: editedProductInfo.link_info,
                    link_info_recycle: editedProductInfo.link_info_recycle,
                    product_image: editedProductInfo.product_image
                },
                "warnings": {
                    product_id: editedProductInfo.product_id,
                    warnings: warnings
                },
                "contract_manufacturer_list": {
                    contract_manufacturer_list: assignedManufacturers.map((manufacturer) => manufacturer.spoq_producer_id),
                    product_id: editedProductInfo.product_id,
                },
                "sale_region_list": {
                    sale_region_list: sale_region_list,
                    product_id: editedProductInfo.product_id,
                }
            }
        ),
    })
    if (response.status !== 200) {
        // error
        const data = await response.json()
        //console.log(data);
        alert("Error: " + response.status + " " + data.message);
    }
}
async function postRegisterProduct(product_api_url, productInfoData, warningData, contractManfuacturersData, salesRegionData) {
    // send data to api
    try {
        const response = await fetch(product_api_url + "/product/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                product: productInfoData,
                warnings: warningData,
                contract_manufacturer_list: contractManfuacturersData,
                sale_region_list: salesRegionData
            }),
        });
        if (response.status !== 200) {
            // error
            const data = await response.json()
            //console.log(data);
            alert("Error: " + response.status + " " + data.message);
        } else {
            // success
            const data = await response.json()
            //console.log(data);
            alert("Success: " + data.message);
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchSaleRegions(product_api_url, productID, setSaleRegions) {
    // get all products from the database
    try {
        const response = await fetch(product_api_url + "/product/get_all_sale_regions?product_id=" + productID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"), // not sure if this is the right token?
            },
        });
        // process response
        if (response.status !== 200) {
            //console.log("Response status: " + response.status);
        } else if (!response.headers.get("content-type").includes("application/json")) {
            //console.log("Browser Cache is saying no")
            const cachedData = sessionStorage.getItem("cached_sale_regions");
            var data = JSON.parse(cachedData);
            //console.log(data)
            if (data) {
                setSaleRegions(data.sale_regions)
            }
        }
        else {
            const data = await response.json()
            var newSaleRegions = data.sale_regions;
            // Cache the fetched data
            const cachedInfo = {
                sale_regions: newSaleRegions,
            };
            //console.log(cachedInfo)
            try {
            sessionStorage.setItem("cached_sale_regions", JSON.stringify(cachedInfo));
            }
            catch(error){
                console.log("Browser Cache out of Space")
              }
            setSaleRegions(newSaleRegions);
        }
    }
    catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postSaleRegion(product_api_url, sale_region, productID) {
    // send data to api
    try {
        const response = await fetch(product_api_url + "/product/add_sale_region", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                product_id: productID,
                sale_region: sale_region,
                comment: ""
            }),
        });
        if (response.status !== 200) {
            // error
            const data = await response.json()
            //console.log(data);
            alert("Error: " + response.status + " " + data.message);
        } else {
            // success
            const data = await response.json()
            //console.log(data);
            alert("Success: " + data.message);
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function postDeleteProduct(product_api_url, productID) {
    // send data to api
    try {
        const response = await fetch(product_api_url + "/product/delete?product_id=" + productID, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (response.status !== 200) {
            alert("Error: " + response.status + " " + response.detail);
        } else {
            // success
            const data = await response.json()
            //console.log(data);
            alert("Success: " + data.message);
        }
    } catch (error) {
        alert("Error: " + error);
        console.log(error);
    }
}

async function fetchProductStatisticData(
    product_api_url,
    products,
    setProductToIdentitysData,
    setProductToChargesData,) {
    var productData = [];
    for (var product of products) {
        var number_charges = 0
        var number_completed_charges = 0
        var number_finished_identitys = 0
        var number_unfinished_identitys= 0
        var charges_for_product = await fetchChargesForProduct(product_api_url, product.product_id, () => { });
        console.log("ChargesforProduct", charges_for_product)
        for (var charge of charges_for_product) {
            number_charges = number_charges + 1;
            var chargeData = await fetchSpoqProductIdsForCharge(product_api_url, product.product_id, charge.charge_id, () => { }, () => { })
            number_finished_identitys = number_finished_identitys + chargeData.spoqProducIds.length - chargeData.unfinishedSpoqProductIds.length
            number_unfinished_identitys = number_unfinished_identitys + chargeData.unfinishedSpoqProductIds.length 
            if (chargeData.unfinishedSpoqProductIds.length === 0) {
                number_completed_charges = number_completed_charges + 1;
            }
        }
        productData.push({
            product_name: product.product_name,
            number_finished_identitys: number_finished_identitys,
            number_unfinished_identitys: number_unfinished_identitys,
            number_completed_charges: number_completed_charges,
            number_charges: number_charges
        })
    }
    setProductToIdentitysData(
        {
            labels: productData.map((product) => product.product_name),
            datasets: [{
                label:"Completed Identitys",
                data: productData.map((product) => product.number_finished_identitys),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
            {
                label:"Uncompleted Identitys",
                data: productData.map((product) => product.number_unfinished_identitys),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            }]
        });
        setProductToChargesData(
            {
                labels: productData.map((product) => product.product_name),
                datasets: [{
                    label:"Uncompleted Charges",
                    data: productData.map((product) => product.number_charges),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
                {
                    label:"Completed Charges",
                    data: productData.map((product) => product.number_completed_charges),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }]
            }
        )
}




export {
    fetchAllProducts,
    fetchAllProductsForManufacturer,
    fetchSaleRegions,
    postUpdateProduct,
    postRegisterProduct,
    postSaleRegion,
    postDeleteProduct,
    fetchProductStatisticData
}