import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import "../../css/pages/Charges.css";
import "../../css/components/ProducerProductsBar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";
import { fetchAssignedManufacturers } from "../../api_functions/ManufacturerInfo";
import { fetchAllProducts, fetchAllProductsForManufacturer } from "../../api_functions/ProductInfo";
import { fetchChargesForManufacturer, fetchChargesForProduct } from "../../api_functions/ChargeInfo";
import { fetchCompanyInfo } from "../../api_functions/AccountInfo";
import AddCharge from "./AddCharge";
import ChargeTile from "./ChargeTile";
import ProducerProductBar from "../../Components/ProducerProductsBar";
import ProductTile from "../../Components/ProductTile";
import Header from "../../Components/Header";
import LoadingIndicator from "../../Components/LoadingIndicator";

const Charges = ({ spoqProducerData, spoqId, foreign_product_api_urls, spoqUserData, isMobile }) => {
  const { t } = useTranslation();
  const [showAddChargeDialog, setShowAddChargeDialog] = useState(false);
  const [assignedManufacturers, setAssignedmanufacturers] = useState([]);
  const [chargeManufacturers, setChargeManufacturers] = useState([]);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [charges, setCharges] = useState(null);
  const [contractProducers, setContractProducers] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState(null);


  const [manufacturersFilter, setManufacturersFilter] = useState([]);
  const [filteredCharges, setFilteredCharges] = useState(null);


  const [searchText, setSearchtext] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [chargesPerPage] = useState(5); // Number of charges per page

  const indexOfLastCharge = currentPage * chargesPerPage;
  const indexOfFirstCharge = indexOfLastCharge - chargesPerPage;
  const [currentCharges, setCurrentCharges] = useState(filteredCharges ? filteredCharges.slice(indexOfFirstCharge, indexOfLastCharge) : null);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const filterCharges = () => {
    var prefilteredCharges = charges;
    if (searchText === "") {
      prefilteredCharges = charges;
      setCurrentPage(1);
    }
    else {
      prefilteredCharges = charges.filter(charge => {
        return charge.charge_number.includes(searchText);
      });
      setCurrentPage(1);
    }
    if (manufacturersFilter.length !== 0) {
      prefilteredCharges = prefilteredCharges.filter(charge => {
        return manufacturersFilter.some(manu => manu.spoq_producer_id === charge.contract_manufacturer_id.toString(16));
      });
      setCurrentPage(1);
    }
    setFilteredCharges(prefilteredCharges);
  }

  useEffect(() => {
    //console.log("Initial UseEffect")
    if (spoqProducerData) {
      if (spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "") {
        fetchAllProducts(spoqProducerData.product_api_url, setProducts, () => { }, () => { })
      }
      if (spoqProducerData.contract_manufacturer_boolean) {
        for (const foreign_data of foreign_product_api_urls) {
          //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
          fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (spoqProducerData) {
      if (spoqProducerData.contract_manufacturer_boolean) {
        for (const foreign_data of foreign_product_api_urls) {
          //console.log("Fetching Cmpany Info with foreign Data:", foreign_data);
          fetchCompanyInfo(intToHexString(foreign_data["spoq_producer_id"], 7), addForeignProducerInfo);
        }
      }
    }
  }, [spoqProducerData, foreign_product_api_urls])
  useEffect(() => {
    //console.log("UseEffect selectedProducer")
    setProducts([]);
    setCharges([]);
    if (selectedProducer && spoqProducerData) {
      if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
        fetchAllProducts(spoqProducerData.product_api_url, setProducts, () => { }, () => { });
      }
      else if (selectedProducer && foreign_product_api_urls.length > 0 && typeof selectedProducer !== 'undefined') {
        //console.log(contractProducers)
        var product_apiUrl_id_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id);
        if (selectedProducer.product_api_url !== "" && product_apiUrl_id_pair[0]) {
          fetchAllProductsForManufacturer(product_apiUrl_id_pair[0].product_api_url, spoqProducerData.spoq_producer_id, setProducts, () => { }, () => { });
        }
      }
    }
  }, [selectedProducer]);

  useEffect(() => {
    if (spoqProducerData.producer_boolean) {
      setSelectedProducer(
        {
          "spoq_producer_id": spoqProducerData.spoq_producer_id,
          "company_name": spoqProducerData.spoq_producer_name,
          "contact_person_name": spoqProducerData.contact_person_name,
          "contact_email": spoqProducerData.contact_email,
          "company_logo": spoqProducerData.company_logo
        }
      )
    }
    else if (contractProducers.length > 0) {
      setSelectedProducer(contractProducers[0]);
    }
  }, [contractProducers])
  useEffect(() => {
    //console.log("useEffect: selectedProduct", selectedProduct)
    setCharges([]);
    if (selectedProduct && selectedProducer) {
      //console.log(selectedProduct);
      if (spoqProducerData.producer_boolean && selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
        //console.log("Fetching Charges For Produc of own company")
        fetchAssignedManufacturers(spoqProducerData.product_api_url, selectedProduct.product_id, (product_id, updatedAssignedManufacturers) => { setAssignedmanufacturers(updatedAssignedManufacturers); });
        fetchChargesForProduct(spoqProducerData.product_api_url, selectedProduct.product_id, setCharges);
      }
      if (selectedProducer && spoqProducerData.contract_manufacturer_boolean) {
        //console.log("Contract Producers", contractProducers)
        //console.log("Fetching Charges for foreign Product")
        var contract_producer_id = selectedProducer.spoq_producer_id;
        var id_api_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === contract_producer_id);
        //console.log("ID API Pair:", id_api_pair);
        if (id_api_pair[0]) {
          var product_api_url = id_api_pair[0].product_api_url;
          //console.log(selectedProduct)
          //console.log("With api Url:", product_api_url)
          fetchChargesForManufacturer(
            product_api_url,
            selectedProduct.product_id,
            spoqProducerData.spoq_producer_id,
            setCharges);
        }
      }
    }
  }, [selectedProduct]);



  useEffect(() => {
    setChargeManufacturers([]);
    setManufacturersFilter([]);
    if (charges) {
      filterCharges(searchText);
      var chargeManufacturersIds = charges.map(charge => charge.contract_manufacturer_id);
      chargeManufacturersIds = Array.from(new Set(chargeManufacturersIds));
      console.log("ChargemanufacturerIds:", chargeManufacturersIds)

      // Array to hold all fetch promises
      const fetchPromises = chargeManufacturersIds.map(chargeManufacturersId => {
        return new Promise(resolve => {
          fetchCompanyInfo(chargeManufacturersId.toString(16), (newManufacturer) => {
            console.log("Retrieved:", newManufacturer, "With ID:", chargeManufacturersId);
            resolve(newManufacturer);
          });
        });
      });

      // Wait for all fetch promises to resolve
      Promise.all(fetchPromises).then(newManufacturers => {
        // Filter out duplicates and update the state
        const uniqueManufacturers = newManufacturers.filter((manufacturer, index, self) =>
          index === self.findIndex(m => m.spoq_producer_id === manufacturer.spoq_producer_id)
        );
        setChargeManufacturers(prevManufacturers => [...prevManufacturers, ...uniqueManufacturers]);
      });
    }
  }, [charges]);
  useEffect(() => {
    if (charges) {
      const indexOfLastCharge = currentPage * chargesPerPage;
      const indexOfFirstCharge = indexOfLastCharge - chargesPerPage;
      const slicedCharges = filteredCharges.slice(indexOfFirstCharge, indexOfLastCharge);
      setCurrentCharges(slicedCharges);
    }
  }, [filteredCharges, currentPage, chargesPerPage]);

  useEffect(() => {
    console.log("ChargeManufacturers", chargeManufacturers);
  }, [chargeManufacturers]);

  useEffect(() => {
    console.log("ManufacturerFilter:", manufacturersFilter)
    filterCharges();
  }, [manufacturersFilter]);

  useEffect(() => {
    filterCharges();
  }, [searchText])

  function getContractManufacturerDataFromId(contractManufacturer_id) {
    if (typeof contractManufacturer_id === 'undefined' || contractManufacturer_id === null) {
      //console.log("Searching for manufacturer with undefined ID")
      return { spoq_producer_id: 'Not Found', verified: false, spoq_producer_name: 'Not Found', contact_person_name: 'Not Found', contact_email: 'Not Found' }
    }
    //console.log("Searching for Manufacturer with:", contractManufacturer_id)
    //console.log("in", assignedManufacturers)
    var hex_id = intToHexString(contractManufacturer_id, 7);
    //console.log(assignedManufacturers)
    //console.log("Searching with:", hex_id)
    var assignedManufacturer = assignedManufacturers.filter(
      (manufacturer) =>
        manufacturer.spoq_producer_id === hex_id
    );
    //console.log("Manufacturer to display:", assignedManufacturer);
    if (assignedManufacturer.length > 0) {
      return assignedManufacturer[0]
    } else {
      //console.log("Manufacturer not found in assigned Manufacturers")
      return { spoq_producer_id: 'Not Found', verified: false, spoq_producer_name: 'Not Found', contact_person_name: 'Not Found', contact_email: 'Not Found' }
    }
  }
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function addForeignProducerInfo(foreignProducerInfo) {
    //console.log("foreignProducerInfo", foreignProducerInfo);
    // Check if the foreignProducerInfo is not already in the array
    if (!contractProducers.some((producer) => producer.company_name === foreignProducerInfo.company_name)) {
      // Add the foreignProducerInfo to the state while removing duplicates
      setContractProducers((prevProducers) => [
        ...prevProducers.filter((producer) => producer.company_name !== foreignProducerInfo.company_name),
        foreignProducerInfo,
      ]);
    }
  }
  function intToHexString(num, length) {
    // Convert the integer to a hexadecimal string without leading "0x".
    let hexString = num.toString(16);

    // Pad the string with leading zeros to ensure a fixed length of 5.
    while (hexString.length < length) {
      hexString = '0' + hexString;
    }

    return hexString;
  }

  return (
    <div style={isMobile ? { fontSize: "11px" } : {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />
      <div className="producer-product-content-row">
        <ProducerProductBar
          productsData={products}
          producerData={spoqProducerData.producer_boolean ? [{
            "spoq_producer_id": spoqProducerData.spoq_producer_id,
            "company_name": spoqProducerData.spoq_producer_name,
            "contact_person_name": spoqProducerData.contact_person_name,
            "contact_email": spoqProducerData.contact_email,
            "company_logo": spoqProducerData.company_logo
          }, ...contractProducers] : contractProducers}
          onProducerSelected={(selectedProducer) => {
            setSelectedProducer(selectedProducer);
          }
          }
          onProductSelected={(selectedProduct) => {
            setSelectedProduct(selectedProduct);
          }}
          selectedProducer={selectedProducer}
          isMobile={isMobile}
        />
        <div className="charges-page" style={isMobile ? {minHeight:'85vh'} : {minHeight:'70vh'}}>
          <div className="charges-page-content">
            <div>
              <center>
                {isMobile ? <h3>{t("account.charges")}</h3> : <h1>{t("account.charges")}</h1>}
              </center>
              {selectedProduct &&
                <center>
                  <ProductTile
                    productData={selectedProduct}
                    isMobile={isMobile}
                  />
                </center>
              }
            </div>



            <div className="w3-center">

              {selectedProducer && selectedProduct && spoqProducerData && products &&
                <div>
                  <div>
                    {selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqUserData.role <= 2 &&
                      <div>
                        {isMobile ? <h4>{t("account.charges.producer_view")}</h4> : <h2>{t("account.charges.producer_view")}</h2>}
                      </div>}
                    {((selectedProducer.spoq_producer_id !== spoqProducerData.spoq_producer_id) || (spoqUserData.role === 3 && selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && spoqProducerData.contract_manufacturer_boolean)) &&
                      <div>
                        {isMobile ? <h4>{t("account.charges.manufacturer_view")}</h4> : <h2>{t("account.charges.manufacturer_view")}</h2>}
                      </div>}
                    <div className="standard-row" style={{ justifyContent: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", }}>
                        <input
                          type="text"
                          value={searchText}
                          className="w3-input w3-border w3-center w3-margin-top"
                          placeholder="Filter your charges by chargenumber"
                          style={{ maxWidth: "400px", display: "inline-block" }}
                          onChange={(e) => {
                            setSearchtext(e.target.value);
                          }}
                        />
                        <FontAwesomeIcon icon={faSearch} style={{ marginLeft: '10px' }} />
                      </div>
                      {selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", marginLeft: '10px' }}>
                        <Select
                          style={{ width: '20%', maxWidth: "max-content", minWidth: 'max-content' }}
                          placeholder="Filter by Manufacturer"
                          options={chargeManufacturers}
                          labelField="company_name"
                          valueField="spoq_producer_id"
                          onChange={(value) => {
                            console.log("Select Value:", value);
                            setManufacturersFilter(value);
                          }}
                          multi={true} />
                        <FontAwesomeIcon icon={faFilter} style={{ marginLeft: '10px' }} />
                      </div>}
                      {selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id && selectedProduct && spoqUserData.role <= 2 &&
                        <div className="charge-select-add">
                          <div className="charge-add">
                            <button
                              type="button"
                              className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                              onClick={() => { setShowAddChargeDialog(true) }}
                              disabled={spoqProducerData.product_api_url === "" || !selectedProduct}
                              style={isMobile ? { fontSize: "11px" } : {}}
                            >
                              {t("account.add_charge")}
                            </button>
                          </div>
                        </div>}
                    </div>
                    {currentCharges ? currentCharges.length > 0 ?
                      <div className="charges-container">
                        {currentCharges.map((charge, index) =>
                          <ChargeTile
                            key={'Charge-Tile' + index}
                            spoq_producer_id={selectedProducer.spoq_producer_id}
                            product_api_url={spoqProducerData.spoq_producer_id === selectedProducer.spoq_producer_id ?
                              spoqProducerData.product_api_url :
                              foreign_product_api_urls.filter(
                                (api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === selectedProducer.spoq_producer_id)[0].product_api_url}
                            chargeData={charge}
                            contractManufacturerName={getContractManufacturerDataFromId(charge.contract_manufacturer_id).spoq_producer_name}
                            isManufacturerView={selectedProducer.spoq_producer_id !== spoqProducerData.spoq_producer_id ||
                              (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id &&
                                spoqProducerData.contract_manufacturer_boolean &&
                                charge.contract_manufacturer_id === parseInt(spoqProducerData.spoq_producer_id, 16))}
                            isProducerView={selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id}
                            productData={selectedProduct}
                            onChargeInfoChanged={async () => {
                              await delay(400);
                              if (selectedProducer.spoq_producer_id === spoqProducerData.spoq_producer_id) {
                                fetchChargesForProduct(spoqProducerData.product_api_url, selectedProduct.product_id, setCharges);
                              } else if (selectedProducer && spoqProducerData.contract_manufacturer_boolean) {
                                //console.log("Contract Producers", contractProducers)
                                //console.log("Fetching Charges for foreign Product")
                                var contract_producer_id = selectedProducer.spoq_producer_id;
                                var id_api_pair = foreign_product_api_urls.filter((api_id_pair) => intToHexString(api_id_pair.spoq_producer_id, 7) === contract_producer_id);
                                //console.log("ID API Pair:", id_api_pair);
                                if (id_api_pair[0]) {
                                  var product_api_url = id_api_pair[0].product_api_url;
                                  //console.log(selectedProduct)
                                  //console.log("With api Url:", product_api_url)
                                  fetchChargesForManufacturer(
                                    product_api_url,
                                    selectedProduct.product_id,
                                    spoqProducerData.spoq_producer_id,
                                    setCharges);
                                }
                              }
                            }
                            }
                            spoqUserData={spoqUserData}
                            isMobile={isMobile} />)}
                      </div> :
                      <center>{t("account.charges.no_charges_found")}</center> :
                      <LoadingIndicator></LoadingIndicator>}
                  </div>
                  {spoqProducerData.product_api_url === "" &&
                    <div className="w3-center w3-margin w3-padding-64">
                      <p id="info_text"></p>
                    </div>}
                </div>}

            </div>
            {filteredCharges ?
              <center className="pagination" style={{ marginBottom: '15px' }}>
                {Array.from({ length: Math.ceil(filteredCharges.length / chargesPerPage) }).map((_, index) => (
                  <button key={index} onClick={() => paginate(index + 1)} style={currentPage === index + 1 ? { color: "blue" } : {}}>
                    {index + 1}
                  </button>
                ))}</center> :
              <></>}
          </div>
        </div>
      </div>
      <Footer />
      {selectedProducer && selectedProduct && spoqProducerData &&
        <AddCharge
          show={showAddChargeDialog}
          onAdded={async () => {
            //console.log("onAdded");
            setShowAddChargeDialog(false);
            await delay(400);
            fetchChargesForProduct(spoqProducerData.product_api_url, selectedProduct.product_id, setCharges);
            //console.log("Charges : ", charges)
          }}
          product_api_url={spoqProducerData.product_api_url}
          onClose={() => { setShowAddChargeDialog(false) }}
          productInfo={selectedProduct}
          assignedManufacturers={assignedManufacturers}
          isMobile={isMobile} />}
    </div>
  );
};

export default Charges;
