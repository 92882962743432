// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-tile-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
}

.product-tile-mobile {
    height: 100%;
    width: 100%;
    max-width: 70vw;
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: 10vh;
    background-color: #334191;
    color: #fff;
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/css/components/ProductTile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,4BAAuB;IAAvB,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,6BAA6B;IAC7B,eAAe;AACnB","sourcesContent":[".product-tile-desktop {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    padding: 20px;\r\n}\r\n\r\n.product-tile-mobile {\r\n    height: 100%;\r\n    width: 100%;\r\n    max-width: 70vw;\r\n    max-height: fit-content;\r\n    min-height: 10vh;\r\n    background-color: #334191;\r\n    color: #fff;\r\n    display: flex;\r\n    flex-direction:row;\r\n    align-items:center;\r\n    justify-content: space-around;\r\n    font-size: 11px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
