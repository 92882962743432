// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charges-page{
    display: flex;
    justify-content:center;
    width: 100%;
}

.charges-page-content {
    display: flex;
    justify-content:flex-start;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
}
.charge-select-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.charge-select {
    margin: 20px;
    width: 50%;
}

.charges-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/css/pages/Charges.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".charges-page{\r\n    display: flex;\r\n    justify-content:center;\r\n    width: 100%;\r\n}\r\n\r\n.charges-page-content {\r\n    display: flex;\r\n    justify-content:flex-start;\r\n    flex-direction: column;\r\n    box-sizing: border-box;\r\n    overflow-y: auto;\r\n}\r\n.charge-select-add {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 10px;\r\n}\r\n\r\n.charge-select {\r\n    margin: 20px;\r\n    width: 50%;\r\n}\r\n\r\n.charges-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
