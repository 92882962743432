// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-select-container{
    align-self: center;
    align-items: center;
    max-width: 400px;
}
.role-select-table{
    background-color: transparent
}
.translucent-td:nth-child(1) {
    background-color: inherit;
    color: black;
}
.role-checkbox{
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/pages/RegisterCompany.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI;AACJ;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":[".role-select-container{\r\n    align-self: center;\r\n    align-items: center;\r\n    max-width: 400px;\r\n}\r\n.role-select-table{\r\n    background-color: transparent\r\n}\r\n.translucent-td:nth-child(1) {\r\n    background-color: inherit;\r\n    color: black;\r\n}\r\n.role-checkbox{\r\n    margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
