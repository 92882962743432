// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.account-table {
    align-self: center;
    align-items: center;
    border-collapse: collapse;
    border-spacing: 0;
    width: 80%;
    display: table;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: 10px;
  }
    table.account-table td {
    word-wrap: break-word;
    display:inline-flexbox;
}
  table.account-table th,
  table.account-table td:first-child {
    width: 35%;
  }

  .role-checkbox{
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/pages/AccountLanding.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,UAAU;IACV,cAAc;IACd,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;EACd;IACE;IACA,qBAAqB;IACrB,sBAAsB;AAC1B;EACE;;IAEE,UAAU;EACZ;;EAEA;IACE,YAAY;AAChB","sourcesContent":["table.account-table {\r\n    align-self: center;\r\n    align-items: center;\r\n    border-collapse: collapse;\r\n    border-spacing: 0;\r\n    width: 80%;\r\n    display: table;\r\n    border: 1px solid #ccc;\r\n    background-color: #fff;\r\n    margin: 10px;\r\n  }\r\n    table.account-table td {\r\n    word-wrap: break-word;\r\n    display:inline-flexbox;\r\n}\r\n  table.account-table th,\r\n  table.account-table td:first-child {\r\n    width: 35%;\r\n  }\r\n\r\n  .role-checkbox{\r\n    margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
