import React  from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/components/Dialog.css"
import { useTranslation } from "react-i18next";
import { postDynamicAPISetupForCompany } from "../../api_functions/AccountInfo";

function SetUpAPIDialog({ show, onClose, spoq_producer_id, onAccountInfoChanged, setIsAPISetupRunning, onConfirmed, isMobile}) {
    const { t } = useTranslation();
    if (!show) return null;
    async function handleSetUpAPIConfirmed() {
        postDynamicAPISetupForCompany(spoq_producer_id, onAccountInfoChanged, setIsAPISetupRunning);
        onAccountInfoChanged();
    }
    return (
        <div className="edit-product-dialog">
            <div className="edit-product-dialog-content">
                {isMobile ? <h4>{t("account.home.setup_api")}</h4> : <h2>{t("account.home.setup_api")}</h2>}
                {isMobile ? <h5> {t("account.home.setup_api_message")}</h5> : <h3> {t("account.home.setup_api_message")}</h3>}
                <h3>{}</h3>
                <div className="fixed-buttons-dialog">
                    <button className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding" onClick={()=> onClose()}>{t("button.cancel")}</button>
                    <button className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding" onClick={() => {
                        handleSetUpAPIConfirmed();
                        onConfirmed();
                        onClose();
                    }}>{t("button.confirm")}</button>
                </div>
            </div>
        </div>
    );
};

export default SetUpAPIDialog;
