import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { postForgotPassword, postForgotPasswordReset, postForgotPasswordVerify } from "../../api_functions/AccountInfo";

// migration from index.html
const ForgotPassword = ({spoqProducerData, spoqUserData, isMobile}) => {
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]|;:'",.<>/?]).{8,}$/;
  const inputs = ["input1", "input2", "input3", "input4", "input5", "input6"];
  const { t } = useTranslation();
  const [spoq_producer_id, setSpoqProducerId] = useState("");
  const [email_verified, setEmailVerified] = useState(false);
  const [email_known, setEmailKnown] = useState(false);
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState(""); // Add state for error text
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  useEffect(() => {
    if (email_known && !email_verified) {
      initializeInputs();
    }
  }, [email_known, email_verified]);
  async function updatePassword() {
    if (!passwordRegex.test(password)) {
      setErrorText(t("register.password_unsafe"));
    }
    if (password !== passwordRepeat) {
      setErrorText("Passwords are not equal");
    } else {
      const userData = {
        email: email,
        spoq_producer_id: spoq_producer_id,
        password: password,
        token: localStorage.getItem("token"),
      };
      postForgotPasswordReset(userData, setErrorText);
    }
  }

  async function generateNewOTPToken() {
    const userData = {
      email: email,
      spoq_producer_id: spoq_producer_id,
      password: "",
      token: "",
    };
    postForgotPassword(userData, setErrorText);
  }
  async function checkMailAvailable() {
    await generateNewOTPToken();
    setEmailKnown(true);
  }

  function addListener(input) {
    input.addEventListener("keyup", (event) => {
      const n = input.nextElementSibling;
      if (n) n.focus();
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        const prev = input.previousElementSibling;
        if (prev) prev.focus();
      }
    });
  }

  async function verify_otp() {
    const input1 = document.getElementById("input1");
    const input2 = document.getElementById("input2");
    const input3 = document.getElementById("input3");
    const input4 = document.getElementById("input4");
    const input5 = document.getElementById("input5");
    const input6 = document.getElementById("input6");
    const otp_token =
      input1.value +
      input2.value +
      input3.value +
      input4.value +
      input5.value +
      input6.value;
    const userData = {
      email: email,
      spoq_producer_id: spoq_producer_id,
      password: "",
      token: otp_token,
    };
    postForgotPasswordVerify(userData,setEmailVerified, setErrorText);
  }
  function initializeInputs() {
    inputs.map((id) => {
      const input = document.getElementById(id);
      addListener(input);
    });
  }
  return (
    <div style={isMobile ? {fontSize:"11px"} : {}}>
      <Header 
      spoqProducerData={spoqProducerData} 
      isMobile={isMobile}
      showBanner={false}
      spoqUserData={spoqUserData}/>
      {/* Infos zur Anmeldung */}
      <div className="w3-container w3-center" id="info">
        <div className="w3-content ">
          <p>{t("login.text")}</p>
        </div>
      </div>
      {email_known && !email_verified && (
        <section>
          <div className="w3-center">
            <FontAwesomeIcon icon={faEnvelope} size="4x" />
            {isMobile ? <h4>{t("account.forgot_password.email_verification")}</h4> : <h2>{t("account.forgot_password.email_verification")}</h2>}
            <p>{t("account.forgot_password.email_send")}</p>
          </div>
          <div id="inputs" className="w3-center w3-padding-64">
            <input
              id="input1"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
            <input
              id="input2"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
            <input
              id="input3"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
            <input
              id="input4"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
            <input
              id="input5"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
            <input
              id="input6"
              className="otp_input w3-border"
              type="text"
              maxLength="1"
            />
          </div>
          {errorText && <p>{errorText}</p>}{" "}
          {/* Display error text if available */}
          <div className="w3-center w3-padding-32">
            <button onClick={verify_otp} className="w3-button w3-theme-d5 w3-round-large">
              {t("login.twofa.submit")}
            </button>
          </div>
        </section>
      )}
      {email_verified && (
        <div className="w3-padding-64">
        <div className="w3-center w3-margin">
        <label>{t("login.password")}</label>
          <div>
          <input
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w3-margin w3-border w3-input"
                style={{ maxWidth: "400px", display: " inline-block" }}
          ></input>
          </div>
        </div>
        <div className="w3-center w3-margin">
          <label>{t("register.confirm_password")}</label>
          
          <div>
          <input
            type="password"
            placeholder="repeat password"
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
            className="w3-margin w3-border w3-input"
            style={{ maxWidth: "400px", display: " inline-block" }}
          ></input>
          </div>
          </div>
          <div className="w3-center">
          <button className="w3-button w3-theme-d5 w3-round-large" onClick={updatePassword}>
            {t("account.forgot_password.update_password")}
          </button>
          </div>
        </div>
      )}
      {!email_known && (
        <div className="w3-center w3-margin w3-padding-64">
          <div>
            <label>{t("account.forgot_password.spoq_id_promtpt")}</label>
            <div>
              <input
                type="text"
                placeholder="SPOQ ID"
                value={spoq_producer_id}
                onChange={(e) => setSpoqProducerId(e.target.value)}
                className="w3-margin w3-border w3-input"
                style={{ maxWidth: "400px", display: " inline-block" }}
              ></input>
            </div>
          </div>
          <div className="w3-margin">
            <label>{t("account.forgot_password.email_prompt")}</label>
            <div>
              <input
                type="text"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w3-margin w3-border w3-input"
                style={{ maxWidth: "400px", display: " inline-block" }}
              ></input>
            </div>
          </div>
          {errorText && <center><p className="error-text">{errorText}</p></center>}{" "}
          {/* Display error text if available */}
          <button
            className="w3-button w3-theme-d5 w3-round-large"
            onClick={checkMailAvailable}
          >
            {t("button.send_verification")}
          </button>
        </div>
      )}
      <Footer />
    </div>
  );
  
};

export default ForgotPassword;
