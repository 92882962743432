import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import "../../css/pages/AddAssociatedManufacturer.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchAllManufacturers, fetchAssociatedContractManufacturers } from "../../api_functions/ManufacturerInfo";
import { postCooperationRequest, postAnswerCooperationRequest, postEndCooperation, fetchCooperationRequests } from "../../api_functions/AccountInfo";
import CompanyTile from "../../Components/CompanyTile";
import CooperationRequestTile from "./CooperationRequestTile";
import Header from "../../Components/Header";

const AddAssociatedManufacturer = ({ spoqProducerData, spoqId, spoqUserData, onCooperationsChanged, isMobile }) => {
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [cooperationRequests, setCooperationRequests] = useState([]);
  const [associatedManufacturers, setAssociatedManufacturers] = useState([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState(allManufacturers);
  const [currentPage, setCurrentPage] = useState(1);
  const [manufacturersPerPage] = useState(5);
  const { t } = useTranslation();

  useEffect(() => {
    fetchAllManufacturers(setAllManufacturers,);
    fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
    fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
  }, []);
  useEffect(() => {
    fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
    fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
  }, [spoqProducerData]);
  useEffect(() => {
    setFilteredManufacturers(allManufacturers);
    filterManufacturers();
  }, [allManufacturers])

  useEffect(() => {
    filterManufacturers();
  }, [searchText])

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function filterManufacturers() {
    var filtered = allManufacturers.filter((manufacturer) => {
      return (manufacturer.spoq_producer_name.includes(searchText) ||
        manufacturer.spoq_producer_id.includes(searchText)) &&
        !cooperationRequests.some((request) => (manufacturer.spoq_producer_id === request.manufacturer_id &&
          spoqProducerData.spoq_producer_id === request.producer_id && request.acknowleged !== false))
    });
    setFilteredManufacturers(filtered);
  }

  function paginate(array, page_number, items_per_page) {
    const start_index = (page_number - 1) * items_per_page;
    const end_index = page_number * items_per_page;
    return allManufacturers && filteredManufacturers ? array.slice(start_index, end_index) : [];
  }
  const totalPages = Math.ceil(filteredManufacturers?.length / manufacturersPerPage);
  const paginatedManufacturers = paginate(filteredManufacturers, currentPage, manufacturersPerPage);

  function handlePagination(pageNumber) {
    setCurrentPage(pageNumber);
  }

  return (
    <div style={isMobile ? { fontSize: "11px" } : {}}>
      <Header
        spoqProducerData={spoqProducerData}
        isMobile={isMobile}
        showBanner={false}
        spoqUserData={spoqUserData} />

      <div className="w3-center" style={isMobile ? {minHeight:'85vh'} : {minHeight:'75vh'}}>
        {isMobile ? <h3>{t("account.company_cooperation")}</h3> : <h1>{t("account.company_cooperation")}</h1>}
        <div className="manufacturer-page">
          {isMobile ? <h4>{t("account.show_associated_manufacturers")}</h4> : <h2>{t("account.show_associated_manufacturers")}</h2>}
          <div className={isMobile ? "" : "existing-cooperatios-container"}>
            {spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "" && spoqProducerData.product_api_url &&
              <div className="cooperations-list">
                {isMobile ? <h5>{t("account.company_cooperation.manufacturers_associated")}</h5> : <h3>{t("account.company_cooperation.manufacturers_associated")}</h3>}
                {associatedManufacturers.map(manufacturer => (
                  <div key={manufacturer.spoq_producer_id} className="manufacturer-item">
                    <CompanyTile company_id={manufacturer.spoq_producer_id} isMobile={isMobile} />
                    <button
                      type="button"
                      className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                      onClick={async () => {
                        postEndCooperation(spoqProducerData.spoq_producer_id, manufacturer.spoq_producer_id)
                        onCooperationsChanged();
                        await delay(700)
                        fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
                        fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
                      }}
                      style={{ maxWidth: "100px", display: " inline-block" }}>
                      {t("button.remove")}
                    </button>
                  </div>
                ))}
              </div>}
            {spoqProducerData.contract_manufacturer_boolean &&
              <div>
                {isMobile ? <h5>{t("account.company_cooperation.producers_associated")}</h5> : <h3>{t("account.company_cooperation.producers_associated")}</h3>}
                {cooperationRequests.filter((some_request) => { return some_request.manufacturer_id === spoqProducerData.spoq_producer_id && some_request.acknowleged === true }).map(acceptedRequest => (
                  <div key={acceptedRequest.producer_id.toString() + acceptedRequest.manufacturer_id.toString()}>
                    <CompanyTile company_id={acceptedRequest.producer_id} isMobile={isMobile} />
                    <button
                      type="button"
                      className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                      onClick={async () => {
                        postEndCooperation(acceptedRequest.producer_id, acceptedRequest.manufacturer_id)
                        onCooperationsChanged();
                        await delay(700)
                        fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
                        fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
                      }}
                      style={{ maxWidth: "100px", display: " inline-block" }}>
                      {t("button.remove")}
                    </button>
                  </div>
                ))}
              </div>}
          </div>
          <div className={isMobile ? "" : "existing-cooperatios-container"}>
            {spoqProducerData.producer_boolean && spoqProducerData.product_api_url !== "" && spoqProducerData.product_api_url &&
              <div className="manufacturer-select" style={{ flex: "1" }}>
                {isMobile ? <h5>{t("account.company_cooperation.available_manufctuers")}</h5> : <h3>{t("account.company_cooperation.available_manufctuers")}</h3>}
                <div className="standard-row">
                  <input
                    type="text"
                    value={searchText}
                    className="w3-input w3-border w3-center w3-margin-top"
                    style={{ maxWidth: "400px", display: " inline-block" }}
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                  <i className={"fas fa-search"} ></i>
                </div>
                {filteredManufacturers && filteredManufacturers.length > manufacturersPerPage && (
                  <div>
                    <button onClick={() => handlePagination(1)} style={currentPage === 1 ? { color: "blue" } : {}}>1</button>
                    {/* Display two pages before and after the current page */}
                    {[currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].filter(page => page > 0 && page <= totalPages && page !== 1 && page != totalPages).map(page => (
                      <button key={page} onClick={() => handlePagination(page)} style={currentPage === page ? { color: "blue" } : {}}>{page}</button>
                    ))}
                    <button onClick={() => handlePagination(totalPages)} style={currentPage === totalPages ? { color: "blue" } : {}}>{totalPages}</button>
                  </div>
                )}
                {
                  paginatedManufacturers.map
                    (
                      searched_manufacturer => (
                        <div className="send-request-tile" key={searched_manufacturer.spoq_producer_id}>
                          <div style={{ flex: "4" }}>
                            <CompanyTile company_id={searched_manufacturer.spoq_producer_id} isMobile={isMobile} />
                          </div>
                          <button
                            className="w3-button w3-theme-d5 w3-round-large"
                            onClick={async () => {
                              postCooperationRequest(spoqProducerData.spoq_producer_id, searched_manufacturer.spoq_producer_id)
                              await delay(500);
                              fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
                            }}
                            style={{ maxWidth: "150px", display: " inline-block", marginLeft: "5px", flex: "1" }}
                            disabled={spoqUserData.role >= 3}>
                            {t("account.company_cooperation.send_request")}
                          </button>
                        </div>
                      )
                    )
                }
              </div>}
            {spoqProducerData.contract_manufacturer_boolean && <div
              className="manufacturer-select"
              style={{ flex: "1" }}>
              {isMobile ? <h5>{t("account.company_cooperation.requests")}</h5> : <h3>{t("account.company_cooperation.requests")}</h3>}
              <div>
                {isMobile ? <h6>{t("account.company_cooperation.recived_requests")}</h6> : <h4>{t("account.company_cooperation.recived_requests")}</h4>}
                {cooperationRequests.filter((some_request) => { return some_request.manufacturer_id === spoqProducerData.spoq_producer_id && some_request.acknowleged === null }).map(pendingRequest => (
                  <div key={pendingRequest.producer_id.toString() + pendingRequest.manufacturer_id.toString()}>
                    <CooperationRequestTile
                      request_data={pendingRequest}
                      isRecivedRequest={true}
                      handleAcknowledge={async (isAcknowledged) => {
                        postAnswerCooperationRequest(pendingRequest.producer_id, pendingRequest.manufacturer_id, isAcknowledged);
                        onCooperationsChanged();
                        await delay(500);
                        fetchCooperationRequests(spoqProducerData.spoq_producer_id, setCooperationRequests);
                        fetchAssociatedContractManufacturers(spoqProducerData.product_api_url, setAssociatedManufacturers);
                      }}
                      isMobile={isMobile} />
                  </div>
                ))}
              </div>
              <div>
                {isMobile ? <h6>{t("account.company_cooperation.send_requests")}</h6> : <h4>{t("account.company_cooperation.send_requests")}</h4>}
                {cooperationRequests.filter((some_request) => { return some_request.producer_id === spoqProducerData.spoq_producer_id && (some_request.acknowleged === null || some_request.acknowleged === false) }).map(pendingRequest => (
                  <div key={pendingRequest.producer_id.toString() + pendingRequest.manufacturer_id.toString()}>
                    <CooperationRequestTile
                      request_data={pendingRequest}
                      isRecivedRequest={false}
                      handleAcknowledge={() => { }}
                      isMobile={isMobile} />
                  </div>
                ))}
              </div>
            </div>}
          </div>
          <div className="w3-center">
            <div>
              <span>
                {t("account.company_cooperation.not_found_1")}
              </span>
            </div>
            <span className="psw">
              <Link to="/">
                {t("account.company_cooperation.not_found_2")}
              </Link>
            </span>
          </div>
        </div>
      </div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
      />
      <Footer />
    </div>
  );
};

export default AddAssociatedManufacturer;
