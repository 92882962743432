// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import { useTranslation } from "react-i18next";
import { postDeleteProduct } from "../../api_functions/ProductInfo";

// page containing tde base information of a company including tde option to
// edit tde information
function ConfirmationDialogDelete({ show, onClose, onDeleted, productInfo, api_url, isMobile}) {
    const { t } = useTranslation();
    async function handleDeleteConfirmed() {
        postDeleteProduct(api_url,productInfo.product_id);
        onDeleted();
    }
    if (!show) return null;
    return (
        <div className="edit-product-dialog" style={isMobile? {fontSize:"11px"} : {}}>
            <div className="edit-product-dialog-content">
                {isMobile ?  <h4>{t("account.delete_product")}</h4>:<h2>{t("account.delete_product")}</h2>}
                {isMobile ? <h5> {t("account.delete_product_warning" )}</h5> : <h3> {t("account.delete_product_warning" )}</h3>}
                <h3>{productInfo.product_name}</h3>
                <div className="fixed-buttons-dialog">
                    <button className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding" onClick={onClose}>{t("button.cancel")}</button>
                    <button className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding" onClick={() => handleDeleteConfirmed()}>{t("button.confirm")}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialogDelete;
