// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/components/Dialog.css"
import "../../css/pages/AccountLanding.css"
import { useTranslation } from "react-i18next";
import { CustomImage, imageToBase64 } from "../../Components/CustomImage";
import { fetchAllOwners, postUpdateCompanyInfo } from "../../api_functions/AccountInfo";
import Select from "react-dropdown-select";
import CountrySelect from "../../Components/CountrySelect";
function EditCompanyInfo({
    show,
    onClose,
    onEdited,
    onAccountInfoChanged,
    spoqProducerData,
    isMobile
}) {

    const { t } = useTranslation();
    const [allOwners, setAllOwners] = useState([]);
    const [editedCompanyInfo, setEditedCompanyInfo] = useState(spoqProducerData)
    useEffect(() => {
        fetchAllOwners(spoqProducerData.account_api_url, spoqProducerData.spoq_producer_id, setAllOwners);
    }, [])
    useEffect(() => {
        fetchAllOwners(spoqProducerData.account_api_url, spoqProducerData.spoq_producer_id, setAllOwners);
        setEditedCompanyInfo(spoqProducerData)
    }, [spoqProducerData])

    async function handleEditConfirmed() {
        postUpdateCompanyInfo(editedCompanyInfo, onAccountInfoChanged);
        onEdited();
    }
    function handleInputChangeCompanyInfo(value, element) {
        console.log("HandleInputchange:", value, element);
        setEditedCompanyInfo((prevSpoqProductInfo) => ({
            ...prevSpoqProductInfo,
            [element]: value,
        }));
    }


    if (!show) return null;
    return (
        <div className="dialog">
            <div className= {isMobile ? "dialog-content-mobile" : "dialog-content"}>
                <center>
                    {isMobile ?  <h4>{t("account.home.change_company_info")}</h4> : <h2>{t("account.home.change_company_info")}</h2>}
                </center>
                <center>
                    <table className="account-table w3-table w3-striped w3-border w3-bordered">
                        <tbody>
                            <tr>
                                <td>{t("login.producer_id")}</td>
                                <td id="producer_id">
                                    <center>
                                        {editedCompanyInfo.spoq_producer_id}
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("register.name")}</td>
                                <td id="company_name">
                                    <center>
                                        <input
                                            type="text"
                                            id="input_company_name"
                                            required
                                            name="reg_name"
                                            value={editedCompanyInfo.spoq_producer_name}
                                            onChange={(e) => {
                                                handleInputChangeCompanyInfo(e.target.value, "spoq_producer_name");
                                            }}
                                            className="w3-input w3-border w3-center"
                                            style={{ maxWidth: "400px", display: " inline-block" }}
                                        />
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("account.register.role")}</td>
                                <td id="company role">
                                    <center>
                                        <div id="roleselect" className="role-select-container">
                                            <label id="reg_role">
                                                <b>
                                                    {t("register.role")}
                                                </b>
                                            </label>
                                            <center>
                                                <table>
                                                    <tbody>
                                                        <tr key={"producer_boolean"}>
                                                            <td className="translucent-td">
                                                                <label>
                                                                    <input
                                                                        className="role-checkbox"
                                                                        type="checkbox"
                                                                        checked={editedCompanyInfo.producer_boolean}
                                                                        onChange={(e) => {
                                                                            handleInputChangeCompanyInfo(!editedCompanyInfo.producer_boolean, "producer_boolean")
                                                                        }}
                                                                    />
                                                                    {t("account.roles.producer")}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <div className="hint-text">{t("account.register.role_producer_hint")}</div>
                                                            </td>
                                                        </tr>
                                                        <tr key={"contract_manufacturer_boolean"}>
                                                            <td className="translucent-td">
                                                                <label>
                                                                    <input
                                                                        className="role-checkbox"
                                                                        type="checkbox"
                                                                        checked={editedCompanyInfo.contract_manufacturer_boolean}
                                                                        onChange={(e) => {
                                                                            handleInputChangeCompanyInfo(!editedCompanyInfo.contract_manufacturer_boolean, "contract_manufacturer_boolean")
                                                                        }}
                                                                    />
                                                                    {t("account.roles.contract_manufacturer")}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <div className="hint-text">{t("account.register.role_manufacturer_hint")}</div>
                                                            </td>
                                                        </tr>
                                                        <tr key={"dealer_boolean"}>
                                                            <td className="translucent-td">
                                                                <label>
                                                                    <input
                                                                        className="role-checkbox"
                                                                        type="checkbox"
                                                                        checked={editedCompanyInfo.dealer_boolean}
                                                                        onChange={(e) => {
                                                                            handleInputChangeCompanyInfo(!editedCompanyInfo.dealer_boolean, "dealer_boolean")
                                                                        }}
                                                                    />
                                                                    {t("account.roles.dealer")}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <div className="hint-text">{t("account.register.role_dealer_hint")}</div>
                                                            </td>
                                                        </tr>
                                                        <tr key={"customer_boolean"}>
                                                            <td className="translucent-td">
                                                                <label>
                                                                    <input
                                                                        className="role-checkbox"
                                                                        type="checkbox"
                                                                        checked={editedCompanyInfo.customer_boolean}
                                                                        onChange={(e) => {
                                                                            handleInputChangeCompanyInfo(!editedCompanyInfo.customer_boolean, "customer_boolean")
                                                                        }}
                                                                    />
                                                                    {t("account.roles.customer")}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <div className="hint-text">{t("account.register.role_customer_hint")}</div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </center>
                                        </div>
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("register.contact_person_name")}</td>
                                <td id="contact_person_name">
                                    <center>
                                        <input
                                            type="text"
                                            id="input_contact_name"
                                            required
                                            name="reg_name"
                                            value={editedCompanyInfo.contact_person_name}
                                            onChange={(e) => {
                                                handleInputChangeCompanyInfo(e.target.value, "contact_person_name");
                                            }}
                                            className="w3-input w3-border w3-center"
                                            style={{ maxWidth: "400px", display: " inline-block" }}
                                        />
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("register.contact_person_email")}</td>
                                <td id="contact_person_email">
                                    <center>
                                        <Select
                                            className="w3-border"
                                            options={allOwners}
                                            labelField="email"
                                            valueField="email"
                                            style={{ maxWidth: "400px", display: "inline-block", minWidth:"" }}
                                            onChange={(value) => {
                                                handleInputChangeCompanyInfo(value[0].email, "contact_email");
                                            }}>
                                        </Select>
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("account.home.company_logo")}</td>
                                <td id="company_logo">
                                    {editedCompanyInfo.company_logo && (
                                        <div className={isMobile ? "" : "w3-padding-16"}>
                                            <center>
                                            <CustomImage data={editedCompanyInfo.company_logo}
                                                maxHeight={isMobile ? "20vh" :  "200px"}
                                                maxWidth={isMobile ? "20vw" :  "200px"}
                                            />                                                
                                            </center>

                                            <br />
                                            {spoqProducerData.producer_boolean && (
                                                <center>
                                                    <button
                                                        onClick={() => {
                                                            handleInputChangeCompanyInfo(null, "company_logo");
                                                            var input =
                                                                document.getElementById("product_image_file");
                                                            input.value = null;
                                                        }}
                                                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                                    >
                                                        {t("button.remove")}
                                                    </button>
                                                </center>

                                            )}

                                        </div>
                                    )}
                                    <center>
                                        <input
                                            type="file"
                                            name="product_image_file"
                                            id="product_image_file"
                                            className="w3-padding-16 w3-center"
                                            onChange={async (e) => {
                                                var base64Image = await imageToBase64(e.target.files[0])
                                                //console.log("Base64Image1:", base64Image)
                                                handleInputChangeCompanyInfo(
                                                    base64Image,
                                                    "company_logo"
                                                );
                                            }}
                                        />
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("register.address")}</td>
                                <td id="adress">
                                    <center>
                                        <input
                                            type="text"
                                            id="input_adress"
                                            required
                                            name="reg_name"
                                            value={editedCompanyInfo.main_address}
                                            onChange={(e) => {
                                                handleInputChangeCompanyInfo(e.target.value, "main_address");
                                            }}
                                            className="w3-input w3-border w3-center"
                                            style={{ maxWidth: "400px", display: " inline-block" }}
                                        />
                                    </center>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("register.country")}</td>
                                <td id="producer_id">
                                    <center>
                                        <select
                                            id="input_country"
                                            name="country"
                                            autoComplete="country"
                                            enterKeyHint="done"
                                            className="w3-input w3-border w3-center"
                                            style={{ maxWidth: "400px", display: " inline-block" }}
                                            onChange={(e) => {
                                                handleInputChangeCompanyInfo(e.target.value, "country")
                                            }}
                                        >
                                            <CountrySelect />
                                        </select>
                                    </center>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </center>

                <div className="fixed-buttons-dialog">
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("button.cancel")}
                    </button>
                    <button
                        className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                        onClick={() => {
                            handleEditConfirmed();
                        }}
                    >
                        {t("button.confirm")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditCompanyInfo;
