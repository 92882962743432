// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts
import "../css/basicStyle.css";
import "../css/theme.css";
import "../css/components/ProductTile.css";
import { useTranslation } from "react-i18next";
import { CustomImage } from "./CustomImage";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
function ProductTile({ productData, isMobile }) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false)
    return (
        <div style={{marginBottom:"20px"}}>
            {!isMobile && <div className="product-tile-desktop">
                <table className="product-table w3-table w3-striped w3-border w3-bordered">
                    <tbody>
                        <tr>
                            <td>{t("account.products.product_name")}</td>
                            <td>{productData.product_name}</td>
                        </tr>
                        <tr>
                            <td>{t("account.products.product_number")}</td>
                            <td>{productData.product_number}</td>
                        </tr>
                        <tr>
                            <td>{t("account.products.product_description")}</td>
                            <td>{productData.description}</td>
                        </tr>
                        <tr>
                            <td>{t("account.products.info_link")}</td>
                            <td>{productData.link_info}</td>
                        </tr>
                    </tbody>
                </table>
                <center className="manufacturer-tile-image">
                    <CustomImage
                        data={productData.product_image}
                        maxWidth={"15vw"}
                        maxHeight={"10vh"}
                    /></center>
            </div>}
            {isMobile &&
                <div>
                    <div className="product-tile-mobile"
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                        >

                        <center style={{ flex: "6", marginLeft: "5px"}}>{productData.product_name}</center>
                        <center style={{ padding: "10px", flex: "2" }} className="company-tile-image">
                            <CustomImage
                                data={productData.product_image}
                                maxWidth={"15vw"}
                                maxHeight={"10vh"}
                            />
                        </center>
                        <center style={{ flex: "1" }}>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </center>
                    </div>
                    {expanded &&
                        <div className="product-tile-mobile">
                            <table>
                                <tbody className="w3-border w3-bordered w3-table w3-striped">
                                    <tr>
                                        <td>{t("account.products.product_number")}</td>
                                        <td>{productData.product_number}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("account.products.product_description")}</td>
                                        <td>{productData.description}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("account.products.info_link")}</td>
                                        <td>{productData.link_info}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                </div>}
        </div>

    );
}

export default ProductTile;
