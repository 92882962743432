// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.producer-products-bar {
  display:flex;
  flex-direction: column;
  position: sticky;
}

.producer-button{
  width: 100%;
}

.producer-product-content-row {
    flex: 1 1;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: row;
    min-height: 75vh;
}

/* TreeView.css */
.treeview-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: 20vw;
    align-items: center; /* Set the width of the sidebar */
    background-color: #334191; /* Set the background color */
    display: flex;
    flex-direction: column;
    background-color: w3-theme-d5; /* Adjust the background color as needed */
    
  }
  
  .treeview-container ul li {
    cursor: pointer;
  }
  
  .treeview-container ul li:hover {
    background-color: #20295b; /* Adjust the hover color as needed */
  }`, "",{"version":3,"sources":["webpack://./src/css/components/ProducerProductsBar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;IACI,SAAO;IACP,WAAW;IACX,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA,iBAAiB;AACjB;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,mBAAmB,EAAE,iCAAiC;IACtD,yBAAyB,EAAE,6BAA6B;IACxD,aAAa;IACb,sBAAsB;IACtB,6BAA6B,EAAE,0CAA0C;;EAE3E;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,yBAAyB,EAAE,qCAAqC;EAClE","sourcesContent":[".producer-products-bar {\r\n  display:flex;\r\n  flex-direction: column;\r\n  position: sticky;\r\n}\r\n\r\n.producer-button{\r\n  width: 100%;\r\n}\r\n\r\n.producer-product-content-row {\r\n    flex: 1;\r\n    width: 100%;\r\n    display: flex;\r\n    height: 100%;\r\n    flex-direction: row;\r\n    min-height: 75vh;\r\n}\r\n\r\n/* TreeView.css */\r\n.treeview-container {\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n    height: 100%;\r\n    width: 20vw;\r\n    align-items: center; /* Set the width of the sidebar */\r\n    background-color: #334191; /* Set the background color */\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: w3-theme-d5; /* Adjust the background color as needed */\r\n    \r\n  }\r\n  \r\n  .treeview-container ul li {\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .treeview-container ul li:hover {\r\n    background-color: #20295b; /* Adjust the hover color as needed */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
