// displaying statistics such as
// how many producs, how many representatives, all witd pretty graphs maybe
// see: https://canvasjs.com/react-charts/animated-chart/ for pretty charts

import React, { useEffect, useState } from "react";
import "../../css/basicStyle.css";
import "../../css/theme.css";
import "../../css/pages/AddAssociatedManufacturer.css"
import { fetchCompanyInfo } from "../../api_functions/AccountInfo";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../Components/LoadingIndicator";
import CompanyTile from "../../Components/CompanyTile";
function CooperationRequestTile({ request_data, isRecivedRequest, handleAcknowledge, isMobile }) {
    const [company_data, setCompanyData] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        fetchCompanyInfo(isRecivedRequest ? request_data.producer_id : request_data.manufacturer_id, setCompanyData)
    }, [request_data])
    return (
        <div >
            {company_data ?
                <div className="request-tile" >
                    <div style={{ flex: "3" }}>
                        <CompanyTile
                            company_id={isRecivedRequest ? request_data.producer_id : request_data.manufacturer_id}
                            isMobile={isMobile}
                        />
                    </div>
                    <div style={{ flex: "1" , marginLeft:"5px"}}>
                        {isRecivedRequest ?
                            <div>
                                <button
                                    type="button"
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        handleAcknowledge(true)
                                    }}
                                    style={{ maxWidth: "100px" }}>
                                    {t("button.accept")}
                                </button>

                                <button
                                    type="button"
                                    className="w3-button w3-theme-d5 w3-margin w3-round-large w3-padding"
                                    onClick={() => {
                                        handleAcknowledge(false)
                                    }}
                                    style={{ maxWidth: "100px" }}>
                                    {t("button.decline")}
                                </button>

                            </div>
                            : request_data.acknowleged !== null ? <div>{t("account.company_cooperation.status_declined")}</div> : <div>{t("account.company_cooperation.status_pending")}</div>
                        }
                    </div>
                </div> :
                <LoadingIndicator></LoadingIndicator>
            }
        </div>
    );
}

export default CooperationRequestTile;
