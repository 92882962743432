import React from 'react';

const CustomImage = ({ data, maxHeight, maxWidth, margin}) => {
  const imageStyle = (maxWidth || maxHeight) ?
  {
    maxWidth: maxHeight ? maxHeight:`100%`,
    maxHeight: maxHeight ? maxHeight:`100%`,
    width: "auto",  // Ensure SVG fits within the specified dimensions
    height: "auto", // Maintain aspect ratio for SVG
  }:
  {
    maxWidth: `10vw`,
    maxHeight: `10vh`,
    width: "auto",  // Ensure SVG fits within the specified dimensions
    height: "auto", // Maintain aspect ratio for SVG
  };

  const isSVG = data && data.includes("<?xml version=\"1.0\" encoding=\"iso-8859-1\"?>");
  return (
    <div className= {margin ? "w3-center w3-margin w3-round-large": "w3-centerw3-round-large" }>
      {data ? isSVG ? (
        <div dangerouslySetInnerHTML={{ __html: data }} style={imageStyle} />
      ) : (
        <img src={`data:image/png;base64,${data}`} style={imageStyle} alt="Product" />
      ): <div></div>}
    </div>
  );
};

async function imageToBase64(file, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imageWidth = img.width;
        const imageHeight = img.height;
        
        // Calculate the new dimensions while maintaining the aspect ratio
        let newWidth = imageWidth;
        let newHeight = imageHeight;
        
        if (imageWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = (imageHeight / imageWidth) * newWidth;
        }
        
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (imageWidth / imageHeight) * newHeight;
        }
        
        // Set the canvas dimensions to the calculated width and height
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas to PNG data URL
        const pngDataURL = canvas.toDataURL('image/png');

        // Extract the base64 part
        const base64Image = pngDataURL.split(',')[1];

        resolve(base64Image);
      };
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export {
  CustomImage,
  imageToBase64
};