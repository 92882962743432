import React from "react";
import { Link } from "react-router-dom"; // for routing to other pages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretDown,
  faHome,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import "../css/basicStyle.css";
import "../css/theme.css";
import { HashLink } from "react-router-hash-link";
import Logo from "../images/Spoq_Globe.png";
import { useTranslation } from "react-i18next";
import {
  CACHE_KEY_ACCOUNT_INFO,
  CACHE_KEY_USER_ROLE,
  CACHE_KEY_ALL_OWNERS,
  CACHE_KEY_STATISTICS_USERS,
  CACHE_KEY_STATISTICS_PRODUCTS,
  CACHE_KEY_PRODUCTS
} from "../config";
import { useEffect, useState } from "react";
import { CustomImage } from "./CustomImage";
import AccountHeader from "./AccountHeader";
// href is replaced with link component from react-router-dom
// about localisation: https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
const Header = ({ spoqProducerData, spoqUserData, isMobile, showBanner }) => {
  const [navBarExpanded, setNavBarExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!isMobile) {
      setNavBarExpanded(false)
    }
  }, [isMobile])
  return (
    <div className="w3-top" style={isMobile? {fontSize:"11px"}: {}}>
      {/* Navbar Desktop*/}
      {!isMobile &&
        <div className="w3-bar w3-theme-d2 w3-left-align">
          <Link to="/home" className="w3-bar-item w3-button w3-theme-d5">
            <FontAwesomeIcon icon={faHome} className="w3-margin-right" />
            {t("website.name")}
          </Link>
          <HashLink
            to="/home#features"
            className="w3-bar-item w3-button w3-hover-white"
          >
            {t("header.features")}
          </HashLink>
          <HashLink
            to="/home#partner"
            className="w3-bar-item w3-button w3-hover-white"
          >
            {t("header.partners")}
          </HashLink>
          <HashLink
            to="/home#contact"
            className="w3-bar-item w3-button w3-hover-white"
          >
            {t("header.contact")}
          </HashLink>
          <Link
            to="/impressum"
            className="w3-bar-item w3-button w3-hover-white"
          >
            {t("header.legal_notice")}
          </Link>
          <div className="w3-dropdown-hover">
            <button className="w3-button" title="Notifications">
              <Link to={spoqProducerData ? "/account/home" : "/login"}>
                Account <FontAwesomeIcon icon={faCaretDown} />
              </Link>
            </button>
            <div className="w3-dropdown-content w3-card-4 w3-bar-block">
              {!spoqProducerData && <Link to="/login" className="w3-button">
                {t("header.login")}
              </Link>}
              {spoqProducerData && <div>
                <button
                  className="w3-bar-item w3-button"
                  onClick={() => {
                    // delete token to log out, then redirect to home 
                    localStorage.removeItem("token");
                    window.location.href = "/";
                  }
                  }
                >
                  {t("account.home.logout")}
                </button>
              </div>}
              <Link to="/register" className="w3-bar-item w3-button">
                {t("header.register")}
              </Link>
            </div>
          </div>
          <select
            className="w3-button w3-right w3-bar-item w3-theme-d2"
            value={i18n.language}
            onChange={(e) => i18n.changeLanguage(e.target.value)}
          >
            <option value="en" className="w3-bar-item">
              English
            </option>
            <option value="de" className="w3-bar-item">
              Deutsch
            </option>
          </select>
        </div>

      }
      {/* Navbar Mobile*/}
      {isMobile &&
        <div
          id="navDemo"
          className="w3-theme-d2"
          style={{ height: "40px" }}
        >
          <Link to="/home" className="w3-bar-item w3-button w3-theme-d5">
            <img
              src={Logo}
              alt="Logo"
              style={{ maxHeight: "23px", marginRight: "5px" }}
            />
            {t("website.name")}
          </Link>
          {spoqProducerData &&
            <div className="w3-dropdown-hover">
              <button className="w3-button" title="Notifications">
                <Link to={spoqProducerData ? "/account/home" : "/login"}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <CustomImage
                      id="companyLogo"
                      data={spoqProducerData.company_logo}
                      maxHeight={"25px"}
                      maxWidth={"25px"}

                    />
                    <div style={{ marginLeft: "5px" }}>
                      Account
                    </div>
                    <center>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </center>
                  </div>
                </Link>
              </button>
              <div className="w3-dropdown-content w3-card-4 w3-bar-block">
                {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) && (<Link
                  to="/account/products"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.products")}
                </Link>)}
                {spoqProducerData.product_api_url !== "" && spoqProducerData.producer_boolean && spoqUserData.role <= 2 && (<Link
                  to="/account/register_product"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.register_product")}
                </Link>)}
                {spoqProducerData.product_api_url !== "" && spoqUserData.role <= 2 && (<Link
                  to="/account/stats"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.statistics")}
                </Link>)}
                {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) && spoqUserData.role <= 3 && <Link
                  to="/account/charges"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.charges")}
                </Link>}
                {(spoqProducerData.producer_boolean || spoqProducerData.contract_manufacturer_boolean) && spoqUserData.role <= 3 && <Link
                  to="/account/register_production_scanner"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.production_scanner")}
                </Link>}
                {spoqUserData.role <= 2 && (<Link
                  to="/account/add_associated_manufacturer"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.company_cooperation")}
                </Link>)}
                {spoqProducerData.account_api_url !== "" && spoqUserData.role <= 2 && (<Link
                  to="/account/register"
                  className="w3-bar-item w3-button w3-hover-white"
                >
                  {t("account.register_account")}
                </Link>)}
                <Link to="/account/home" className="w3-bar-item w3-button w3-hover-white">
                  {t("account.home.account_data")}
                </Link>
              </div>
            </div>}
          <button
            className="w3-bar-item w3-button w3-right w3-hover-white w3-theme-d2"
            onClick={() => {
              setNavBarExpanded(!navBarExpanded)
            }}
          >
            <FontAwesomeIcon icon={faBars}
            />
          </button>
          {navBarExpanded && <div className=" w3-theme-d2 w3-right" style={{ position: "absolute", right: "0px", alignSelf: "flex-end", display: "flex", flexDirection: "column" }}>
            {!spoqProducerData && <Link to="/login" className="w3-bar-item w3-button">
              {t("header.login")}
            </Link>}
            {spoqProducerData && <center>
              <button
                className="w3-bar-item w3-button"
                onClick={() => {
                  // delete token to log out, then redirect to home 
                  localStorage.removeItem("token");
                  localStorage.removeItem(CACHE_KEY_ACCOUNT_INFO)
                  localStorage.removeItem(CACHE_KEY_USER_ROLE)
                  localStorage.removeItem(CACHE_KEY_ALL_OWNERS)
                  localStorage.removeItem(CACHE_KEY_STATISTICS_USERS)
                  localStorage.removeItem(CACHE_KEY_STATISTICS_PRODUCTS)
                  localStorage.removeItem(CACHE_KEY_PRODUCTS)
                  window.location.href = "/";
                }
                }
              >
                {t("account.home.logout")}
              </button>
            </center>}
            <HashLink to="/home#features" className="w3-bar-item w3-button">
              {t("header.features")}
            </HashLink>
            <HashLink to="/home#partner" className="w3-bar-item w3-button">
              {t("header.partners")}
            </HashLink>
            <HashLink to="/home#contact" className="w3-bar-item w3-button">
              {t("header.contact")}
            </HashLink>
            <Link to="/impressum" className="w3-bar-item w3-button">
              {t("header.legal_notice")}
            </Link>
            <Link to={spoqProducerData ? "/account/home" : "/login"} className="w3-bar-item w3-button">
              Account
            </Link>
            <Link to="/register" className="w3-bar-item w3-button">
              {t("header.register")}
            </Link>
            <select
              className="w3-button w3-bar-item w3-theme-d2"
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
            >
              <option value="en" className="w3-bar-item">
                English
              </option>
              <option value="de" className="w3-bar-item">
                Deutsch
              </option>
            </select>
          </div>}

        </div>
      }

      {!isMobile && !showBanner &&
        <div>
          <AccountHeader
            spoqProducerData={spoqProducerData}
            spoqUserData={spoqUserData}
          />
        </div>
      }
      {true &&
        <div id="id01" className="w3-modal">
          <div className="w3-modal-content w3-card-4 w3-animate-top">
            <header className="w3-container w3-indigo w3-display-container">
              <span
                onClick={() =>
                  (document.getElementById("id01").style.display = "none")
                }
                className="w3-button w3-theme-d5 w3-display-topright"
              >
                <FontAwesomeIcon icon={faRemove} />
              </span>
              <h4>{t("header.info")}</h4>
              <h5>{t("header.info2")}</h5>
            </header>
            <div className="w3-container w3-centered">
              <div>
                <p>
                  {t("header.download_dialog_text1")}
                </p>
                <p>
                  {t("header.download_dialog_text2")}
                </p>
                <p>
                  {t("header.download_dialog_text3")}
                </p>
              </div>
              <div className="container">
                <Link to="/download" className="w3-bar-item w3-button center">
                  <button className="w3-button w3-theme-l2 w3-hover-white .w3-button">
                    {t("header.download")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>}
    </div>
  );
};

export default Header;
